import { Component } from "./Component";
import { observable } from "mobx";
import moment from "moment";
import { IComputedProps } from "./ComponentProperties";
import { RulePropertySchema, RuleGroupInfo } from "./Rule";
import { PropertyType, PermissionLevels } from "../Repository/PropertySchema";
import { USER_ID_SESSION_ATTRIBUTE_NAME } from "../../Pages/Login/AuthenticationService";
import { GrantedGroupPermission, GrantedUserPermission } from "./UserGroup";

export class HhitsBuild {
  public id?: string;
  @observable public title: string = "";
  @observable public description: string = "";
  @observable public ownerId: string = "";
  @observable public worldAccess: PermissionLevels = PermissionLevels.None;
  @observable public groupAccess: GrantedGroupPermission[] = [];
  @observable public userAccess: GrantedUserPermission[] = [];
  @observable public weightThreshold?: number;
  public dateCreated: Date = new Date();
  public dateModified: Date = new Date();
  @observable public allowUnmappedComponents: boolean = false;
  @observable public roots: Component[] = [];
  @observable public ruleGroupInfo: RuleGroupInfo[] = [];
  @observable public editable: boolean = true;
  @observable public deletable: boolean = true;

  constructor(source?: HhitsBuild) {
    if (source) {
      Object.assign(this, source);
      this.dateCreated = moment(source.dateCreated).toDate();
      this.dateModified = moment(source.dateModified).toDate();
      this.roots = source.roots.map((root) => new Component(root));
      this.groupAccess = source.groupAccess.map(
        (access) => new GrantedGroupPermission(access)
      );
      this.userAccess = source.userAccess.map(
        (access) => new GrantedUserPermission(access)
      );
    }
    if (this.ownerId.length === 0) {
      this.ownerId =
        sessionStorage.getItem(USER_ID_SESSION_ATTRIBUTE_NAME) || "";
    }
  }

  equals(that: HhitsBuild): boolean {
    return (
      this.id === that.id &&
      this.allowUnmappedComponents === that.allowUnmappedComponents &&
      this.roots.length === that.roots.length &&
      this.roots.every((child, index) => child.equals(that.roots[index]))
    );
  }

  findComponent(index: number): Component | null {
    let componentFound = null;
    this.roots.forEach((root) => {
      const found = root.findComponent(index);
      if (found) {
        componentFound = found;
      }
    });
    return componentFound;
  }

  public static getRulePropertySchema(): RulePropertySchema[] {
    const properties = [
      {
        name: "weightTotalInGrams",
        display: "Total Build Weight",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "weightThreshold",
        display: "Weight Threshold",
        computed: false,
        listCount: false,
        type: PropertyType.Number,
      },
    ];

    return properties;
  }

  addRuleGroupByGroup(ruleGroupInfo: RuleGroupInfo) {
    let ruleGroup = this.ruleGroupInfo.find(
      (group) => group.id === ruleGroupInfo.id
    );

    if (!ruleGroup) {
      this.ruleGroupInfo.push(ruleGroupInfo);
    } else {
      ruleGroup.disabledRuleIds = ruleGroupInfo.disabledRuleIds;
    }

    return ruleGroup;
  }

  public addRuleGroupById(ruleGroupId: string) {
    let ruleGroup = this.ruleGroupInfo.find(
      (group) => group.id === ruleGroupId
    );
    if (!ruleGroup) {
      ruleGroup = { id: ruleGroupId, disabledRuleIds: [] };
      this.ruleGroupInfo.push(ruleGroup);
    }

    return ruleGroup;
  }

  public removeRuleGroup(id: string) {
    var idx = this.ruleGroupInfo.map((group) => group.id).indexOf(id);
    if (idx > -1) {
      this.ruleGroupInfo.splice(idx, 1);
    }
  }
}

export class BuildComputeds implements IComputedProps {
  public index: number = 0;
  public weightTotalInGrams: number = 0;
  public powerTotalDrawInWatts: number = 0;
  public powerTotalSupplyInWatts: number = 0;
  public power12vTotalDrawInAmps: number = 0;
  public power5vTotalDrawInAmps: number = 0;
  public power3_3vTotalDrawInAmps: number = 0;
  public power3_3vAuxTotalDrawInAmps: number = 0;
  public power12vAuxPlusTotalDrawInAmps: number = 0;
  public power12vAuxNegTotalDrawInAmps: number = 0;

  constructor(source?: BuildComputeds) {
    if (source) {
      Object.assign(this, source);
    }
  }
}
