import { SiteState } from "../State";
import { UiStore } from "./UiStore";

export class RootStore {
  public uiStore: UiStore;
  public siteState: SiteState;

  constructor() {
    this.uiStore = new UiStore();
    this.siteState = new SiteState();
  }
}
