import { observable } from "mobx";
import {
  PropGroup,
  PropertyDescriptor,
  ComponentPropertyProps,
} from "./ComponentProperties";
import {
  PropertyType,
  PermissionLevels,
  PERMISSION_LEVEL_STRINGS,
} from "../Repository/PropertySchema";

export class UserGroup {
  public id?: string;
  @observable public name: string = "";
  @observable public userIds: string[] = [];

  constructor(source?: UserGroup) {
    if (source) {
      Object.assign(this, source);
    }
  }

  public getTitle(): string {
    return this.name;
  }

  public containsEmptyRequiredFields(): boolean {
    if (this.name.length <= 0) return true;
    return false;
  }

  getPropertySchema(): PropGroup<UserGroup>[] {
    return [
      new PropGroup("Group Info", [
        new PropertyDescriptor("name", "Name", PropertyType.Text),
      ]),
      new PropGroup("Users", [
        new PropertyDescriptor(
          "userIds",
          "Users",
          PropertyType.IdList,
          undefined,
          undefined,
          undefined,
          undefined,
          "users"
        ),
      ]),
    ];
  }

  public asImplementation(): UserGroup {
    return this;
  }

  public addToList<UserGroup>(propName: keyof UserGroup) {
    if (propName === "userIds") {
      this.userIds.push("");
    }
  }

  public removeFromList<UserGroup>(propName: keyof UserGroup, index: number) {
    if (propName === "userIds") {
      this.userIds.splice(index, 1);
    }
  }
}

export class GrantedGroupPermission implements ComponentPropertyProps {
  @observable public groupId: string = "";
  @observable public access: PermissionLevels = PermissionLevels.None;

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<GrantedGroupPermission>[] {
    return [
      new PropGroup("Groups", [
        new PropertyDescriptor(
          "groupId",
          "Group",
          PropertyType.SingleSelectById,
          undefined,
          undefined,
          undefined,
          undefined,
          "user-groups"
        ),
        new PropertyDescriptor(
          "access",
          "Permission",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          PERMISSION_LEVEL_STRINGS
        ),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: GrantedGroupPermission): string[] {
    return [];
  }
}

export class GrantedUserPermission implements ComponentPropertyProps {
  @observable public userId: string = "";
  @observable public access: PermissionLevels = PermissionLevels.None;

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<GrantedUserPermission>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor(
          "userId",
          "User",
          PropertyType.SingleSelectById,
          undefined,
          undefined,
          undefined,
          undefined,
          "users"
        ),
        new PropertyDescriptor(
          "access",
          "Permission",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          PERMISSION_LEVEL_STRINGS
        ),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: GrantedUserPermission): string[] {
    return [];
  }
}
