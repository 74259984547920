import { SearchBool } from "../Pages/Main/Search/SearchBool";
import { SearchDate } from "../Pages/Main/Search/SearchDate";
import { SearchMultiSelect } from "../Pages/Main/Search/SearchMultiSelect";
import { SearchNumeric } from "../Pages/Main/Search/SearchNumeric";
import {
  AttributeType,
  ISearchAttribute,
  ISearchType,
} from "../Pages/Main/Search/SearchOption";
import { SearchText } from "../Pages/Main/Search/SearchText";
import { ComponentType } from "./Schema/Component";
import { HhitsBuild } from "./Schema/HhitsBuild";
import { convertValUnits } from "../Utilities/Units";
import { parseNumber } from "../Utilities/Number";

export interface ISearchQueryType {
  typeId: string;
  query: ISearchQuery;
}
export interface ISearchQuery {
  searchObjects: ISearchObject[];
  buildCompatible?: BuildCompatible;
  individualSearches?: boolean;
}

export interface BuildCompatible {
  build: HhitsBuild;
  componentIndex: number;
  specifiedSlotIndex?: number;
}

export interface ISearchObject {
  separator: SearchSeparator;
  predicates: ISearchPredicate[];
}

export interface ISearchPredicate {
  attribute: ISearchAttribute;
  value: string | number | Date | Boolean;
  operator: SearchOperator;
}

export enum SearchSeparator {
  And,
  Or,
}

export enum SearchOperator {
  Equals = "Equals",
  NotEquals = "NotEquals",
  GreaterThan = "GreaterThan",
  GreaterThanEquals = "GreaterThanEquals",
  LessThan = "LessThan",
  LessThanEquals = "LessThanEquals",
  In = "In",
  NotIn = "NotIn",
  DoesNotContain = "DoesNotContain",
  Contains = "Contains",
  Exists = "Exists",
  NotExists = "NotExists",
  Table = "Table",
  SizeEquals = "SizeEquals",
  SizeGreaterThanEquals = "SizeGreaterThanEquals",
  SizeLessThanEquals = "SizeLessThanEquals",
}
export function OperatorDisplayString(operator: SearchOperator) {
  return operator.toString().replace(/([A-Z])/g, " $1");
}

export function convertToSearchQuery(
  searchType: ISearchType,
  build?: HhitsBuild,
  componentIndex?: number,
  childIndex?: number
): ISearchQuery {
  const searchQuery: ISearchQuery = {
    searchObjects: [],
    buildCompatible:
      build && componentIndex
        ? {
            build: build,
            componentIndex: componentIndex,
            specifiedSlotIndex: childIndex,
          }
        : undefined,
  };

  if (searchType.options && searchType.options.length > 0) {
    searchType.options
      .filter((option) => option.active)
      .forEach((option) => {
        if (option instanceof SearchBool) {
          const searchBool = option as SearchBool;

          const searchObject: ISearchObject = {
            separator: SearchSeparator.Or,
            predicates: [],
          };

          if (searchBool.checked) {
            searchObject.predicates.push({
              attribute: searchBool.attribute,
              value: true,
              operator: SearchOperator.Equals,
            });
          } else {
            searchObject.predicates.push({
              attribute: searchBool.attribute,
              value: false,
              operator: SearchOperator.Equals,
            });
            searchObject.predicates.push({
              attribute: searchBool.attribute,
              value: "null",
              operator: SearchOperator.Equals,
            });
            searchObject.predicates.push({
              attribute: searchBool.attribute,
              value: "",
              operator: SearchOperator.NotExists,
            });
          }

          if (searchObject.predicates.length > 0) {
            searchQuery.searchObjects.push(searchObject);
          }
        } else if (option instanceof SearchMultiSelect) {
          const searchDropdown = option as SearchMultiSelect;

          const searchObject: ISearchObject = {
            separator: SearchSeparator.Or,
            predicates: [],
          };

          searchDropdown.value.forEach((searchValue) => {
            const searchPredicate = {
              attribute: searchDropdown.attribute,
              value: searchValue,
              operator: SearchOperator.Equals,
            };
            searchObject.predicates.push(searchPredicate);
          });
          if (searchObject.predicates.length > 0) {
            searchQuery.searchObjects.push(searchObject);
          }
        } else if (option instanceof SearchNumeric) {
          const searchNumeric = option as SearchNumeric;

          const searchObject: ISearchObject = {
            separator: SearchSeparator.And,
            predicates: [],
          };
          if (searchNumeric.value != null) {
            let metricValue = convertValUnits(
              searchNumeric.value,
              "" + searchNumeric.attribute.unit,
              "Metric",
              true
            );

            const searchPredicate = {
              attribute: {
                ...searchNumeric.attribute,
                unit: metricValue.units,
              },
              value: parseNumber(metricValue.value),
              operator: searchNumeric.operator,
            };

            searchObject.predicates.push(searchPredicate);
          }

          if (searchObject.predicates.length > 0) {
            searchQuery.searchObjects.push(searchObject);
          }
        } else if (option instanceof SearchDate) {
          const searchDate = option as SearchDate;

          const searchObject: ISearchObject = {
            separator: SearchSeparator.Or,
            predicates: [],
          };

          // Between case. It's not good to propagate the code spam, but this can't be cleaned now.
          let operatorValue = searchDate.operator.valueOf();

          if (operatorValue === "NotIn") {
            searchQuery.individualSearches = true;
          }

          if (operatorValue === "In" || operatorValue === "NotIn") {
            if (searchDate.value) {
              let value = new Date(searchDate.value);

              const searchPredicate = {
                attribute: searchDate.attribute,
                value: value.toISOString(),
                operator:
                  operatorValue === "In"
                    ? SearchOperator.GreaterThanEquals
                    : SearchOperator.LessThan,
              };
              searchObject.predicates.push(searchPredicate);
            }
            if (searchDate.secondaryValue) {
              let value = new Date(searchDate.secondaryValue);

              const searchPredicate = {
                attribute: searchDate.attribute,
                value: value.toISOString(),
                operator:
                  operatorValue === "In"
                    ? SearchOperator.LessThanEquals
                    : SearchOperator.GreaterThan,
              };
              searchObject.predicates.push(searchPredicate);
            }
          } else {
            if (searchDate.value) {
              const searchPredicate = {
                attribute: searchDate.attribute,
                value: searchDate.value.toISOString(),
                operator: searchDate.operator,
              };
              searchObject.predicates.push(searchPredicate);
            }
          }

          if (searchObject.predicates.length > 0) {
            searchQuery.searchObjects.push(searchObject);
          }
        } else if (option instanceof SearchText) {
          const searchText = option as SearchText;

          const searchObject: ISearchObject = {
            separator: SearchSeparator.And,
            predicates: [],
          };

          if (searchText.value.length > 0) {
            const searchPredicate = {
              attribute: searchText.attribute,
              value: searchText.value,
              operator: searchText.operator,
              numMatches: searchText.numMatches,
              numMatchesOperator: searchText.numMatchesOperator,
            };
            searchObject.predicates.push(searchPredicate);
          }
          if (searchObject.predicates.length > 0) {
            searchQuery.searchObjects.push(searchObject);
          }
        }
      });
  }

  return searchQuery;
}

export function GetStartingSearchQuery(
  componentType: ComponentType
): ISearchType {
  switch (componentType) {
    case ComponentType.Backplane:
      const backplaneOptions = [
        new SearchText("", SearchOperator.Contains, {
          caption: "Name",
          value: "model",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchText("", SearchOperator.Contains, {
          caption: "Vendor",
          value: "vendor",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchText("", SearchOperator.Contains, {
          caption: "Profile",
          value: "profile",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchDate(
          null,
          SearchOperator.GreaterThanEquals,
          [
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
            SearchOperator.In,
            SearchOperator.NotIn,
          ],
          {
            caption: "Manufacture Date",
            value: "manufacturedDate",
            type: AttributeType.Date,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.SizeEquals,
          [
            SearchOperator.SizeEquals,
            SearchOperator.SizeGreaterThanEquals,
            SearchOperator.SizeLessThanEquals,
          ],
          {
            caption: "Slot Count",
            value: "slots",
            type: AttributeType.List,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Weight",
            value: "weightInGrams",
            type: AttributeType.Numeric,
            unit: "grams",
          }
        ),
        new SearchText("", SearchOperator.Contains, {
          caption: "Port Type",
          value: "ports.type",
          type: AttributeType.Text,
          unit: "",
          repeatedField: true,
        }),
        new SearchMultiSelect(
          [],
          ["Centralized", "Distributed", "Hybrid"],
          {
            caption: "Topology Type",
            value: "topologyType",
            type: AttributeType.Text,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThan,
            SearchOperator.LessThan,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Cost",
            value: "costInUSD",
            type: AttributeType.Numeric,
            unit: "USD",
          }
        ),
      ];
      return {
        buildCompatible: false,
        options: backplaneOptions,
        componentType: componentType,
      };

    case ComponentType.Module:
      const moduleOptions = [
        new SearchText("", SearchOperator.Contains, {
          caption: "Name",
          value: "model",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchMultiSelect([], ["3U", "6U"], {
          caption: "Form",
          value: "form",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchMultiSelect(
          [],
          [
            "Payload",
            "Switch",
            "Peripheral",
            "Storage",
            "Time",
            "PMC Mezzanine",
            "XMC Mezzanine",
          ],
          { caption: "Type", value: "type", type: AttributeType.Text, unit: "" }
        ),
        new SearchText("", SearchOperator.Contains, {
          caption: "Vendor",
          value: "vendor",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchText("", SearchOperator.Contains, {
          caption: "Profile",
          value: "moduleProfile",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchBool(false, {
          caption: "IPMC Implemented",
          value: "ipmcImplemented",
          type: AttributeType.Boolean,
          unit: "",
        }),
        new SearchText("", SearchOperator.Contains, {
          caption: "Tier 3 Document",
          value: "tier3",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchMultiSelect(
          [],
          ["Air", "Conduction", "AFT", "LFT", "Air Flow-By"],
          {
            caption: "Cooling Type",
            value: "coolingType",
            type: AttributeType.Text,
            unit: "",
          }
        ),
        new SearchDate(
          null,
          SearchOperator.GreaterThanEquals,
          [
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
            SearchOperator.In,
            SearchOperator.NotIn,
          ],
          {
            caption: "Manufacture Date",
            value: "manufacturedDate",
            type: AttributeType.Date,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.SizeEquals,
          [
            SearchOperator.SizeEquals,
            SearchOperator.SizeGreaterThanEquals,
            SearchOperator.SizeLessThanEquals,
          ],
          {
            caption: "Processor Count",
            value: "processors",
            type: AttributeType.List,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.SizeEquals,
          [
            SearchOperator.SizeEquals,
            SearchOperator.SizeGreaterThanEquals,
            SearchOperator.SizeLessThanEquals,
          ],
          {
            caption: "Mezzanine Count",
            value: "mezzanineSlots",
            type: AttributeType.List,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Weight",
            value: "weightInGrams",
            type: AttributeType.Numeric,
            unit: "grams",
          }
        ),
        new SearchText("", SearchOperator.Contains, {
          caption: "Port Type",
          value: "ports.type",
          type: AttributeType.Text,
          unit: "",
          repeatedField: true,
        }),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThan,
            SearchOperator.LessThan,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Cost",
            value: "costInUSD",
            type: AttributeType.Numeric,
            unit: "USD",
          }
        ),
      ];
      return {
        buildCompatible: false,
        options: moduleOptions,
        componentType: componentType,
      };

    case ComponentType.Chassis:
      const chassisOptions = [
        new SearchText("", SearchOperator.Contains, {
          caption: "Name",
          value: "model",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchText("", SearchOperator.Contains, {
          caption: "Vendor",
          value: "vendor",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchDate(
          null,
          SearchOperator.GreaterThanEquals,
          [
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
            SearchOperator.In,
            SearchOperator.NotIn,
          ],
          {
            caption: "Manufacture Date",
            value: "manufacturedDate",
            type: AttributeType.Date,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.SizeEquals,
          [
            SearchOperator.SizeEquals,
            SearchOperator.SizeGreaterThanEquals,
            SearchOperator.SizeLessThanEquals,
          ],
          {
            caption: "Backplane Count",
            value: "slots",
            type: AttributeType.List,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Weight",
            value: "weightInGrams",
            type: AttributeType.Numeric,
            unit: "grams",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThan,
            SearchOperator.LessThan,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Cost",
            value: "costInUSD",
            type: AttributeType.Numeric,
            unit: "USD",
          }
        ),
      ];
      return {
        buildCompatible: false,
        options: chassisOptions,
        componentType: componentType,
      };
    case ComponentType.Mezzanine:
      const mezzanineOptions = [
        new SearchText("", SearchOperator.Contains, {
          caption: "Name",
          value: "model",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchText("", SearchOperator.Contains, {
          caption: "Vendor",
          value: "vendor",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchMultiSelect([], ["XMC", "PMC", "FMC"], {
          caption: "Form",
          value: "form",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchMultiSelect([], ["VITA 42", "VITA 61"], {
          caption: "Type",
          value: "type",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchDate(
          null,
          SearchOperator.GreaterThanEquals,
          [
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
            SearchOperator.In,
            SearchOperator.NotIn,
          ],
          {
            caption: "Manufacture Date",
            value: "manufacturedDate",
            type: AttributeType.Date,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Weight",
            value: "weightInGrams",
            type: AttributeType.Numeric,
            unit: "grams",
          }
        ),
        new SearchMultiSelect([], ["Single Width", "Double Width"], {
          caption: "Size",
          value: "size",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThan,
            SearchOperator.LessThan,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Cost",
            value: "costInUSD",
            type: AttributeType.Numeric,
            unit: "USD",
          }
        ),
      ];
      return {
        buildCompatible: false,
        options: mezzanineOptions,
        componentType: componentType,
      };
    case ComponentType.PowerSupply:
      const powerOptions = [
        new SearchText("", SearchOperator.Contains, {
          caption: "Name",
          value: "model",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchText("", SearchOperator.Contains, {
          caption: "Vendor",
          value: "vendor",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchMultiSelect(
          [],
          ["Air", "Conduction", "AFT", "LFT", "Air Flow-By"],
          {
            caption: "Cooling Type",
            value: "coolingType",
            type: AttributeType.Text,
            unit: "",
          }
        ),
        new SearchDate(
          null,
          SearchOperator.GreaterThanEquals,
          [
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
            SearchOperator.In,
            SearchOperator.NotIn,
          ],
          {
            caption: "Manufacture Date",
            value: "manufacturedDate",
            type: AttributeType.Date,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "Total Power",
            value: "powerTotalInWatts",
            type: AttributeType.Numeric,
            unit: "watts",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "Efficiency",
            value: "efficiencyPercent",
            type: AttributeType.Numeric,
            unit: "",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "12 V",
            value: "power12vInAmps",
            type: AttributeType.Numeric,
            unit: "amps",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "5 V",
            value: "power5vInAmps",
            type: AttributeType.Numeric,
            unit: "amps",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "3.3 V",
            value: "power3_3vInAmps",
            type: AttributeType.Numeric,
            unit: "amps",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "3.3 V AUX",
            value: "power3_3vAuxInAmps",
            type: AttributeType.Numeric,
            unit: "amps",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "+12 V AUX",
            value: "power12vAuxPlusInAmps",
            type: AttributeType.Numeric,
            unit: "amps",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.GreaterThanEquals,
          [SearchOperator.GreaterThanEquals, SearchOperator.LessThanEquals],
          {
            caption: "-12 V AUX",
            value: "power12vAuxNegInAmps",
            type: AttributeType.Numeric,
            unit: "amps",
          }
        ),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Weight",
            value: "weightInGrams",
            type: AttributeType.Numeric,
            unit: "grams",
          }
        ),
        new SearchText("", SearchOperator.Contains, {
          caption: "Power Input Type",
          value: "powerInputType",
          type: AttributeType.Text,
          unit: "",
        }),
        new SearchNumeric(
          null,
          SearchOperator.Equals,
          [
            SearchOperator.Equals,
            SearchOperator.GreaterThan,
            SearchOperator.LessThan,
            SearchOperator.GreaterThanEquals,
            SearchOperator.LessThanEquals,
          ],
          {
            caption: "Cost",
            value: "costInUSD",
            type: AttributeType.Numeric,
            unit: "USD",
          }
        ),
      ];
      return {
        buildCompatible: false,
        options: powerOptions,
        componentType: componentType,
      };
    default:
      throw new Error("invalid typeId");
  }
}
