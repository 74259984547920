import { PropertyType, PermissionLevels } from "../Repository/PropertySchema";
import { ModuleProps } from "./Components/Module";
import { observable } from "mobx";
import { BackplaneProps } from "./Components/BackplaneProps";
import { ComponentType } from "./Component";
import { ChassisProps } from "./Components/ChassisProps";
import { MezzanineProps } from "./Components/Mezzanine";
import { PowerSupplyProps } from "./Components/PowerSupply";
import { ITopology } from "../../Components/Topology/Topology";
import { FileData } from "./FileData";
import { RulePropertySchema } from "./Rule";
import { User } from "./User";
import {
  UserGroup,
  GrantedGroupPermission,
  GrantedUserPermission,
} from "./UserGroup";

export interface IComputedProps {
  index: number;
}

export interface IComponentProps {
  imageData: FileData[];
  topology: ITopology[];
  editable: boolean;
  deletable: boolean;
  ownerId: string;
  worldAccess: PermissionLevels;
  groupAccess: GrantedGroupPermission[];
  userAccess: GrantedUserPermission[];
  documentation: FileData[];

  getId(): string | undefined;
  getTitle(): string;
  getSubtext(): string;
  getSlots(): ComponentSlot[];
  getComponentType(): ComponentType;
  getPropertySchema():
    | PropGroup<ChassisProps>[]
    | PropGroup<BackplaneProps>[]
    | PropGroup<ModuleProps>[]
    | PropGroup<MezzanineProps>[]
    | PropGroup<PowerSupplyProps>[];
  getTopPropertySchema():
    | PropGroup<ChassisProps>[]
    | PropGroup<BackplaneProps>[]
    | PropGroup<ModuleProps>[]
    | PropGroup<MezzanineProps>[]
    | PropGroup<PowerSupplyProps>[];
  getPermissionPropertySchema():
    | PropGroup<ChassisProps>[]
    | PropGroup<BackplaneProps>[]
    | PropGroup<ModuleProps>[]
    | PropGroup<MezzanineProps>[]
    | PropGroup<PowerSupplyProps>[];
  getRepositoryProperties():
    | PropertyDescriptor<ChassisProps>[]
    | PropertyDescriptor<BackplaneProps>[]
    | PropertyDescriptor<ModuleProps>[]
    | PropertyDescriptor<MezzanineProps>[]
    | PropertyDescriptor<PowerSupplyProps>[];
  addToList<T>(propName: keyof T): void;
  removeFromList<T>(propName: keyof T, index: number): void;
  asImplementation():
    | ChassisProps
    | BackplaneProps
    | ModuleProps
    | MezzanineProps
    | PowerSupplyProps;
  compareTo(other: IComponentProps): DifferenceSet;
  getOwnerId(): string;
  getWorldAccess(): PermissionLevels;
  getGroupAccess(): GrantedGroupPermission[];
  getUserAccess(): GrantedUserPermission[];
  clone(keepId?: boolean): IComponentProps;
  containsEmptyRequiredFields(): boolean;
}

// TODO: better name
export interface ComponentPropertyProps {
  getPropertySchema():
    | PropGroup<MezzanineSlot>[]
    | PropGroup<Processor>[]
    | PropGroup<ComponentPort>[]
    | PropGroup<ModuleSlot>[]
    | PropGroup<BackplaneSlot>[]
    | PropGroup<Memory>[]
    | PropGroup<Plane>[]
    | PropGroup<BpPlane>[]
    | PropGroup<BpPlaneNoPipeSize>[]
    | PropGroup<Software>[]
    | PropGroup<GrantedGroupPermission>[]
    | PropGroup<GrantedUserPermission>[];
  addToList<T>(propName: keyof T): void;
  removeFromList<T>(propName: keyof T, index: number): void;
  compareTo(other: ComponentPropertyProps): string[];
}

export interface ComponentSlot {
  index: number;
  getType(): string;
  getDisplayText(): string;
  getForm(): string;
}

export function getComponentValue(
  propertyName: string,
  component: IComponentProps | ComponentPropertyProps | User | UserGroup
) {
  if (
    (component as any)[propertyName] !== undefined &&
    (component as any)[propertyName] !== null
  ) {
    return (component as any)[propertyName].toString();
  } else {
    return "";
  }
}

export function getComponentValueRaw(
  propertyName: string,
  component: IComponentProps | ComponentPropertyProps | User | UserGroup
) {
  if (
    (component as any)[propertyName] !== undefined &&
    (component as any)[propertyName] !== null
  ) {
    return (component as any)[propertyName];
  } else {
    return undefined;
  }
}

export function getComponentRuleProperties(
  propertyGroups: PropGroup<IComponentProps>[]
) {
  const options: RulePropertySchema[] = [];
  propertyGroups.forEach((group) => {
    group.properties.forEach((property) => {
      if (property.type === PropertyType.List) {
        options.push({
          name: property.name,
          display: property.displayName + " Count",
          type: PropertyType.Number,
          computed: false,
          listCount: true,
        });
      } else {
        options.push({
          name: property.name,
          display: property.displayName,
          type: property.type,
          computed: false,
          listCount: false,
        });
      }
    });
  });
  return options;
}

export function getComponentCount(
  propertyName: string,
  component: IComponentProps | ComponentPropertyProps | User | UserGroup
) {
  if (
    (component as any)[propertyName] !== undefined &&
    (component as any)[propertyName] !== null &&
    ((component as any)[propertyName] as any[])
  ) {
    return ((component as any)[propertyName] as any[]).length.toString();
  } else {
    return "";
  }
}

export class ModuleSlot implements ComponentPropertyProps, ComponentSlot {
  @observable public index: number = 0;
  @observable public type: string = "";
  @observable public form: string = "";
  @observable public profile: string = "";
  @observable public pitchInInches?: number;
  @observable public coolingType?: string = "";
  @observable public connectorType?: string = "";
  @observable public opticalProfile?: string = "";
  @observable public alias?: string = "";

  constructor(source: {}) {
    Object.assign(this, source);
  }
  getPropertySchema(): PropGroup<ModuleSlot>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("index", "Number", PropertyType.Number),
        new PropertyDescriptor(
          "profile",
          "Slot Profile",
          PropertyType.SlotProfile
        ),
        new PropertyDescriptor(
          "pitchInInches",
          "Pitch",
          PropertyType.Number,
          "inches"
        ),
        new PropertyDescriptor(
          "coolingType",
          "Cooling Type",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["Air", "Conduction", "AFT", "LFT", "Air Flow-By"]
        ),
        new PropertyDescriptor(
          "connectorType",
          "Connector Type",
          PropertyType.DependentSingleSelectDB,
          undefined,
          undefined,
          undefined,
          undefined,
          "aperture-pattern",
          new PropertyDescriptor(
            "opticalProfile",
            "Optical Profile",
            PropertyType.SingleSelectDB,
            undefined,
            undefined,
            undefined,
            undefined,
            "apperature-pattern-optical-profile"
          )
        ),
        new PropertyDescriptor("alias", "Alias"),
      ]),
    ];
  }
  getType(): string {
    return this.type;
  }
  getDisplayText(): string {
    return this.type === "Power" ? "Power Supply" : this.profile;
  }
  getForm() {
    return this.form;
  }

  addToList() {}
  removeFromList() {}

  public compareTo(other: ModuleSlot): string[] {
    var diffs: string[] = [];

    if (this.index !== other.index) diffs.push("index");
    if (this.type !== other.type) diffs.push("type");
    if (this.form !== other.form) diffs.push("form");
    if (this.pitchInInches !== other.pitchInInches) diffs.push("pitchInInches");
    if (this.profile !== other.profile) diffs.push("profile");
    if (this.coolingType !== other.coolingType) diffs.push("coolingType");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName?: string,
    parentDisplayName?: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName ? parentFieldName + ".type" : "type",
        display: parentDisplayName ? parentDisplayName + " Type" : "Type",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName ? parentFieldName + ".form" : "form",
        display: parentDisplayName ? parentDisplayName + " Form" : "Form",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName
          ? parentFieldName + ".pitchInInches"
          : "pitchInInches",
        display: parentDisplayName ? parentDisplayName + " Pitch" : "Pitch",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName ? parentFieldName + ".profile" : "profile",
        display: parentDisplayName ? parentDisplayName + " Profile" : "Profile",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName
          ? parentFieldName + ".coolingType"
          : "coolingType",
        display: parentDisplayName
          ? parentDisplayName + " Cooling Type"
          : "Cooling Type",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName
          ? parentFieldName + ".connectorType"
          : "connectorType",
        display: parentDisplayName
          ? parentDisplayName + " Connector Type"
          : "Connector Type",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class Memory implements ComponentPropertyProps {
  @observable public type: string = "";
  @observable public sizeInGb?: number;

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<Memory>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("type", "Type"),
        new PropertyDescriptor("sizeInGb", "Size", PropertyType.Number, "GB"),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: Memory): string[] {
    var diffs: string[] = [];

    if (this.type !== other.type) diffs.push("type");
    if (this.sizeInGb !== other.sizeInGb) diffs.push("sizeInGb");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName: string,
    parentDisplayName: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName + ".type",
        display: parentDisplayName + " Type",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".sizeInGb",
        display: parentDisplayName + " Size",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class BackplaneSlot implements ComponentPropertyProps, ComponentSlot {
  @observable public index: number = 0;
  @observable public type: string = "";
  @observable public location: string = "";

  constructor(source: {}) {
    Object.assign(this, source);
  }
  getPropertySchema(): PropGroup<BackplaneSlot>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("index", "Number", PropertyType.Number),
        new PropertyDescriptor("type", "Type"),
        new PropertyDescriptor("location", "Location"),
      ]),
    ];
  }
  getForm() {
    return "";
  }
  getType(): string {
    return "Backplane";
  }
  getDisplayText() {
    return "Backplane";
  }

  addToList() {}
  removeFromList() {}

  public compareTo(other: BackplaneSlot): string[] {
    var diffs: string[] = [];

    if (this.index !== other.index) diffs.push("index");
    if (this.type !== other.type) diffs.push("type");
    if (this.location !== other.location) diffs.push("location");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName?: string,
    parentDisplayName?: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName ? parentFieldName + ".type" : "type",
        display: parentDisplayName ? parentDisplayName + " Type" : "Type",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName ? parentFieldName + ".location" : "location",
        display: parentDisplayName
          ? parentDisplayName + " Location"
          : "Location",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class MezzanineSlot implements ComponentPropertyProps, ComponentSlot {
  @observable public index: number = 0;
  @observable public size: string = "";
  @observable public connectorType: string = "";
  @observable public form: string = "";
  @observable public mapping: string = "";
  @observable public protocol: string = "";
  @observable public protocolVersion: string = "";
  @observable public interfaceConfiguration: string = "";
  @observable public interfacePowerInterface: string = "";

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<MezzanineSlot>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("index", "Number", PropertyType.Number),
        new PropertyDescriptor(
          "form",
          "Form",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["XMC", "PMC", "FMC"]
        ),
        new PropertyDescriptor(
          "size",
          "Size",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["Single Width", "Double Width"]
        ),
        new PropertyDescriptor(
          "connectorType",
          "Connector Type",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["VITA 42", "VITA 61"]
        ),
        new PropertyDescriptor("mapping", "Mapping"),
        new PropertyDescriptor(
          "protocol",
          "Protocol",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          [
            "1000BASE",
            "100GBASE",
            "40GBASE",
            "10GBASE",
            "sRIO",
            "PCIe",
            "InfiniBand",
            "Fibre Channel",
            "SATA",
            "SAS",
          ]
        ),
        new PropertyDescriptor("protocolVersion", "Protocol Version"),
        new PropertyDescriptor(
          "interfaceConfiguration",
          "Interface Configuration"
        ),
        new PropertyDescriptor("interfacePowerInterface", "Power Interface"),
      ]),
    ];
  }
  getForm() {
    return "";
  }
  getType(): string {
    return "Mezzanine";
  }
  getDisplayText(): string {
    return this.form;
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: MezzanineSlot): string[] {
    var diffs: string[] = [];

    if (this.index !== other.index) diffs.push("index");
    if (this.form !== other.form) diffs.push("type");
    if (this.size !== other.size) diffs.push("size");
    if (this.connectorType !== other.connectorType) diffs.push("connectorType");
    if (this.mapping !== other.mapping) diffs.push("mapping");
    if (this.protocol !== other.protocol) diffs.push("interfaceType");
    if (this.protocolVersion !== other.protocolVersion)
      diffs.push("interfaceVersion");
    if (this.interfaceConfiguration !== other.interfaceConfiguration)
      diffs.push("interfaceConfiguration");
    if (this.interfacePowerInterface !== other.interfacePowerInterface)
      diffs.push("interfacePowerInterface");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName?: string,
    parentDisplayName?: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName ? parentFieldName + ".form" : "form",
        display: parentDisplayName ? parentDisplayName + " Form" : "Form",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName ? parentFieldName + ".size" : "size",
        display: parentDisplayName ? parentDisplayName + " Size" : "Size",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName
          ? parentFieldName + ".connectorType"
          : "connectorType",
        display: parentDisplayName
          ? parentDisplayName + " Connector Type"
          : "Connector Type",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName ? parentFieldName + ".mapping" : "mapping",
        display: parentDisplayName ? parentDisplayName + " Mapping" : "Mapping",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName ? parentFieldName + ".protocol" : "protocol",
        display: parentDisplayName
          ? parentDisplayName + " Protocol"
          : "Protocol",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName
          ? parentFieldName + ".protocolVersion"
          : "protocolVersion",
        display: parentDisplayName
          ? parentDisplayName + " Protocol Version"
          : "Protocol Version",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName
          ? parentFieldName + ".interfaceConfiguration"
          : "interfaceConfiguration",
        display: parentDisplayName
          ? parentDisplayName + " Interface Configuration"
          : "Interface Configuration",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName
          ? parentFieldName + ".interfacePowerInterface"
          : "interfacePowerInterface",
        display: parentDisplayName
          ? parentDisplayName + " Power Interface"
          : "Power Interface",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
    ];
  }
}

export class Processor implements ComponentPropertyProps {
  @observable public type: string = "";
  @observable public speedInGhz?: number;
  @observable public memoryInMb?: number;

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<Processor>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("type", "Type"),
        new PropertyDescriptor(
          "speedInGhz",
          "Speed",
          PropertyType.Number,
          "Ghz"
        ),
        new PropertyDescriptor(
          "memoryInMb",
          "Memory",
          PropertyType.Number,
          "Mb"
        ),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: Processor): string[] {
    var diffs: string[] = [];

    if (this.type !== other.type) diffs.push("type");
    if (this.speedInGhz !== other.speedInGhz) diffs.push("speedInGhz");
    if (this.memoryInMb !== other.memoryInMb) diffs.push("memoryInMb");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName: string,
    parentDisplayName: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName + ".type",
        display: parentDisplayName + " Type",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".speedInGhz",
        display: parentDisplayName + " Speed",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".memoryInMb",
        display: parentDisplayName + " Memory",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class Software implements ComponentPropertyProps {
  @observable public name: string = "";
  @observable public version: string = "";

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<Software>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("name", "Name"),
        new PropertyDescriptor("version", "Version"),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: Software): string[] {
    var diffs: string[] = [];

    if (this.name !== other.name) diffs.push("name");
    if (this.version !== other.version) diffs.push("version");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName: string,
    parentDisplayName: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName + ".name",
        display: parentDisplayName + " Name",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".version",
        display: parentDisplayName + " Version",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class Plane implements ComponentPropertyProps {
  @observable public protocol: string = "";
  @observable public width: string = "";
  @observable public version: string = "";
  @observable public portQuantity?: number;
  @observable public gbaudRateInGBd?: number;
  @observable public pipeSize: string = "";

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<Plane>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor(
          "pipeSize",
          "Pipe Size",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          [
            "SP",
            "UTP",
            "TP",
            "FP",
            "MP",
            "WP",
            "DFP",
            "MMP",
            "TFP",
            "QFP",
            "OFP",
          ]
        ),
        new PropertyDescriptor(
          "protocol",
          "Protocol",
          PropertyType.RelatedSingleSelectDB,
          undefined,
          undefined,
          undefined,
          undefined,
          "protocol",
          new PropertyDescriptor(
            "version",
            "Version",
            PropertyType.SingleSelectDB,
            undefined,
            undefined,
            undefined,
            undefined,
            "protocol-version"
          )
        ),
        new PropertyDescriptor("width", "Width"),
        new PropertyDescriptor("portQuantity", "Quantity", PropertyType.Number),
        //new PropertyDescriptor("gbaudRateInGBd", "Gbaud Rate", PropertyType.Number, "GBd")
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: Plane): string[] {
    var diffs: string[] = [];

    if (this.protocol !== other.protocol) diffs.push("protocol");
    if (this.width !== other.width) diffs.push("width");
    if (this.version !== other.version) diffs.push("version");
    if (this.portQuantity !== other.portQuantity) diffs.push("portQuantity");
    if (this.gbaudRateInGBd !== other.gbaudRateInGBd)
      diffs.push("gbaudRateInGBd");
    if (this.pipeSize !== other.pipeSize) diffs.push("pipeSize");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName: string,
    parentDisplayName: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName + ".protocol",
        display: parentDisplayName + " Protocol",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".width",
        display: parentDisplayName + " Width",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".pipeSize",
        display: parentDisplayName + " Pipe Size",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".version",
        display: parentDisplayName + " Version",
        computed: false,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: parentFieldName + ".portQuantity",
        display: parentDisplayName + " Quantity",
        computed: false,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: parentFieldName + ".gbaudRateInGBd",
        display: parentDisplayName + " Gbaud Rate",
        computed: false,
        listCount: false,
        type: PropertyType.Number,
      },
    ];
  }
}

export class BpPlane implements ComponentPropertyProps {
  @observable public gbaudRateInGBd?: number;
  @observable public pipeSize: string = "";
  @observable public connections: BackplaneConnection[] = [];

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<BpPlane>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor(
          "pipeSize",
          "Pipe Size",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          [
            "SP",
            "UTP",
            "TP",
            "FP",
            "MP",
            "WP",
            "DFP",
            "MMP",
            "TFP",
            "QFP",
            "OFP",
          ]
        ),
        new PropertyDescriptor(
          "gbaudRateInGBd",
          "Gbaud Rate",
          PropertyType.Number,
          "GBd"
        ),
        new PropertyDescriptor(
          "connections",
          "Slot Connections",
          PropertyType.ConnectionList
        ),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: Plane): string[] {
    var diffs: string[] = [];

    if (this.gbaudRateInGBd !== other.gbaudRateInGBd)
      diffs.push("gbaudRateInGBd");
    if (this.pipeSize !== other.pipeSize) diffs.push("pipeSize");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName: string,
    parentDisplayName: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName + ".pipeSize",
        display: parentDisplayName + " Pipe Size",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".gbaudRateInGBd",
        display: parentDisplayName + " Gbaud Rate",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class BpPlaneNoPipeSize implements ComponentPropertyProps {
  @observable public gbaudRateInGBd?: number;
  @observable public connections: BackplaneConnection[] = [];

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<BpPlaneNoPipeSize>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor(
          "gbaudRateInGBd",
          "Gbaud Rate",
          PropertyType.Number,
          "GBd"
        ),
        new PropertyDescriptor(
          "connections",
          "Slot Connections",
          PropertyType.ConnectionList
        ),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: Plane): string[] {
    var diffs: string[] = [];

    if (this.gbaudRateInGBd !== other.gbaudRateInGBd)
      diffs.push("gbaudRateInGBd");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName: string,
    parentDisplayName: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName + ".pipeSize",
        display: parentDisplayName + " Pipe Size",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".gbaudRateInGBd",
        display: parentDisplayName + " Gbaud Rate",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class ComponentPort implements ComponentPropertyProps {
  @observable public type: string = "";
  @observable public version: string = "";
  @observable public location: string = "";

  constructor(source: {}) {
    Object.assign(this, source);
  }

  getPropertySchema(): PropGroup<ComponentPort>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("type", "Type"),
        new PropertyDescriptor("version", "Version"),
        new PropertyDescriptor("location", "Location"),
      ]),
    ];
  }
  addToList() {}
  removeFromList() {}

  public compareTo(other: ComponentPort): string[] {
    var diffs: string[] = [];

    if (this.type !== other.type) diffs.push("type");
    if (this.version !== other.version) diffs.push("version");
    if (this.location !== other.location) diffs.push("location");

    return diffs;
  }

  public static getRulePropertySchema(
    parentFieldName: string,
    parentDisplayName: string
  ): RulePropertySchema[] {
    return [
      {
        name: parentFieldName + ".type",
        display: parentDisplayName + " Type",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".version",
        display: parentDisplayName + " Version",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
      {
        name: parentFieldName + ".location",
        display: parentDisplayName + " Location",
        computed: false,
        listCount: false,
        type: PropertyType.List,
      },
    ];
  }
}

export class BackplaneConnection {
  @observable public a: number = 0;
  @observable public b: number = 0;
  @observable public row: number = -1;
  @observable public a_plane: string = "";
  @observable public b_plane: string = "";
  @observable public a_label: string = "";
  @observable public b_label: string = "";
  @observable public right_label: string = "";
}

export class PropGroup<T> {
  constructor(
    public name: string = "",
    public properties: PropertyDescriptor<T>[] = []
  ) {}
}

export class PropertyDescriptor<T> {
  constructor(
    public name: keyof T,
    public displayName: string,
    public type: PropertyType = PropertyType.Text,
    public unit: string = "",
    public required: boolean = false,
    public options: string[] = [],
    public subProperty?: keyof T,
    public databaseEntryName?: string,
    public dependentProperty?: PropertyDescriptor<T>
  ) {}
}

export class DifferenceSet {
  constructor(
    private diffs: Map<string, string[]> = new Map<string, string[]>(),
    private subdiffsLeft: Map<string, Map<number, string[]>> = new Map<
      string,
      Map<number, string[]>
    >(),
    private subdiffsRight: Map<string, Map<number, string[]>> = new Map<
      string,
      Map<number, string[]>
    >()
  ) {}

  public getDiffs(): Map<string, string[]> {
    return this.diffs;
  }

  public addDiffs(otherSet: DifferenceSet) {
    otherSet.getDiffs().forEach((value, key) => this.addDiff(key, value[0]));
  }

  public addDiff(groupName: string, fieldName: string) {
    if (!this.diffs.has(groupName)) {
      this.diffs.set(groupName, []);
    }
    let group = this.diffs.get(groupName);
    if (group) group.push(fieldName);
  }

  public addLeftDiff(groupName: string, index: number, fieldName: string) {
    this.doSubGroupDiffs(this.subdiffsLeft, groupName, index, fieldName);
  }

  public addRightDiff(groupName: string, index: number, fieldName: string) {
    this.doSubGroupDiffs(this.subdiffsRight, groupName, index, fieldName);
  }

  public doSubGroupDiffs(
    diffset: Map<string, Map<number, string[]>>,
    groupName: string,
    index: number,
    fieldName: string
  ) {
    if (!diffset.has(groupName)) {
      var fieldMap = new Map<number, string[]>();
      fieldMap.set(index, []);
      diffset.set(groupName, fieldMap);
    }
    let group = diffset.get(groupName);
    if (group) {
      if (!group.has(index)) {
        group.set(index, []);
      }
      var subgroup = group.get(index);
      if (subgroup) {
        subgroup.push(fieldName);
      }
    }
  }

  public groupHasDiff(groupName: string): boolean {
    return (
      this.diffs.has(groupName) ||
      this.subdiffsLeft.has(groupName) ||
      this.subdiffsRight.has(groupName)
    );
  }

  public fieldHasDiff(groupName: string, fieldName: string): boolean {
    if (this.diffs.has(groupName)) {
      let group = this.diffs.get(groupName);
      if (group && group.indexOf(fieldName) > -1) {
        return true;
      }
    }
    return false;
  }

  public leftFieldHasDiff(
    groupName: string,
    idx: number,
    fieldName: string
  ): boolean {
    if (this.subdiffsLeft.has(groupName)) {
      let group = this.subdiffsLeft.get(groupName);
      if (group) {
        var subgroup: string[] | undefined = group.get(idx);
        if (subgroup && subgroup.indexOf(fieldName) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  public rightFieldHasDiff(
    groupName: string,
    idx: number,
    fieldName: string
  ): boolean {
    if (this.subdiffsRight.has(groupName)) {
      let group = this.subdiffsRight.get(groupName);
      if (group) {
        var subgroup: string[] | undefined = group.get(idx);
        if (subgroup && subgroup.indexOf(fieldName) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  public compareProps(
    groupName: string,
    list1: ComponentPropertyProps[],
    list2: ComponentPropertyProps[]
  ) {
    list1.forEach((p1, idx1) => {
      var fieldsWithDiffs: string[] = [];
      list2.forEach((p2, idx2) => {
        let f = p1.compareTo(p2);
        if (idx2 === 0 || f.length <= fieldsWithDiffs.length)
          fieldsWithDiffs = f;
      });
      fieldsWithDiffs.forEach((field) => {
        this.addLeftDiff(groupName, idx1, field);
      });
    });

    list2.forEach((p2, idx1) => {
      var fieldsWithDiffs: string[] = [];
      list1.forEach((p1, idx2) => {
        let f = p2.compareTo(p1);
        if (idx2 === 0 || f.length < fieldsWithDiffs.length)
          fieldsWithDiffs = f;
      });
      fieldsWithDiffs.forEach((field) => {
        this.addRightDiff(groupName, idx1, field);
      });
    });
  }
}
