import * as React from "react";
import { observable } from "mobx";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import {
  HashRouter as Router,
  Route,
  RouteComponentProps,
} from "react-router-dom";

import "./Polyfill/Import.ts";

import { Header } from "./Header";

import { RootStore } from "./Data/Store/RootStore";
import { SiteContext } from "./Data/State";

import { Suspense } from "react";
import { LinearProgress } from "@material-ui/core";

const BuildIndex = React.lazy(() => import("./Pages/TreeBuild/BuildIndex"));

const Repository = React.lazy(() => import("./Pages/Repository/Repository"));

const RuleIndex = React.lazy(() => import("./Pages/Rules/RuleIndex"));

const TreeBuild = React.lazy(() => import("./Pages/TreeBuild/TreeBuild"));

const LoginWithRouter = React.lazy(() => import("./Pages/Login/Login"));

const SettingsPage = React.lazy(() => import("./Pages/Settings/SettingsPage"));

const ForgotPassword = React.lazy(() =>
  import("./Pages/ForgotPassword/ForgotPassword")
);

class AppRaw extends React.Component {
  @observable private rootStore = new RootStore();
  public render() {
    return (
      <SiteContext.Provider value={this.rootStore.siteState}>
        <Router>
          <div className="App">
            <Header rootStore={this.rootStore} />
            <Route path="/" exact component={this.renderLogin} />
            <Route
              exact={true}
              path="/builds"
              component={this.renderDashboard}
            />
            <Route
              path="/builds/:buildId"
              render={(props: RouteComponentProps<any>) =>
                this.renderBuild(props.match.params.buildId)
              }
            />
            <Route path="/rules" component={this.renderRules} />
            <Route path="/repository" component={this.renderRepositoryView} />
            <Route path="/settings" component={this.renderSettings} />
            <Route
              path="/forgot_password"
              component={this.renderForgotPassword}
            />
          </div>
        </Router>
      </SiteContext.Provider>
    );
  }

  renderFallback = () => {
    return (
      <div className="FallbackRender">
        <LinearProgress />
      </div>
    );
  };

  private renderBuild = (buildId: string) => {
    return (
      <Suspense fallback={this.renderFallback()}>
        <TreeBuild rootStore={this.rootStore} buildId={buildId} />
      </Suspense>
    );
  };
  private renderRules = () => {
    return (
      <Suspense fallback={this.renderFallback()}>
        <RuleIndex rootStore={this.rootStore} />
      </Suspense>
    );
  };
  private renderDashboard = () => {
    return (
      <Suspense fallback={this.renderFallback()}>
        <BuildIndex rootStore={this.rootStore} />
      </Suspense>
    );
  };
  private renderRepositoryView = () => {
    return (
      <Suspense fallback={this.renderFallback()}>
        <Repository {...this.rootStore} />
      </Suspense>
    );
  };
  private renderLogin = () => {
    return (
      <Suspense fallback={this.renderFallback()}>
        <LoginWithRouter rootStore={this.rootStore} />
      </Suspense>
    );
  };
  private renderSettings = () => {
    return (
      <Suspense fallback={this.renderFallback()}>
        <SettingsPage rootStore={this.rootStore} />
      </Suspense>
    );
  };
  private renderForgotPassword = () => {
    return (
      <Suspense fallback={this.renderFallback()}>
        <ForgotPassword rootStore={this.rootStore} />
      </Suspense>
    );
  };
}
export const App = DragDropContext(HTML5Backend)(
  AppRaw
) as React.ComponentClass;

export default App;
