import { observable } from "mobx";

import {
  PropGroup,
  PropertyDescriptor,
  Software,
  IComponentProps,
  MezzanineSlot,
  ComponentSlot,
  IComputedProps,
  getComponentRuleProperties,
  DifferenceSet,
} from "../ComponentProperties";
import {
  PropertyType,
  PermissionLevels,
  PERMISSION_LEVEL_STRINGS,
} from "../../Repository/PropertySchema";
import { ComponentType } from "../Component";
import { FileData } from "../FileData";
import { RulePropertySchema } from "../Rule";
import { GrantedGroupPermission, GrantedUserPermission } from "../UserGroup";
import { USER_ID_SESSION_ATTRIBUTE_NAME } from "../../../Pages/Login/AuthenticationService";
import { ComputedPower } from "../Subcomponents/ComputedPower";
import { BaseComponent } from "./BaseComponent";

export class PowerSupplyProps extends BaseComponent implements IComponentProps {
  @observable public form: string = "";
  @observable public mezzanineSlots: MezzanineSlot[] = [];
  @observable public type: string = "Power";

  @observable public connectorType: string = "";

  @observable public noise: string = "";
  @observable public ripple: string = "";
  @observable public overvoltageProtection: string = "";
  @observable public reversePolarityProtection: string = "";
  @observable public shortCircuitProtection: string = "";
  @observable public twoLevelMaintenance: string = "";
  @observable public loadSharing: string = "";
  @observable public holdupTimeInSeconds?: number;
  @observable public holdupTimeWattage?: number;
  @observable public clock: string = "";

  constructor(source?: PowerSupplyProps) {
    super();
    if (source) {
      Object.assign(this, source);
      this.mezzanineSlots = source.mezzanineSlots.map(
        (slot) => new MezzanineSlot(slot)
      );
      this.software = source.software.map((software) => new Software(software));
      this.groupAccess = source.groupAccess.map(
        (access) => new GrantedGroupPermission(access)
      );
      this.userAccess = source.userAccess.map(
        (access) => new GrantedUserPermission(access)
      );
      this.documentation = source.documentation.map(
        (documentation) => new FileData(documentation)
      );
    }
  }

  public clone(keepId?: boolean): PowerSupplyProps {
    var clone = new PowerSupplyProps(this);
    if (!keepId) {
      clone.id = undefined;
      clone.model = this.model + " (Clone)";
    }
    var currentUserId = sessionStorage.getItem(USER_ID_SESSION_ATTRIBUTE_NAME);
    if (currentUserId) {
      clone.ownerId = currentUserId;
    }
    return clone;
  }

  public containsEmptyRequiredFields(): boolean {
    if (this.model.length <= 0) return true;
    return false;
  }

  public getId(): string | undefined {
    return this.id;
  }
  public getComponentType(): ComponentType {
    return ComponentType.PowerSupply;
  }

  public getTitle(): string {
    return this.model;
  }

  public getSubtext(): string {
    return this.powerType;
  }
  public getSlots(): ComponentSlot[] {
    return this.mezzanineSlots
      .slice()
      .sort((a, b) => (a.index >= b.index ? 1 : -1));
  }
  public getTopPropertySchema() {
    return POWER_TOP_PROPERTY_SCHEMA;
  }
  public getPermissionPropertySchema(): PropGroup<PowerSupplyProps>[] {
    return POWER_PERMISSION_PROPERTY_SCHEMA;
  }
  public getPropertySchema(): PropGroup<PowerSupplyProps>[] {
    return POWER_PROPERTY_SCHEMA;
  }
  public getRepositoryProperties(): PropertyDescriptor<PowerSupplyProps>[] {
    return [
      new PropertyDescriptor("vendor", "Vendor"),
      new PropertyDescriptor(
        "powerTotalInWatts",
        "Power",
        PropertyType.Number,
        "Watts"
      ),
      new PropertyDescriptor(
        "efficiencyPercent",
        "Efficiency",
        PropertyType.Number,
        "%"
      ),
      new PropertyDescriptor("powerType", "Type"),
    ];
  }
  public addToList<PowerSupplyProps>(propName: keyof PowerSupplyProps) {
    if (propName === "mezzanineSlots") {
      this.mezzanineSlots.push(
        new MezzanineSlot({ index: this.mezzanineSlots.length + 1 })
      );
    } else if (propName === "software") {
      this.software.push(new Software({}));
    } else if (propName === "groupAccess") {
      this.groupAccess.push(new GrantedGroupPermission({}));
    } else if (propName === "userAccess") {
      this.userAccess.push(new GrantedUserPermission({}));
    } else if (propName === "documentation") {
      this.documentation.push(new FileData());
    }
  }
  public removeFromList<ModuleProps>(
    propName: keyof ModuleProps,
    index: number
  ) {
    if (propName === "mezzanineSlots") {
      this.mezzanineSlots.splice(index, 1);
    } else if (propName === "software") {
      this.software.splice(index, 1);
    } else if (propName === "groupAccess") {
      this.groupAccess.splice(index, 1);
    } else if (propName === "userAccess") {
      this.userAccess.splice(index, 1);
    } else if (propName === "documentation") {
      this.documentation.splice(index, 1);
    }
  }
  public asImplementation(): PowerSupplyProps {
    return this;
  }

  public static getRulePropertySchema(): RulePropertySchema[] {
    const properties = getComponentRuleProperties(
      POWER_PROPERTY_LIST as PropGroup<IComponentProps>[]
    );
    //  properties.push({name: "type", display: "Module Type", computed: false, listCount: false, type: PropertyType.Text})
    return properties.sort((a, b) => a.display.localeCompare(b.display));
  }

  public compareTo(other: PowerSupplyProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();

    diffs.addDiffs(this.getVendorDifferences(other));
    diffs.addDiffs(this.getPhysicalDifferences(other));
    diffs.addDiffs(this.getHostDifferences(other));
    diffs.addDiffs(this.getSystemDifferences(other));
    diffs.addDiffs(this.getPowerDifferences(other, false, true));

    if (this.form !== other.form) diffs.addDiff("Connector", "form");
    if (this.connectorType !== other.connectorType)
      diffs.addDiff("Connector", "connectorType");

    diffs.compareProps("Mezzanines", this.mezzanineSlots, other.mezzanineSlots);

    diffs.compareProps("SW / FW / BSP Support", this.software, other.software);

    if (this.clock !== other.clock) diffs.addDiff("Other", "clock");
    if (this.overvoltageProtection !== other.overvoltageProtection)
      diffs.addDiff("Other", "overvoltageProtection");
    if (this.reversePolarityProtection !== other.reversePolarityProtection)
      diffs.addDiff("Other", "reversePolarityProtection");
    if (this.shortCircuitProtection !== other.shortCircuitProtection)
      diffs.addDiff("Other", "shortCircuitProtection");
    if (this.twoLevelMaintenance !== other.twoLevelMaintenance)
      diffs.addDiff("Other", "twoLevelMaintenance");
    if (this.loadSharing !== other.loadSharing)
      diffs.addDiff("Other", "loadSharing");
    if (this.holdupTimeInSeconds !== other.holdupTimeInSeconds)
      diffs.addDiff("Other", "holdupTimeInSeconds");
    if (this.holdupTimeWattage !== other.holdupTimeWattage)
      diffs.addDiff("Other", "holdupTimeWattage");

    return diffs;
  }

  public getOwnerId(): string {
    return this.ownerId;
  }

  public getWorldAccess(): PermissionLevels {
    return this.worldAccess;
  }

  public getGroupAccess(): GrantedGroupPermission[] {
    return this.groupAccess;
  }

  public getUserAccess(): GrantedUserPermission[] {
    return this.userAccess;
  }

  buildPowerSchema = () => {
    let powerSchema: PropGroup<PowerSupplyProps>[] = [
      this.getVendorPropGroup(),
      new PropGroup("Connector", [
        new PropertyDescriptor(
          "form",
          "Form",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["3U", "6U"]
        ),
        new PropertyDescriptor("connectorType", "Connector Type"),
      ]),
      this.getPowerPropGroup(false, true),
      new PropGroup("Mezzanine Slots", [
        new PropertyDescriptor(
          "mezzanineSlots",
          "Mezzanines",
          PropertyType.List
        ),
      ]),
      this.getEnvironmentalPropGroup(),
      this.getPhysicalPropGroup(),
      this.getSystemPropGroup(),
      new PropGroup("SW / FW / BSP Support", [
        new PropertyDescriptor(
          "software",
          "SW / FW / BSP Support",
          PropertyType.List
        ),
      ]),
      new PropGroup("Other", [
        new PropertyDescriptor("clock", "Clock"),
        new PropertyDescriptor(
          "overvoltageProtection",
          "Overvoltage Protection",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "reversePolarityProtection",
          "Reverse Polarity Protection",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "shortCircuitProtection",
          "Short Circuit Protection",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "twoLevelMaintenance",
          "Two Level Maintenance",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "loadSharing",
          "Load Sharing",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "holdupTimeInSeconds",
          "Holdup Time",
          PropertyType.Number,
          "seconds"
        ),
        new PropertyDescriptor(
          "holdupTimeWattage",
          "Holdup Wattage",
          PropertyType.Number,
          "Watts"
        ),
      ]),
      this.getHostPropGroup(),
      this.getPermissionPropGroup(),
    ];

    return powerSchema;
  };
}

export class PowerSupplyComputeds extends ComputedPower
  implements IComputedProps {
  public index: number = 0;

  constructor(source?: PowerSupplyComputeds) {
    super();
    if (source) {
      Object.assign(this, source);
    }
  }
}

const POWER_TOP_PROPERTY_SCHEMA: PropGroup<PowerSupplyProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("form", "Form"),
    new PropertyDescriptor("connectorType", "Connector Type"),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Total",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor(
      "efficiencyPercent",
      "Efficiency",
      PropertyType.Number,
      "%"
    ),
    new PropertyDescriptor("powerType", "Power Type"),
    new PropertyDescriptor("powerInputType", "Power Input Type"),
    new PropertyDescriptor("powerOutputType", "Power Output Type"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
  ]),
];

const POWER_PERMISSION_PROPERTY_SCHEMA: PropGroup<PowerSupplyProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor(
      "ownerId",
      "Owner",
      PropertyType.SingleSelectById,
      undefined,
      undefined,
      undefined,
      undefined,
      "users"
    ),
    new PropertyDescriptor("worldAccess", "World"),
    new PropertyDescriptor("groupAccess", "Groups", PropertyType.CompactList),
    new PropertyDescriptor("userAccess", "Users", PropertyType.CompactList),
  ]),
];

const POWER_PROPERTY_LIST: PropGroup<PowerSupplyProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("partNumber", "Part Number"),
    new PropertyDescriptor("seriesNumber", "Series Number"),
    new PropertyDescriptor("revisionNumber", "Revision Number"),
    new PropertyDescriptor(
      "manufacturedDate",
      "Manufactured Date",
      PropertyType.Date
    ),
    new PropertyDescriptor(
      "form",
      "Form",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["3U", "6U"]
    ),
    new PropertyDescriptor("connectorType", "Connector Type"),
    new PropertyDescriptor(
      "power12vInAmps",
      "12V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power5vInAmps",
      "5V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vInAmps",
      "3.3V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vAuxInAmps",
      "3.3V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxPlusInAmps",
      "+12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxNegInAmps",
      "-12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Power Supply Total Power",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor(
      "efficiencyPercent",
      "Efficiency",
      PropertyType.Number,
      "%"
    ),
    new PropertyDescriptor("powerType", "Power Type"),
    new PropertyDescriptor("powerInputType", "Power Input Type"),
    new PropertyDescriptor("powerOutputType", "Power Output Type"),
    new PropertyDescriptor("mezzanineSlots", "Mezzanines", PropertyType.List),
    new PropertyDescriptor(
      "coolingType",
      "Cooling Type",
      PropertyType.SingleSelect
    ),
    new PropertyDescriptor(
      "operatingTemperatureMinInCelsius",
      "Min Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "operatingTemperatureMaxInCelsius",
      "Max Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMinInCelsius",
      "Min Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMaxInCelsius",
      "Max Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "temperatureCycling",
      "Temperature Cycling",
      PropertyType.Flag
    ),
    new PropertyDescriptor("noise", "Noise"),
    new PropertyDescriptor("ripple", "Ripple"),
    new PropertyDescriptor("vibration", "Vibration"),
    new PropertyDescriptor("shock", "Shock"),
    new PropertyDescriptor("humidity", "Humidity"),
    new PropertyDescriptor(
      "operatingAltitudeMinInFeet",
      "Min Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "operatingAltitudeMaxInFeet",
      "Max Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "rapidDecompression",
      "Rapid Decompression",
      PropertyType.Flag
    ),
    new PropertyDescriptor("fungusResistance", "Fungus Resistance"),
    new PropertyDescriptor(
      "electrostaticDischargeResistance",
      "Electrostatic Discharge Resistance"
    ),
    new PropertyDescriptor("corrosionResistance", "Corrosion Resistance"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor(
      "lengthInInches",
      "Length",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "widthInInches",
      "Width",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "weightInGrams",
      "Weight",
      PropertyType.Number,
      "Grams"
    ),
    new PropertyDescriptor(
      "ipmcImplemented",
      "IPMC Implemented",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier1Controller",
      "Tier 1 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier2Controller",
      "Tier 2 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "chassisManager",
      "Chassis Manager",
      PropertyType.Flag
    ),
    new PropertyDescriptor("clock", "Clock"),
    new PropertyDescriptor("software", "Software Support", PropertyType.List),
    new PropertyDescriptor(
      "overvoltageProtection",
      "Overvoltage Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "reversePolarityProtection",
      "Reverse Polarity Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "shortCircuitProtection",
      "Short Circuit Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "twoLevelMaintenance",
      "Two Level Maintenance",
      PropertyType.Flag
    ),
    new PropertyDescriptor("loadSharing", "Load Sharing", PropertyType.Flag),
    new PropertyDescriptor(
      "holdupTimeInSeconds",
      "Holdup Time",
      PropertyType.Number,
      "seconds"
    ),
    new PropertyDescriptor(
      "holdupTimeWattage",
      "Holdup Wattage",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor("hostConformancy", "Conformant", PropertyType.Flag),
    new PropertyDescriptor(
      "hostConformancyLevel",
      "Level of Conformance",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Level A", "Level B"]
    ),
    new PropertyDescriptor("tier3", "Tier 3"),
  ]),
];

const POWER_PROPERTY_SCHEMA: PropGroup<PowerSupplyProps>[] = [
  new PropGroup("Vendor", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("partNumber", "Part Number"),
    new PropertyDescriptor("seriesNumber", "Series Number"),
    new PropertyDescriptor("revisionNumber", "Revision Number"),
    new PropertyDescriptor(
      "manufacturedDate",
      "Manufactured Date",
      PropertyType.Date
    ),
    new PropertyDescriptor("costInUSD", "Cost", PropertyType.Number, "USD"),
  ]),
  new PropGroup("Connector", [
    new PropertyDescriptor(
      "form",
      "Form",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["3U", "6U"]
    ),
    new PropertyDescriptor("connectorType", "Connector Type"),
  ]),
  new PropGroup("Power", [
    new PropertyDescriptor(
      "power12vInAmps",
      "12V",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor("power5vInAmps", "5V", PropertyType.Number, "Amps"),
    new PropertyDescriptor(
      "power3_3vInAmps",
      "3.3V",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vAuxInAmps",
      "3.3V Aux",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxPlusInAmps",
      "+12V Aux",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxNegInAmps",
      "-12V Aux",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Total",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor(
      "efficiencyPercent",
      "Efficiency",
      PropertyType.Number,
      "%"
    ),
    new PropertyDescriptor("powerType", "Power Type"),
    new PropertyDescriptor("powerInputType", "Power Input Type"),
    new PropertyDescriptor("powerOutputType", "Power Output Type"),
  ]),
  new PropGroup("Mezzanine Slots", [
    new PropertyDescriptor("mezzanineSlots", "Mezzanines", PropertyType.List),
  ]),
  new PropGroup("Environmental", [
    new PropertyDescriptor(
      "coolingType",
      "Cooling Type",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Air", "Conduction", "AFT", "LFT", "Air Flow-By"]
    ),
    new PropertyDescriptor(
      "operatingTemperatureMinInCelsius",
      "Min Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "operatingTemperatureMaxInCelsius",
      "Max Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMinInCelsius",
      "Min Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMaxInCelsius",
      "Max Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "temperatureCycling",
      "Temperature Cycling",
      PropertyType.Flag
    ),
    new PropertyDescriptor("noise", "Noise"),
    new PropertyDescriptor("ripple", "Ripple"),
    new PropertyDescriptor("vibration", "Vibration"),
    new PropertyDescriptor("shock", "Shock"),
    new PropertyDescriptor("humidity", "Humidity"),
    new PropertyDescriptor(
      "operatingAltitudeMinInFeet",
      "Min Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "operatingAltitudeMaxInFeet",
      "Max Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "rapidDecompression",
      "Rapid Decompression",
      PropertyType.Flag
    ),
    new PropertyDescriptor("fungusResistance", "Fungus Resistance"),
    new PropertyDescriptor(
      "electrostaticDischargeResistance",
      "Electrostatic Discharge Resistance"
    ),
    new PropertyDescriptor("corrosionResistance", "Corrosion Resistance"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor("mtbf", "MTBF", PropertyType.Number, "Hours"),
  ]),
  new PropGroup("Physical", [
    new PropertyDescriptor(
      "lengthInInches",
      "Length",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "widthInInches",
      "Width",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "weightInGrams",
      "Weight",
      PropertyType.Number,
      "Grams"
    ),
  ]),
  new PropGroup("System Management", [
    new PropertyDescriptor(
      "ipmcImplemented",
      "IPMC Implemented",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier1Controller",
      "Tier 1 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier2Controller",
      "Tier 2 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "chassisManager",
      "Chassis Manager",
      PropertyType.Flag
    ),
  ]),
  new PropGroup("SW / FW / BSP Support", [
    new PropertyDescriptor(
      "software",
      "SW / FW / BSP Support",
      PropertyType.List
    ),
  ]),
  new PropGroup("Other", [
    new PropertyDescriptor("clock", "Clock"),
    new PropertyDescriptor(
      "overvoltageProtection",
      "Overvoltage Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "reversePolarityProtection",
      "Reverse Polarity Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "shortCircuitProtection",
      "Short Circuit Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "twoLevelMaintenance",
      "Two Level Maintenance",
      PropertyType.Flag
    ),
    new PropertyDescriptor("loadSharing", "Load Sharing", PropertyType.Flag),
    new PropertyDescriptor(
      "holdupTimeInSeconds",
      "Holdup Time",
      PropertyType.Number,
      "seconds"
    ),
    new PropertyDescriptor(
      "holdupTimeWattage",
      "Holdup Wattage",
      PropertyType.Number,
      "Watts"
    ),
  ]),
  new PropGroup("HOST", [
    new PropertyDescriptor("hostConformancy", "Conformant", PropertyType.Flag),
    new PropertyDescriptor(
      "hostConformancyLevel",
      "Level of Conformance",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Level A", "Level B"]
    ),
    new PropertyDescriptor("tier3", "Tier 3"),
  ]),
  new PropGroup("Documentation", [
    new PropertyDescriptor(
      "documentation",
      "Documentation",
      PropertyType.CompactList
    ),
  ]),
  new PropGroup("Permissions", [
    new PropertyDescriptor(
      "ownerId",
      "Owner",
      PropertyType.SingleSelectById,
      undefined,
      undefined,
      undefined,
      undefined,
      "users"
    ),
    new PropertyDescriptor(
      "worldAccess",
      "World",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      PERMISSION_LEVEL_STRINGS
    ),
    new PropertyDescriptor("groupAccess", "Group", PropertyType.CompactList),
    new PropertyDescriptor("userAccess", "User", PropertyType.CompactList),
  ]),
];
