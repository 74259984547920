import { observable } from "mobx";

import { SearchOperator } from "../../../Data/Search";
import { ISearchAttribute, SearchOption } from "./SearchOption";

export class SearchDate extends SearchOption {
  @observable public value: Date | null;
  @observable public secondaryValue: Date | null;
  @observable public operator: SearchOperator;
  public options: SearchOperator[];
  public defaultText?: string;

  constructor(
    value: Date | null,
    operator: SearchOperator,
    options: SearchOperator[],
    attribute: ISearchAttribute,
    enabled: boolean = true,
    defaultText?: string,
    active?: boolean,
    secondaryValue?: Date | null
  ) {
    super(attribute, enabled);
    this.value = value;
    this.secondaryValue = null;
    this.operator = operator;
    this.options = options;
    this.defaultText = defaultText;
    this.active = active ? active : false;
    this.secondaryValue = secondaryValue ? secondaryValue : null;
  }

  clone = () => {
    return new SearchDate(
      this.value,
      this.operator,
      this.options,
      this.attribute,
      this.enabled,
      this.defaultText,
      this.active,
      this.secondaryValue
    );
  };

  public setValue(value: Date | null) {
    this.value = value;
  }
}
