import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Snackbar } from "@material-ui/core";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

export class SnackbarStore {
  @observable isOpen = false;
  @observable hasError = false;

  closeMessage = () => {
    this.toggleMessage();
  };

  toggleMessage = (error?: boolean) => {
    this.hasError = error ? true : false;
    this.isOpen = !this.isOpen;
  };
}

export interface SnackbarProps {
  store: SnackbarStore;
  errorText: string;
  resolutionText: string;
  hideDuration?: number;
}

@observer
export class HHITSnackbar extends React.Component<SnackbarProps> {
  render() {
    return (
      <Snackbar
        open={this.props.store.isOpen}
        onClose={this.props.store.closeMessage}
        className={"HHITSnackbar"}
        autoHideDuration={
          this.props.hideDuration ? this.props.hideDuration : 2000
        }
        action={
          <div className={"Close"} onClick={this.props.store.closeMessage}>
            <FontAwesomeIcon icon="times" />
          </div>
        }
        message={
          this.props.store.hasError ? (
            <div className={`Error`}>
              <FontAwesomeIcon
                icon="exclamation-circle"
                className={"SnackError"}
              />
              {this.props.errorText}
            </div>
          ) : (
            <div className={`Success`}>
              <FontAwesomeIcon icon="check-circle" className={"SnackCheck"} />
              {this.props.resolutionText}
            </div>
          )
        }
      />
    );
  }
}
