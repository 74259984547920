import { observable } from "mobx";

import {
  PropGroup,
  PropertyDescriptor,
  Processor,
  Plane,
  IComponentProps,
  MezzanineSlot,
  ComponentPort,
  ComponentSlot,
  IComputedProps,
  getComponentRuleProperties,
  Memory,
  Software,
  DifferenceSet,
} from "../ComponentProperties";
import {
  PropertyType,
  PermissionLevels,
} from "../../Repository/PropertySchema";
import { ComponentType } from "../Component";
import { FileData } from "../FileData";
import { RulePropertySchema } from "../Rule";
import { GrantedGroupPermission, GrantedUserPermission } from "../UserGroup";
import { USER_ID_SESSION_ATTRIBUTE_NAME } from "../../../Pages/Login/AuthenticationService";
import { BaseComponent } from "./BaseComponent";
import { ComputedPower } from "../Subcomponents/ComputedPower";

export interface ModuleProps {
  type: string;
  form: string;
  clock: string;
  slotProfile: string;
  connectorType?: string;
}

export class ModuleProps extends BaseComponent
  implements IComponentProps, ModuleProps {
  @observable public type: string = "";
  @observable public form: string = "";
  @observable public moduleProfile: string[] = [];
  @observable public slotProfile: string = "";

  @observable public processors: Processor[] = [];
  @observable public memory: Memory[] = [];
  @observable public dataPlanes: Plane[] = [];
  @observable public controlPlanes: Plane[] = [];
  @observable public expansionPlanes: Plane[] = [];
  @observable public mezzanineSlots: MezzanineSlot[] = [];
  @observable public ports: ComponentPort[] = [];
  @observable public clock: string = "";
  @observable public connectorType?: string = "";
  @observable public opticalProfile: string = "";

  constructor(source?: ModuleProps) {
    super();
    if (source) {
      Object.assign(this, source);
      this.ports = source.ports.map((port) => new ComponentPort(port));
      this.mezzanineSlots = source.mezzanineSlots.map(
        (slot) => new MezzanineSlot(slot)
      );
      this.processors = source.processors.map(
        (processor) => new Processor(processor)
      );
      this.memory = source.memory.map((memory) => new Memory(memory));
      this.dataPlanes = source.dataPlanes.map((plane) => new Plane(plane));
      this.controlPlanes = source.controlPlanes.map(
        (plane) => new Plane(plane)
      );
      this.expansionPlanes = source.expansionPlanes.map(
        (plane) => new Plane(plane)
      );
      this.software = source.software.map((software) => new Software(software));
      this.groupAccess = source.groupAccess.map(
        (access) => new GrantedGroupPermission(access)
      );
      this.userAccess = source.userAccess.map(
        (access) => new GrantedUserPermission(access)
      );
      this.documentation = source.documentation.map(
        (documentation) => new FileData(documentation)
      );
    }
  }

  public clone(keepId?: boolean): ModuleProps {
    var clone = new ModuleProps(this);
    if (!keepId) {
      clone.id = undefined;
      clone.model = this.model + " (Clone)";
    }
    var currentUserId = sessionStorage.getItem(USER_ID_SESSION_ATTRIBUTE_NAME);
    if (currentUserId) {
      clone.ownerId = currentUserId;
    }

    return clone;
  }

  public containsEmptyRequiredFields(): boolean {
    if (this.model.length <= 0) return true;
    return false;
  }

  public getId(): string | undefined {
    return this.id;
  }
  public getComponentType(): ComponentType {
    return ComponentType.Module;
  }

  public getTitle(): string {
    return this.model;
  }

  public getSubtext(): string {
    return this.moduleProfile.slice().sort().toString();
  }
  public getSlots(): ComponentSlot[] {
    return this.mezzanineSlots
      .slice()
      .sort((a, b) => (a.index >= b.index ? 1 : -1));
  }

  public getPropertySchema(): PropGroup<ModuleProps>[] {
    return this.buildModuleSchema();
  }
  public getTopPropertySchema(): PropGroup<ModuleProps>[] {
    return MODULE_TOP_PROPERTY_SCHEMA;
  }
  public getPermissionPropertySchema(): PropGroup<ModuleProps>[] {
    return MODULE_PERMISSION_PROPERTY_SCHEMA;
  }
  public getRepositoryProperties(): PropertyDescriptor<ModuleProps>[] {
    return [
      new PropertyDescriptor("moduleProfile", ""),
      new PropertyDescriptor("vendor", "Vendor"),
      new PropertyDescriptor(
        "powerTotalInWatts",
        "Power",
        PropertyType.Number,
        "Watts"
      ),
      new PropertyDescriptor(
        "mezzanineSlots",
        "Mezzanine Count",
        PropertyType.Count
      ),
    ];
  }
  public addToList<ModuleProps>(propName: keyof ModuleProps) {
    // TODO: Push automatically (across all component types) based on propName. Do not use a long list of conditionals.
    if (propName === "processors") {
      this.processors.push(
        new Processor({ index: this.processors.length + 1 })
      );
    } else if (propName === "mezzanineSlots") {
      this.mezzanineSlots.push(
        new MezzanineSlot({ index: this.mezzanineSlots.length + 1 })
      );
    } else if (propName === "ports") {
      this.ports.push(new ComponentPort({}));
    } else if (propName === "memory") {
      this.memory.push(new Memory({}));
    } else if (propName === "dataPlanes") {
      this.dataPlanes.push(new Plane({}));
    } else if (propName === "controlPlanes") {
      this.controlPlanes.push(new Plane({}));
    } else if (propName === "expansionPlanes") {
      this.expansionPlanes.push(new Plane({}));
    } else if (propName === "software") {
      this.software.push(new Software({}));
    } else if (propName === "groupAccess") {
      this.groupAccess.push(new GrantedGroupPermission({}));
    } else if (propName === "userAccess") {
      this.userAccess.push(new GrantedUserPermission({}));
    } else if (propName === "documentation") {
      this.documentation.push(new FileData());
    }
  }
  public removeFromList<ModuleProps>(
    propName: keyof ModuleProps,
    index: number
  ) {
    // TODO: Splice automatically (across all component types) based on propName. Do not use a long list of conditionals.
    if (propName === "processors") {
      this.processors.splice(index, 1);
    } else if (propName === "mezzanineSlots") {
      this.mezzanineSlots.splice(index, 1);
    } else if (propName === "ports") {
      this.ports.splice(index, 1);
    } else if (propName === "memory") {
      this.memory.splice(index, 1);
    } else if (propName === "dataPlanes") {
      this.dataPlanes.splice(index, 1);
    } else if (propName === "controlPlanes") {
      this.controlPlanes.splice(index, 1);
    } else if (propName === "expansionPlanes") {
      this.expansionPlanes.splice(index, 1);
    } else if (propName === "software") {
      this.software.splice(index, 1);
    } else if (propName === "groupAccess") {
      this.groupAccess.splice(index, 1);
    } else if (propName === "userAccess") {
      this.userAccess.splice(index, 1);
    } else if (propName === "documentation") {
      this.documentation.splice(index, 1);
    }
  }
  public asImplementation(): ModuleProps {
    return this;
  }

  public static getRulePropertySchema(): RulePropertySchema[] {
    const properties = getComponentRuleProperties(
      MODULE_PROPERTY_LIST as PropGroup<IComponentProps>[]
    );
    properties.push.apply(properties, [
      {
        name: "dataPlaneLowestRateInGBd",
        display: "Min Gbaud Rate - Data Planes",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "controlPlaneLowestRateInGBd",
        display: "Min Gbaud Rate - Control Planes",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "expansionPlaneLowestRateInGBd",
        display: "Min Gbaud Rate - Expansion Planes",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "dataPlaneHighestRateInGBd",
        display: "Max Gbaud Rate - Data Planes",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "controlPlaneHighestRateInGBd",
        display: "Max Gbaud Rate - Control Planes",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "expansionPlaneHighestRateInGBd",
        display: "Max Gbaud Rate - Expansion Planes",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
    ]);
    properties.push.apply(
      properties,
      MezzanineSlot.getRulePropertySchema("mezzanineSlots", "Mezzanine Slot")
    );
    properties.push.apply(
      properties,
      ComponentPort.getRulePropertySchema("ports", "Port")
    );
    properties.push.apply(
      properties,
      Processor.getRulePropertySchema("processors", "Processor")
    );
    properties.push.apply(
      properties,
      Memory.getRulePropertySchema("memory", "Memory")
    );
    properties.push.apply(
      properties,
      Software.getRulePropertySchema("software", "Software")
    );
    properties.push.apply(
      properties,
      Plane.getRulePropertySchema("dataPlanes", "Data Plane")
    );
    properties.push.apply(
      properties,
      Plane.getRulePropertySchema("controlPlanes", "Control Plane")
    );
    properties.push.apply(
      properties,
      Plane.getRulePropertySchema("expansionPlanes", "Expansion Plane")
    );

    return properties.sort((a, b) => a.display.localeCompare(b.display));
  }

  public compareTo(other: ModuleProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();

    diffs.addDiffs(this.getEnvironmentalDifferences(other));
    diffs.addDiffs(this.getVendorDifferences(other));
    diffs.addDiffs(this.getPhysicalDifferences(other));
    diffs.addDiffs(this.getHostDifferences(other));
    diffs.addDiffs(this.getSystemDifferences(other));
    diffs.addDiffs(this.getPowerDifferences(other, false, false));

    if (this.type !== other.type) diffs.addDiff("Profile", "type");
    if (this.form !== other.form) diffs.addDiff("Profile", "form");
    if (this.moduleProfile !== other.moduleProfile)
      diffs.addDiff("Profile", "moduleProfile");
    if (this.slotProfile !== other.slotProfile)
      diffs.addDiff("Profile", "slotProfile");

    diffs.compareProps("Processors", this.processors, other.processors);
    diffs.compareProps("Memory", this.memory, other.memory);

    diffs.compareProps("Data Planes", this.dataPlanes, other.dataPlanes);
    diffs.compareProps(
      "Control Planes",
      this.controlPlanes,
      other.controlPlanes
    );
    diffs.compareProps(
      "Expansion Planes",
      this.expansionPlanes,
      other.expansionPlanes
    );

    if (this.connectorType !== other.connectorType)
      diffs.addDiff("Connector Type", "connectorType");

    diffs.compareProps("Mezzanines", this.mezzanineSlots, other.mezzanineSlots);
    diffs.compareProps("Ports", this.ports, other.ports);

    diffs.compareProps("SW / FW / BSP Support", this.software, other.software);

    if (this.clock !== other.clock) diffs.addDiff("Other", "clock");

    return diffs;
  }

  public getOwnerId(): string {
    return this.ownerId;
  }

  public getWorldAccess(): PermissionLevels {
    return this.worldAccess;
  }

  public getGroupAccess(): GrantedGroupPermission[] {
    return this.groupAccess;
  }

  public getUserAccess(): GrantedUserPermission[] {
    return this.userAccess;
  }

  buildModuleSchema = () => {
    let moduleSchema: PropGroup<ModuleProps>[] = [
      this.getVendorPropGroup(),
      new PropGroup("Profile", [
        new PropertyDescriptor(
          "moduleProfile",
          "Module Profile",
          PropertyType.ModuleProfile
        ),
      ]),
      new PropGroup("Processors", [
        new PropertyDescriptor("processors", "Processors", PropertyType.List),
      ]),
      new PropGroup("Memory", [
        new PropertyDescriptor("memory", "Memory", PropertyType.List),
      ]),
      this.getPowerPropGroup(false, false),
      new PropGroup("Data Plane", [
        new PropertyDescriptor("dataPlanes", "Data Planes", PropertyType.List),
      ]),
      new PropGroup("Control Plane", [
        new PropertyDescriptor(
          "controlPlanes",
          "Control Planes",
          PropertyType.List
        ),
      ]),
      new PropGroup("Expansion Plane", [
        new PropertyDescriptor(
          "expansionPlanes",
          "Expansion Planes",
          PropertyType.List
        ),
      ]),
      new PropGroup("Connector Type", [
        new PropertyDescriptor(
          "connectorType",
          "Connector Type",
          PropertyType.DependentSingleSelectDB,
          undefined,
          undefined,
          undefined,
          undefined,
          "aperture-pattern",
          new PropertyDescriptor(
            "opticalProfile",
            "Optical Profile",
            PropertyType.SingleSelectDB,
            undefined,
            undefined,
            undefined,
            undefined,
            "apperature-pattern-optical-profile"
          )
        ),
      ]),
      new PropGroup("Mezzanine Slots", [
        new PropertyDescriptor(
          "mezzanineSlots",
          "Mezzanines",
          PropertyType.List
        ),
      ]),
      new PropGroup("Ports", [
        new PropertyDescriptor("ports", "Ports", PropertyType.List),
      ]),
      this.getEnvironmentalPropGroup(),
      this.getPhysicalPropGroup(),
      this.getSystemPropGroup(),
      new PropGroup("SW / FW / BSP Support", [
        new PropertyDescriptor(
          "software",
          "SW / FW / BSP Support",
          PropertyType.List
        ),
      ]),
      new PropGroup("Other", [new PropertyDescriptor("clock", "Clock")]),
      this.getHostPropGroup(),
      this.getPermissionPropGroup(),
    ];

    return moduleSchema;
  };
}

export class ModuleComputeds extends ComputedPower implements IComputedProps {
  public dataPlaneLowestRateInGBd: number = 0;
  public controlPlaneLowestRateInGBd: number = 0;
  public expansionPlaneLowestRateInGBd: number = 0;
  public dataPlaneHighestRateInGBd: number = 0;
  public controlPlaneHighestRateInGBd: number = 0;
  public expansionPlaneHighestRateInGBd: number = 0;

  constructor(source?: ModuleComputeds) {
    super();
    if (source) {
      Object.assign(this, source);
    }
  }
}

const MODULE_TOP_PROPERTY_SCHEMA: PropGroup<ModuleProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name"),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("type", "Type", PropertyType.SingleSelect),
    new PropertyDescriptor("form", "Form", PropertyType.SingleSelect),
    new PropertyDescriptor("moduleProfile", "Profile"),
    new PropertyDescriptor("processors", "Processors", PropertyType.List),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Total Power",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor("dataPlanes", "Data Planes", PropertyType.List),
    new PropertyDescriptor(
      "controlPlanes",
      "Control Planes",
      PropertyType.List
    ),
    new PropertyDescriptor(
      "expansionPlanes",
      "Expansion Planes",
      PropertyType.List
    ),
    new PropertyDescriptor(
      "mezzanineSlots",
      "Mezzanine Count",
      PropertyType.Count
    ),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
  ]),
];

const MODULE_PERMISSION_PROPERTY_SCHEMA: PropGroup<ModuleProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor(
      "ownerId",
      "Owner",
      PropertyType.SingleSelectById,
      undefined,
      undefined,
      undefined,
      undefined,
      "users"
    ),
    new PropertyDescriptor("worldAccess", "World"),
    new PropertyDescriptor("groupAccess", "Groups", PropertyType.CompactList),
    new PropertyDescriptor("userAccess", "Users", PropertyType.CompactList),
  ]),
];

const MODULE_PROPERTY_LIST: PropGroup<ModuleProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("partNumber", "Part Number"),
    new PropertyDescriptor("seriesNumber", "Series Number"),
    new PropertyDescriptor("revisionNumber", "Revision Number"),
    new PropertyDescriptor(
      "manufacturedDate",
      "Manufactured Date",
      PropertyType.Date
    ),
    new PropertyDescriptor(
      "type",
      "Module Type",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Payload", "Switch", "Time", "Peripheral", "Storage"]
    ),
    new PropertyDescriptor(
      "form",
      "Module Form",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["3U", "6U"]
    ),
    new PropertyDescriptor("moduleProfile", "Module Profile"),
    new PropertyDescriptor("slotProfile", "Slot Profile"),
    new PropertyDescriptor("processors", "Processors", PropertyType.List),
    new PropertyDescriptor("memory", "Memory", PropertyType.List),
    new PropertyDescriptor(
      "power12vInAmps",
      "12V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power5vInAmps",
      "5V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vInAmps",
      "3.3V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vAuxInAmps",
      "3.3V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxPlusInAmps",
      "+12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxNegInAmps",
      "-12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Module Total Power",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor("dataPlanes", "Data Planes", PropertyType.List),
    new PropertyDescriptor(
      "controlPlanes",
      "Control Planes",
      PropertyType.List
    ),
    new PropertyDescriptor(
      "expansionPlanes",
      "Expansion Planes",
      PropertyType.List
    ),
    new PropertyDescriptor(
      "mezzanineSlots",
      "Mezzanine Slots",
      PropertyType.List
    ),
    new PropertyDescriptor("ports", "Ports", PropertyType.List),
    new PropertyDescriptor(
      "coolingType",
      "Cooling Type",
      PropertyType.SingleSelect
    ),
    new PropertyDescriptor(
      "operatingTemperatureMinInCelsius",
      "Min Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "operatingTemperatureMaxInCelsius",
      "Max Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMinInCelsius",
      "Min Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMaxInCelsius",
      "Max Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "temperatureCycling",
      "Temperature Cycling",
      PropertyType.Flag
    ),
    new PropertyDescriptor("vibration", "Vibration"),
    new PropertyDescriptor("shock", "Shock"),
    new PropertyDescriptor("humidity", "Humidity"),
    new PropertyDescriptor(
      "operatingAltitudeMinInFeet",
      "Min Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "operatingAltitudeMaxInFeet",
      "Max Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "rapidDecompression",
      "Rapid Decompression",
      PropertyType.Flag
    ),
    new PropertyDescriptor("fungusResistance", "Fungus Resistance"),
    new PropertyDescriptor(
      "electrostaticDischargeResistance",
      "Electrostatic Discharge Resistance"
    ),
    new PropertyDescriptor("corrosionResistance", "Corrosion Resistance"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor(
      "lengthInInches",
      "Length",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "widthInInches",
      "Width",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "weightInGrams",
      "Weight",
      PropertyType.Number,
      "Grams"
    ),
    new PropertyDescriptor(
      "ipmcImplemented",
      "IPMC Implemented",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier1Controller",
      "Tier 1 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier2Controller",
      "Tier 2 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "chassisManager",
      "Chassis Manager",
      PropertyType.Flag
    ),
    new PropertyDescriptor("clock", "Clock"),
    new PropertyDescriptor("software", "Software Support", PropertyType.List),
    new PropertyDescriptor("hostConformancy", "Conformant", PropertyType.Flag),
    new PropertyDescriptor(
      "hostConformancyLevel",
      "Level of Conformance",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Level A", "Level B"]
    ),
    new PropertyDescriptor("tier3", "Tier 3"),
    new PropertyDescriptor(
      "connectorType",
      "Connector Type",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      [
        "66.1",
        "66.2",
        "66.3",
        "66.4",
        "67.1",
        "67.2",
        "67.3 Module A",
        "67.3 Module B",
        "67.3 Module C",
        "67.3 Module D",
        "67.3 Module E",
      ]
    ),
  ]),
];
