import axios from "axios";
import { UiStore } from "../../Data/Store/UiStore";

export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";
export const IS_ADMIN_SESSION_ATTRIBUTE_NAME = "isAdmin";
export const USER_ID_SESSION_ATTRIBUTE_NAME = "id";
export const USER_FIRSTNAME_SESSION_ATTRIBUTE_NAME = "firstName";
export const USER_LASTNAME_SESSION_ATTRIBUTE_NAME = "lastName";
export const USER_MEASUREMENTSYSTEM_SESSION_ATTRIBUTE_NAME = 'measurementSystem';
export const USER_INFOCOLOR_SESSION_ATTRIBUTE_NAME = "informationColor";
export const USER_WARNINGCOLOR_SESSION_ATTRIBUTE_NAME = "warningColor";
export const USER_CAUTIONCOLOR_SESSION_ATTRIBUTE_NAME = "cautionColor";

class AuthenticationService {
  executeBasicAuthenticationService(username: string, password: string) {
    return axios.get(`/api/controllers/user/login`, {
      headers: {
        authorization: this.createBasicAuthToken(username, password),
        "X-Requested-With": "XMLHttpRequest",
      },
    });
  }

  createBasicAuthToken(username: string, password: string) {
    return "Basic " + window.btoa(username + ":" + password);
  }

  registerSuccessfulLogin(
    username: string,
    password: string,
    isAdmin: boolean,
    id: string,
    firstName: string,
    lastName: string,
    measurementSystem: string,
    informationColor: string,
    warningColor: string,
    cautionColor: string,
    uiStore: UiStore    
  ) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
    sessionStorage.setItem(
      IS_ADMIN_SESSION_ATTRIBUTE_NAME,
      isAdmin ? "true" : "false"
    );
    sessionStorage.setItem(USER_ID_SESSION_ATTRIBUTE_NAME, id);
    sessionStorage.setItem(USER_FIRSTNAME_SESSION_ATTRIBUTE_NAME, firstName);
    sessionStorage.setItem(USER_LASTNAME_SESSION_ATTRIBUTE_NAME, lastName);
    sessionStorage.setItem(USER_MEASUREMENTSYSTEM_SESSION_ATTRIBUTE_NAME, measurementSystem);
    sessionStorage.setItem(USER_INFOCOLOR_SESSION_ATTRIBUTE_NAME, informationColor);
    sessionStorage.setItem(USER_WARNINGCOLOR_SESSION_ATTRIBUTE_NAME, warningColor);
    sessionStorage.setItem(USER_CAUTIONCOLOR_SESSION_ATTRIBUTE_NAME, cautionColor);
    uiStore.reinitializeErrorColors();
    this.setupAxiosInterceptors(this.createBasicAuthToken(username, password));
  }

  registerSuccessfulLoginForJwt(username: string, token: string) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
    this.setupAxiosInterceptors(this.createJWTToken(token));
  }

  createJWTToken(token: string) {
    return "Bearer " + token;
  }

  async executeLogout() {
    try {
      const response = await axios.get(`/api/controllers/user/logout`, {
        headers: {
          authorization: this.createBasicAuthToken("dummy", "dummy"),
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      console.log("executeLogout...");
      if (response.status === 204) {
        //get this from the GET on the logout call
        console.log("executeLogout 204...");
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        window.location.href = "/";
      } else {
        return response && response.data;
      }
    } catch (error) {
      if (error.response) {
        console.log("executeLogout error..." + error.response.status);
      }
    }
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (user === null) return false;
    return true;
  }

  getLoggedInUserName() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (user === null) return "";
    return user;
  }

  setupAxiosInterceptors(token: string) {
    axios.interceptors.request.use((config) => {
      if (this.isUserLoggedIn()) {
        config.headers.authorization = token;
      }
      return config;
    });
  }
}

export default new AuthenticationService();
