/**
 * Utilities for converting JSON-deserialized objects into proper classes
 */

export function hydrateMap<T>(
  map: Record<string, T>,
  construct: { new (copy: T): T }
): Record<string, T> {
  const newMap: Record<string, T> = {};
  for (const key in map) {
    newMap[key] = new construct(map[key]);
  }
  return newMap;
}
