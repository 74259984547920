import { observable, computed, reaction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { Link as ScrollLink, animateScroll } from "react-scroll";

import { HhitsButton } from "../../Components/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinearProgress } from "@material-ui/core";
import {
  PropGroup,
  PropertyDescriptor,
} from "../../Data/Schema/ComponentProperties";

import { User } from "../../Data/Schema/User";
import { getUser, saveUser } from "../../API/UserRepo";
import { ComponentPropertyEditor } from "../Repository/ComponentPropertyEditor";
import { USER_NAME_SESSION_ATTRIBUTE_NAME } from "../Login/AuthenticationService";
import { DEFAULT_CAUTION_COLOR, DEFAULT_INFORMATION_COLOR, DEFAULT_WARNING_COLOR, UiStore } from "../../Data/Store/UiStore";
import { HHITSnackbar, SnackbarStore } from "../../Components/HHITSnackbar";

@observer
export class UserEditor extends React.Component<{
  uiStore: UiStore;
  username: string;
  readOnly?: boolean;
  adminUser: boolean;
  onSave(leaveOpen?: boolean): void;
}> {
  @observable private user: User | undefined;
  @observable private fetchingData: boolean = false;
  @observable private saving: boolean = false;
  @observable private leaveOpen = false;
  @observable private activeGroup = 0;
  @observable private userChanged: boolean = false;
  @observable store: SnackbarStore = new SnackbarStore();

  updateBuildChanged = reaction(
    () => JSON.stringify(this.user),
    () => {
      if (!this.fetchingData) {
        this.userChanged = true;
      }
    }
  );

  public componentDidMount() {
    // TODO: Better way of doing this?
    this.fetchingData = true;
    if (this.props.username) {
      getUser(this.props.username).then((user) => {
        this.user = user;
        this.fetchingData = false;
      });
    } else {
      this.fetchingData = false;
      this.resetInputs();
    }
  }

  public render() {
    const propGroups = this.user
      ? (this.user.getPropertySchema() as PropGroup<User>[])
      : undefined;
    const body = (
      <div className="ComponentEditor">
        <div className="EditorTitleBar">
          <div className="Title">
            {this.fetchingData
              ? "Fetching component..."
              : this.props.readOnly && this.user
                ? this.user.getTitle()
                : this.props.username && this.user
                  ? `${this.user.getTitle()}`
                  : `Add New User`}
          </div>
        </div>
        {this.fetchingData && this.renderLoading()}
        <div className={`EditorContent ${this.fetchingData ? "Loading" : ""}`}>
          <div className="Scroll">
            {propGroups &&
              propGroups.map((group: PropGroup<User>, index) =>
                this.renderGroupLink(group.name, index)
              )}
          </div>
          <div
            onScroll={this.onWindowScroll}
            id="GroupsContainer"
            className="Groups"
          >
            {/* {!this.props.readOnly && <div className="HelperText">* Required</div>} */}
            {propGroups && propGroups.map((group) => this.renderGroup(group))}
          </div>
        </div>
        {!this.props.readOnly && (
          <div className="EditorActions">
            {/* {!this.props.username &&
                        <FormControlLabel
                            className={"LeaveOpen"}
                            control={
                                <Checkbox
                                    checked={this.leaveOpen}
                                    onChange={this.toggleLeaveOpen}
                                    color="primary"
                                />
                            }
                            label={"Add another?"}
                            labelPlacement={"end"}
                        />
                    } */}
            <HhitsButton
              primary={this.userChanged}
              onClick={this.saveComponent}
              disabled={this.containsEmptyRequiredFields}
            >
              {this.saving ? (
                <FontAwesomeIcon className="fa-spin" icon="circle-notch" />
              ) : (
                  "Save"
                )}
            </HhitsButton>
          </div>
        )}
        {this.store.isOpen && (
          <HHITSnackbar
            store={this.store}
            errorText={"Error saving component."}
            resolutionText={"Component saved!"}
          />
        )}
      </div>
    );
    return body;
  }

  @computed get containsEmptyRequiredFields() {
    return this.user ? this.user.containsEmptyRequiredFields() : false;
  }

  private onWindowScroll = () => {
    const parentDiv = document.getElementById("GroupsContainer");
    let parentPosition = 0;
    if (parentDiv) {
      parentPosition = parentDiv.getBoundingClientRect().top;
    }
    let groupIndex = 0;
    if (this.user) {
      for (const group of this.user.getPropertySchema()) {
        const groupDiv = document.getElementById(`Group ${group.name}`);
        if (groupDiv) {
          const groupRect = groupDiv.getBoundingClientRect();

          const positionOffset = -groupRect.height + 50;
          if (parentPosition + positionOffset < groupRect.top) {
            this.setActiveGroup(groupIndex);
            break;
          }
        }
        groupIndex++;
      }
    }
  };

  private setActiveGroup = (groupIndex: number) => {
    this.activeGroup = groupIndex;
  };

  private renderLoading() {
    return (
      <div className="Loading">
        <LinearProgress variant="query" />
      </div>
    );
  }

  private renderGroupLink(group: string, index: number) {
    return (
      <ScrollLink
        key={group}
        id={`GroupLink_${index}`}
        className={`ScrollLink${this.activeGroup === index ? " Active" : ""}`}
        to={`Group ${group}`}
        containerId="GroupsContainer"
        smooth={true}
        duration={500}
        offset={0}
      >
        {group}
      </ScrollLink>
    );
  }

  private renderGroup = (propertyGroup: PropGroup<User>) => {
    return (
      <div
        id={`Group ${propertyGroup.name}`}
        key={propertyGroup.name}
        className={`PropertyGroup`}
      >
        <div className="GroupDetails">
          <div className="GroupTitle">{propertyGroup.name}</div>
          <div className="Properties">
            {propertyGroup.properties.map((property, index) =>
              this.renderProperty(property, propertyGroup.name, index)
            )}
          </div>
        </div>
        <div className="GroupDivider" />
      </div>
    );
  };

  private renderProperty = (
    property: PropertyDescriptor<User>,
    groupName: string,
    index: number
  ) => {
    // need to figure out a way to handle making certain fields read only in certain cases like non-admin user vs admin
    var disabled: boolean = property.name === "admin" && !this.props.adminUser;
    disabled =
      disabled || (property.name === "disabled" && !this.props.adminUser);
    // also ugly, dont let user edit username unless creating new user
    disabled =
      disabled ||
      (property.name === "username" &&
        this.user != null &&
        this.user.id != null &&
        this.user.id.length > 0);
    // also don't let user edit groups - only admin
    disabled =
      disabled || (property.name === "groupIds" && !this.props.adminUser);
    if (this.user) {
      return (
        <ComponentPropertyEditor
          key={`${groupName}_${property.name}_${index}`}
          property={property}
          component={this.user}
          readOnly={this.props.readOnly}
          disabled={disabled}
        />
      );
    }

    return <></>;
  };

  

  private saveComponent = () => {
    if (this.user) {
      saveUser(this.user.asImplementation())
        .catch((error) => {
          this.saving = false;
          this.store.toggleMessage(true);
        })
        .then((results) => {
          this.saving = false;
          this.store.toggleMessage();
          // need to find better way of handling this
          // need to update session storage user attribtues if edited user is the logged in user
          // check username equals seesion username?
          var sessionUN = sessionStorage.getItem(
            USER_NAME_SESSION_ATTRIBUTE_NAME
          );
          if (sessionUN === this.props.username) {
            this.props.uiStore.setMeasurementSystem(
              this.user?.measurementSystem
                ? this.user?.measurementSystem
                : "Metric"
            );
            this.props.uiStore.setErrorColors(
              this.props.uiStore.determineErrorColor(this.user?.informationColor, DEFAULT_INFORMATION_COLOR),
              this.props.uiStore.determineErrorColor(this.user?.cautionColor, DEFAULT_CAUTION_COLOR),
              this.props.uiStore.determineErrorColor(this.user?.warningColor, DEFAULT_WARNING_COLOR)
            );
          }
          this.props.onSave(!this.props.username ? this.leaveOpen : false);
          if (this.leaveOpen) {
            this.resetInputs();
            this.leaveOpen = false;
          }
        });
    }
  };

  private resetInputs = () => {
    animateScroll.scrollToTop({ containerId: "GroupsContainer" });
    this.user = new User();
  };
}
