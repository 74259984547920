import * as React from "react";
import * as ReactDOM from "react-dom";

import "core-js/fn/array/find";
import "core-js/fn/array/find-index";
import "core-js/fn/number/is-nan";

import "./index.css";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "mobx-react-lite/batchingForReactDom";

import { importIcons } from "./Utilities/Icons";

importIcons();

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
registerServiceWorker();
