import { observable } from "mobx";
import { PropGroup, PropertyDescriptor } from "./ComponentProperties";
import { PropertyType, MeasurementSystem } from "../Repository/PropertySchema";
import {
  DEFAULT_INFORMATION_COLOR,
  DEFAULT_WARNING_COLOR,
  DEFAULT_CAUTION_COLOR
} from "../Store/UiStore";

export class User {
  public id?: string;
  @observable public username: string = "";
  @observable public admin: boolean = false;
  @observable public firstName: string = "";
  @observable public lastName: string = "";
  @observable public email: string = "";
  @observable public phone: string = "";
  @observable public disabled: boolean = false;
  @observable public loginCount: number = 0;
  @observable public lastLogin?: Date = undefined;
  @observable public groupIds: string[] = [];
  @observable public measurementSystem: MeasurementSystem = MeasurementSystem.Metric;
  @observable public informationColor: string = DEFAULT_INFORMATION_COLOR;
  @observable public warningColor: string = DEFAULT_WARNING_COLOR;
  @observable public cautionColor: string = DEFAULT_CAUTION_COLOR;


  constructor(source?: User) {
    if (source) {
      Object.assign(this, source);
    }
  }

  public getTitle(): string {
    return this.firstName + " " + this.lastName;
  }

  public containsEmptyRequiredFields(): boolean {
    if (this.firstName.length <= 0) return true;
    if (this.lastName.length <= 0) return true;
    if (this.username.length <= 0) return true;
    return false;
  }

  getPropertySchema(): PropGroup<User>[] {
    return [
      new PropGroup("User Info", [
        new PropertyDescriptor("firstName", "First Name", PropertyType.Text),
        new PropertyDescriptor("lastName", "Last Name", PropertyType.Text),
        new PropertyDescriptor("username", "Username", PropertyType.Text),
        new PropertyDescriptor("email", "Email", PropertyType.Text),
        new PropertyDescriptor("phone", "Phone", PropertyType.Text),
        new PropertyDescriptor("admin", "Is Admin", PropertyType.Flag),
        new PropertyDescriptor("disabled", "Is Disabled", PropertyType.Flag),
      ]),
      new PropGroup("Preferences", [
        new PropertyDescriptor(
          "measurementSystem",
          "Measurement System",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["Metric", "Imperial"]),
        new PropertyDescriptor(
          "informationColor",
          "Information Icon/Message Hex Color Code",
          PropertyType.Text),
        new PropertyDescriptor(
          "warningColor",
          "Warning Icon/Message Hex Color Code",
          PropertyType.Text),
        new PropertyDescriptor(
          "cautionColor",
          "Caution Icon/Message Hex Color Code",
          PropertyType.Text)
      ]),
      new PropGroup("Groups", [
        new PropertyDescriptor(
          "groupIds",
          "Groups",
          PropertyType.IdList,
          undefined,
          undefined,
          undefined,
          undefined,
          "user-groups"
        ),
      ]),
    ];
  }

  public asImplementation(): User {
    return this;
  }

  public addToList<User>(propName: keyof User) {
    if (propName === "groupIds") {
      this.groupIds.push("");
    }
  }

  public removeFromList<User>(propName: keyof User, index: number) {
    if (propName === "groupIds") {
      this.groupIds.splice(index, 1);
    }
  }
}
