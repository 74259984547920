import * as React from "react";

import { observer } from "mobx-react";
import MomentUtils from "@date-io/moment";
import { InlineDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { InputAdornment } from "@material-ui/core";
import {
  Clear as ClearIcon,
  InsertInvitation as CalendarIcon,
} from "@material-ui/icons";
import { HhitsButton } from "../Button";

export interface IDateValue {
  name?: string;
  value: Date | null;
  required?: boolean;
  onChange?(value: Date | null): void;
}

@observer
export class DateValueEditor extends React.Component<IDateValue> {
  public render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <InlineDatePicker
          required={this.props.required}
          className={"DateInput"}
          name={this.props.name}
          label={this.props.name ? this.props.name : undefined}
          value={this.props.value}
          onChange={this.onChange}
          format={"MM/DD/YYYY"}
          fullWidth={true}
          InputProps={{
            endAdornment: this.props.value ? (
              <InputAdornment position="end">
                <HhitsButton icon onClick={this.clearDate}>
                  <ClearIcon />
                </HhitsButton>
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                <HhitsButton icon>
                  <CalendarIcon />
                </HhitsButton>
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }

  private clearDate = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (this.props.onChange) {
      this.props.onChange(null);
    }
  };

  private onChange = (newValue: Date | null) => {
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };
}
