import { Get, Post, Delete } from "./Http";
import { User } from "../Data/Schema/User";
import { UserGroup } from "../Data/Schema/UserGroup";

export async function getUser(username: string): Promise<User> {
  const rawData = await Get<User>(
    `/api/controllers/users/get?username=${username}`
  );
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return new User(rawData);
}

export async function getUserByEmail(email: string): Promise<User> {
  const rawData = await Get<User>(
    `/api/controllers/users/getByEmail?email=${email}`
  );
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return new User(rawData);
}

export async function getUserGroup(groupId: string): Promise<UserGroup> {
  const rawData = await Get<UserGroup>(
    `/api/controllers/users/getGroup?groupId=${groupId}`
  );
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return new UserGroup(rawData);
}

export async function getUsers(): Promise<User[]> {
  const rawData = await Get<User[]>(`/api/controllers/users/getAll`);
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return rawData.map((user) => new User(user));
}

export async function getUserGroups(): Promise<UserGroup[]> {
  const rawData = await Get<UserGroup[]>(`/api/controllers/users/getAllGroups`);
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return rawData.map((userGroup) => new UserGroup(userGroup));
}

export async function saveUser(user: User) {
  return Post(`/api/controllers/users/save`, user);
}

export async function saveUserGroup(userGroup: UserGroup) {
  return Post(`/api/controllers/users/saveGroup`, userGroup);
}

export async function deleteUser(userId: string) {
  Delete(`/api/controllers/users/delete?userId=${userId}`);
}

export async function deleteUserGroup(groupId: string) {
  Delete(`/api/controllers/users/deleteGroup?groupId=${groupId}`);
}

export async function saveUserNewPassword(
  username: string,
  oldPassword: string,
  newPassword: string
) {
  var data: string[] = [username, oldPassword, newPassword];
  return Post(`/api/controllers/users/savePassword`, data);
}

export async function resetPassword(user: User) {
  return Post(`/api/controllers/users/resetPassword`, user);
}
