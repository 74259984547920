import { observer } from "mobx-react";
import * as React from "react";

import { Modal } from "../../Components/Modal/Modal";
import { UiStore } from "../../Data/Store/UiStore";
import { UserEditor } from "./UserEditor";

@observer
export class UserEditorModal extends React.Component<{
  uiStore: UiStore;
  username: string;
  adminUser: boolean;
  readOnly?: boolean;
  onClose(): void;
  onSave?(): void;
}> {
  public render() {
    return (
      <Modal className="EditorModal" onClose={this.props.onClose}>
        <UserEditor
          uiStore={this.props.uiStore}
          username={this.props.username}
          onSave={this.onSave}
          readOnly={this.props.readOnly}
          adminUser={this.props.adminUser}
        />
      </Modal>
    );
  }

  private onSave = (leaveOpen: boolean) => {
    if (!leaveOpen) {
      this.props.onClose();
    }
    this.props.onSave && this.props.onSave();
  };
}
