export interface KeyValuePair {
  key: string;
  value: string;
}

export function capitalizeFirstLetter(stringToCapitalize: string) {
  return (
    stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1)
  );
}

export function normalizeCamelCase(stringtoNormalize: string) {
  // Adapted from https://stackoverflow.com/questions/4149276/javascript-camelcase-to-regular-form
  return capitalizeFirstLetter(stringtoNormalize.replace(/([A-Z])/g, " $1"));
}

export function getUniqueSortedStringList(stringList: string[]) {
  // Adapted from https://stackoverflow.com/questions/11688692/how-to-create-a-list-of-unique-items-in-javascript
  return stringList.sort().filter((value, index, array) => {
    return index === 0 || value !== array[index - 1];
  });
}

export function compareStringFields(base: string, toCompare: string){
    let baseFormatted: string = base;
    let compareFormatted: string = toCompare;

    if(isLink(baseFormatted)){
        baseFormatted = getLinkCaption(baseFormatted);
    }

    if(isLink(compareFormatted)){
        compareFormatted = getLinkCaption(compareFormatted);
    }

    return baseFormatted === compareFormatted;
}

export function getLinkCaption(link: string){
    const caption = link.substring(
        link.indexOf("(") + 1,
        link.indexOf(")")
    );

    return caption;
}

export function getLinkURL(link: string){
    const url = link.substring(
        link.indexOf("[") + 1,
        link.lastIndexOf("]")
    );

    return url;
}

export function isLink(valueString: string) {
    const regEx: RegExp = /[(].*[)][[].*[\]]/;
    return regEx.test(valueString);
}

export function isDateAttribute(value: string) {
  const datePropertyName = "$date";
  return value.indexOf(datePropertyName) > 0;
}

export function parseDateAttribute(value: string) {
  const datePropertyName = "$date";
  const valueAsJson = JSON.parse(value);
  const dateValueRaw = valueAsJson[datePropertyName];
  return new Date(dateValueRaw).toISOString();
}
export function parseDateAttributeLocal(value: string) {
  const datePropertyName = "$date";
  const valueAsJson = JSON.parse(value);
  const dateValueRaw = valueAsJson[datePropertyName];
  return new Date(dateValueRaw).toLocaleDateString();
}
export function objectifyDateAttribute(dateValue: string) {
  const datePropertyName = "$date";
  const dateObject: Record<string, string> = {};
  dateObject[datePropertyName] = new Date(dateValue).toISOString();
  return dateObject;
}
