import { Rule, RuleGroup } from "../Data/Schema/Rule";
import { Get, Post, Delete } from "./Http";

export async function getRuleGroups(): Promise<RuleGroup[]> {
  const rawData = await Get<RuleGroup[]>(`/api/controllers/rules/getAllGroups`);
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return rawData.map((ruleGroup) => new RuleGroup(ruleGroup));
}

export async function getRules(): Promise<Rule[]> {
  const rawData = await Get<Rule[]>(`/api/controllers/rules/getAll`);
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return rawData.map((rule) => new Rule(rule));
}

export async function getRule(ruleId: string): Promise<Rule> {
  const rawData = await Get<Rule>(
    `/api/controllers/rules/get?ruleId=${ruleId}`
  );
  return new Rule(rawData);
}

export async function deleteRule(ruleId: string) {
  Delete(`/api/controllers/rules/delete?ruleId=${ruleId}`);
}

export async function deleteRuleGroup(ruleGroupId: string) {
  Delete(`/api/controllers/rules/deleteGroup?ruleGroupId=${ruleGroupId}`);
}

export async function saveRuleGroup(ruleGroup: RuleGroup): Promise<RuleGroup> {
  const rawRuleGroup = await Post<RuleGroup>(
    `/api/controllers/rules/saveGroup`,
    ruleGroup
  );
  return new RuleGroup(rawRuleGroup);
}

export async function cloneRuleGroup(ruleGroup: RuleGroup): Promise<RuleGroup> {
  const rawRuleGroup = await Post<RuleGroup>(
    `/api/controllers/rules/cloneGroup`,
    ruleGroup
  );
  return new RuleGroup(rawRuleGroup);
}

export async function saveRule(rule: Rule) {
  return Post(`/api/controllers/rules/save`, rule);
}

export async function saveRules(rules: Rule[]) {
  return Post(`/api/controllers/rules/saveRules`, rules);
}
