import { Get } from "./Http";
import { ITopology } from "../Components/Topology/Topology";

export function getTopology(profile: string): Promise<ITopology[]> {
  return Get<ITopology[]>(
    `/api/controllers/repository/topologyByName?name=${profile}`
  );
}

export function getBackplaneTopology(id: string): Promise<ITopology[]> {
  return Get<ITopology[]>(
    `/api/controllers/repository/getBackplaneTopology?backplaneId=${id}`
  );
}
