import { ISearchQuery } from "../Data/Search";
import { Get, Post, Delete } from "./Http";
import { ChassisProps } from "../Data/Schema/Components/ChassisProps";
import { BackplaneProps } from "../Data/Schema/Components/BackplaneProps";

import { ComponentType } from "../Data/Schema/Component";
import { ModuleProps } from "../Data/Schema/Components/Module";
import { MezzanineProps } from "../Data/Schema/Components/Mezzanine";
import { IComponentProps } from "../Data/Schema/ComponentProperties";
import { PowerSupplyProps } from "../Data/Schema/Components/PowerSupply";
import { HhitsResult } from "../Data/Schema/HhitsResult";
import { UserGroup } from "../Data/Schema/UserGroup";
import { User } from "../Data/Schema/User";
import { IdOption } from "../Components/Editors/SingleSelectValueById";

export interface IComponentSave {
  id?: string;
  component: string;
  tableName: string;
}

export async function getBuildComponents(
  componentType: ComponentType,
  searchQuery?: ISearchQuery
): Promise<IComponentProps[]> {
  const rawData = await Post<IComponentProps[]>(
    `/api/controllers/repository/getComponents?type=${componentType}`,
    searchQuery ? searchQuery : {}
  );
  if (rawData) {
    return rawData.map((component) =>
      mapComponent(component, componentType)
    ) as IComponentProps[];
  } else {
    return [];
  }
}

export async function getBuildComponent(
  componentId: string,
  componentType: ComponentType
): Promise<IComponentProps> {
  const rawData = await Get<IComponentProps>(
    `/api/controllers/repository/getComponent?componentId=${componentId}&type=${componentType}`
  );
  return mapComponent(rawData, componentType) as IComponentProps;
}

export async function deleteComponent(
  componentId: string,
  componentType: ComponentType
): Promise<HhitsResult> {
  const rawData = await Delete(
    `/api/controllers/repository/deleteComponent?componentId=${componentId}&type=${componentType}`
  );
  return rawData as HhitsResult;
}

export async function getProfileOptions(
  form: string,
  type: string
): Promise<string[]> {
  const rawData = await Get<string[]>(
    `/api/controllers/repository/getProfileOptions?form=${form}&type=${type}`
  );
  return rawData;
}

export async function getSlotProfileOptions(
  form: string,
  type: string
): Promise<string[]> {
  const rawData = await Get<string[]>(
    `/api/controllers/repository/getSlotProfileOptions?form=${form}&type=${type}`
  );
  return rawData;
}

export async function getDropdownOptions(name: string): Promise<string[]> {
  //this is not the way I wanted to do this but will have to do for now
  if (name === "slot-profiles") {
    const rawData = await Get<string[]>(
      `/api/controllers/repository/getAllSlotProfiles`
    );
    return rawData;
  }
  if (name === "module-profiles") {
    const rawData = await Get<string[]>(
      `/api/controllers/repository/getModuleProfiles`
    );
    return rawData;
  }
  const rawData = await Get<string[]>(
    `/api/controllers/repository/getDropdownOptions?name=${name}`
  );
  return rawData;
}

export async function getDropdownOptionsById(
  name: string
): Promise<IdOption[]> {
  //this is not the way I wanted to do this but will have to do for now
  if (name === "users") {
    const rawData = await Get<User[]>(`/api/controllers/users/getAll`);
    if(rawData === null || rawData === undefined){
      return []
    }
    return rawData
      .map(
        (user) =>
          ({
            id: user.id ? user.id : "",
            caption: user.firstName + " " + user.lastName,
          } as IdOption)
      )
      .filter((option) => option.id.length > 0);
  }
  if (name === "user-groups") {
    const rawData = await Get<UserGroup[]>(
      `/api/controllers/users/getAllGroups`
    );
    return rawData.map(
      (userGroup) => ({ id: userGroup.id, caption: userGroup.name } as IdOption)
    );
  }
  return [];
}

function mapComponent(component: IComponentProps, type: ComponentType) {
  if (type === ComponentType.Chassis) {
    return new ChassisProps(component as ChassisProps);
  } else if (type === ComponentType.Backplane) {
    return new BackplaneProps(component as BackplaneProps);
  } else if (type === ComponentType.Module) {
    return new ModuleProps(component as ModuleProps);
  } else if (type === ComponentType.Mezzanine) {
    return new MezzanineProps(component as MezzanineProps);
  } else if (type === ComponentType.PowerSupply) {
    return new PowerSupplyProps(component as PowerSupplyProps);
  } else {
    return null;
  }
}

export async function saveRepoComponent(
  component:
    | ModuleProps
    | BackplaneProps
    | ChassisProps
    | MezzanineProps
    | PowerSupplyProps
) {
  let rawData;
  if (component instanceof ModuleProps) {
    rawData = await Post(`/api/controllers/repository/saveModule`, component) as ModuleProps;
    return new ModuleProps(rawData);
  } else if (component instanceof BackplaneProps) {
    rawData = await Post(`/api/controllers/repository/saveBackplane`, component) as BackplaneProps;
    return new BackplaneProps(rawData);
  } else if (component instanceof ChassisProps) {
    rawData = await Post(`/api/controllers/repository/saveChassis`, component) as ChassisProps;
    return new ChassisProps(rawData);
  } else if (component instanceof MezzanineProps) {
    rawData = await Post(`/api/controllers/repository/saveMezzanine`, component) as MezzanineProps;
    return new MezzanineProps(rawData);
  } else {
    rawData = await Post(`/api/controllers/repository/savePowerSupply`, component) as PowerSupplyProps;
    return new PowerSupplyProps(rawData);
  }
}
