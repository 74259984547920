import * as React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { getDropdownOptionsById } from "../../API/Repository";

export interface IdOption {
  id: string;
  caption: string;
}
export interface ISingleSelectByIdValue {
  name?: string;
  value: string;
  databaseEntryName?: string;
}

@observer
export class ReadOnlyByIdValueEditor extends React.Component<
  ISingleSelectByIdValue
> {
  @observable private options: IdOption[] | undefined;

  public componentDidMount() {
    // TODO: Better way of doing this?
    if (this.props.databaseEntryName) {
      getDropdownOptionsById(this.props.databaseEntryName).then((values) => {
        this.options = values;
      });
    }
  }

  private getCaptionForId(): string {
    if (this.options) {
      var val = this.options.find((x) => x.id === this.props.value);
      return val ? val.caption : "";
    }
    return "";
  }

  public render() {
    return (
      <div className="ReadOnlyProperty">
        <div className="Name">{this.props.name}</div>
        <div className={`Value`}>{this.getCaptionForId()}</div>
      </div>
    );
  }
}
