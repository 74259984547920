import { ComponentType } from "../../../Data/Schema/Component";
import { observable } from "mobx";
import { SearchOperator } from "../../../Data/Search";

export enum AttributeType {
  Text,
  Numeric,
  Boolean,
  Date,
  List,
}
export interface ISearchAttribute {
  caption: string;
  value: string;
  type: AttributeType;
  unit: string;
  repeatedField?: boolean;
}

export interface ISearchType {
  options: SearchOption[];
  buildCompatible: boolean;
  componentType?: ComponentType;
  typeId?: string;
}

export abstract class SearchOption {
  @observable public active: boolean = false;
  @observable public numMatches: Number = -1;
  @observable public numMatchesOperator: SearchOperator = SearchOperator.Equals;
  @observable public numMatchesOperatorOptions: SearchOperator[] = [
    SearchOperator.Equals,
    SearchOperator.LessThan,
    SearchOperator.LessThanEquals,
    SearchOperator.GreaterThan,
    SearchOperator.GreaterThanEquals,
  ];

  constructor(public attribute: ISearchAttribute, public enabled: boolean) {}

  // TODO - The individual clone options will not clone the above observables. Look into doing so if required.
  clone: () => SearchOption = () => this;
}
