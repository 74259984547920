import { USER_MEASUREMENTSYSTEM_SESSION_ATTRIBUTE_NAME } from "../Pages/Login/AuthenticationService";

export interface ValueWithUnits {
  value: string;
  units: string;
}

export function convertValUnits(
  valueNum: number,
  units: string,
  tgtMeasSystem: string,
  parseFloat?: boolean
) {
  var value = valueNum.toString();
  let adjUnits = units.toLocaleLowerCase();
  let measSystem = tgtMeasSystem.toLocaleLowerCase();

  if ((adjUnits === "grams" || adjUnits === "g") && measSystem === "imperial") {
    valueNum = valueNum / 28.35; // ounces = grams / 28.35
    value = valueNum.toPrecision(3);
    adjUnits = "ounces";
  }

  if (adjUnits === "centimeters" && measSystem === "imperial") {
    valueNum = valueNum / 2.54; // inches = centimeters / 2.54
    value = valueNum.toPrecision(3);
    adjUnits = "centimeters";
  }

  if (adjUnits === "meters" && measSystem === "imperial") {
    valueNum = valueNum * 3.281; // feet = meters * 3.281
    value = valueNum.toPrecision(7);
    adjUnits = "feet";
  }

  if ((adjUnits === "ounces" || adjUnits === "oz") && measSystem === "metric") {
    valueNum = valueNum * 28.35; // grams = ounces * 28.35
    value = valueNum.toPrecision(3);
    adjUnits = "grams";
  }

  if (adjUnits === "inches" && measSystem === "metric") {
    valueNum = valueNum * 2.54; // centimeters = inches * 2.54
    value = valueNum.toPrecision(3);
    adjUnits = "centimeters";
  }

  if (adjUnits === "feet" && measSystem === "metric") {
    valueNum = valueNum / 3.281; // meters = feet / 3.281
    value = valueNum.toPrecision(7);
    adjUnits = "meters";
  }

  // Remove scientific notation.
  if (parseFloat) {
    value = "" + Number.parseFloat(value);
  }

  return { value, units: adjUnits };
}

export function convertValueAndUnits(
  valueNum: number,
  units: string,
  measurementSystem?: string
): ValueWithUnits {
  var displayMeasSys = sessionStorage.getItem(
    USER_MEASUREMENTSYSTEM_SESSION_ATTRIBUTE_NAME
  );
  if (measurementSystem) displayMeasSys = measurementSystem;


  return convertValUnits(
    valueNum,
    units,
    displayMeasSys ? displayMeasSys : "Metric"
  );
}

export function convertValueAndUnitsForStorage(
  valueNum: number,
  units: string
): ValueWithUnits {
  units = units.toLowerCase();
  if (units === "centimeters" || units === "meters") {
    return convertValUnits(valueNum, units, "Imperial");
  }

  if (units === "ounces" || units === "oz") {
    return convertValUnits(valueNum, units, "Metric");
  }

  var value = valueNum.toString();
  return { value, units };
}

export function abbreviateUnits(units: string) {
  units = units.toLowerCase();
  let abbreviatedUnits = "";
  switch (units) {
    case "ounces":
    case "ounce":
    case "oz":
      abbreviatedUnits = "oz";
      break;
    case "grams":
    case "gram":
    case "g":
      abbreviatedUnits = "g";
      break;
    case "centimeters":
    case "centimeter":
    case "cm":
      abbreviatedUnits = "cm";
      break;
    case "meters":
    case "meter":
    case "m":
      abbreviatedUnits = "m";
      break;
    case "feet":
    case "foot":
    case "ft":
      abbreviatedUnits = "ft";
      break;
    case "inches":
    case "inch":
    case "in":
      abbreviatedUnits = "in";
      break;
    default:
      abbreviatedUnits = units;
  }
  return abbreviatedUnits;
}