import * as React from "react";

import { observer } from "mobx-react";

import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

export interface IFlagValue {
  name: string;
  value: string; //TODO: change this to boolean | null type
  required?: boolean;
  notNullable?: boolean;
  disabled?: boolean;
  onChange?(value: string): void;
}

@observer
export class FlagValueEditor extends React.Component<IFlagValue> {
  public render() {
    return (
      <FormControl required={this.props.required} fullWidth={true}>
        <InputLabel htmlFor={this.props.name}>{this.props.name}</InputLabel>
        <Select
          id={this.props.name}
          onChange={this.onChange}
          fullWidth={true}
          value={this.props.value}
          input={
            <Input
              fullWidth={true}
              name={this.props.name}
              id={this.props.name}
            />
          }
          disabled={this.props.disabled}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {!this.props.notNullable && <MenuItem value="" />}
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>
    );
  }

  private onChange = (event: any) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };
}
