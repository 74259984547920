import * as React from "react";

import { observer } from "mobx-react";

import { DateValueEditor } from "../../Components/Editors/DateValue";
import { FlagValueEditor } from "../../Components/Editors/FlagValue";
import { NumberValueEditor } from "../../Components/Editors/NumberValue";
import { SingleSelectValueEditor } from "../../Components/Editors/SingleSelectValue";
import { SingleSelectDBValueEditor } from "../../Components/Editors/SingleSelectValueDB";
import { TextValueEditor } from "../../Components/Editors/TextValue";

import { PropertyType } from "../../Data/Repository/PropertySchema";
import {
  PropertyDescriptor,
  IComponentProps,
  ComponentPropertyProps,
  PropGroup,
  getComponentValue,
  getComponentCount,
  DifferenceSet,
  BackplaneConnection,
  getComponentValueRaw,
  ModuleSlot,
} from "../../Data/Schema/ComponentProperties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { HhitsButton } from "../../Components/Button";
import { User } from "../../Data/Schema/User";
import { UserGroup } from "../../Data/Schema/UserGroup";
import { SingleSelectByIdValueEditor } from "../../Components/Editors/SingleSelectValueById";
import { observable, computed } from "mobx";
import { ReadOnlyByIdValueEditor } from "../../Components/Editors/ReadOnlyValueById";
import { IconButton } from "@material-ui/core";
import { getDocumentUrl, uploadFile } from "../../API/FileRetrieve";
import { ConnectionTable } from "../../Components/Editors/ConnectionTable";
import { Topology } from "../../Components/Topology/Topology";
import {
  getDropdownOptions,
  getProfileOptions,
  getSlotProfileOptions,
} from "../../API/Repository";
import { getTopology } from "../../API/Topology";
import { ModuleProps } from "../../Data/Schema/Components/Module";
import { MultiSelectValueEditor } from "../../Components/Editors/MultiSelectValue";
import {
  convertValueAndUnits,
  ValueWithUnits,
  convertValueAndUnitsForStorage,
} from "../../Utilities/Units";
import { isLink, getLinkCaption, getLinkURL } from "../../Utilities/String";

@observer
export class ComponentPropertyEditor extends React.Component<{
  property:
  | PropertyDescriptor<IComponentProps>
  | PropertyDescriptor<ComponentPropertyProps>
  | PropertyDescriptor<User>
  | PropertyDescriptor<UserGroup>;
  component: IComponentProps | ComponentPropertyProps | User | UserGroup;
  columnIdx?: number;
  readOnly?: boolean;
  disabled?: boolean;
  first?: boolean;
  indicateDiff?: boolean;
  diffSet?: DifferenceSet;
  onChange?(): void;
}> {
  myRef = React.createRef<HTMLInputElement>();

  @observable relatedOptionsData: string[] = [];
  @observable profileOptionsData: string[] = [];
  @observable slotProfileOptionsData: string[] = [];

  public componentDidMount() {
    this.updateRelatedOptions();
    this.updateProfileOptions();
    this.updateSlotProfileOptions();

    if (this.props.property.type === PropertyType.ModuleProfile) {
      let value = this.getComponentValueArray(this.props.property.name)
      this.initializeProfileValue(value);
    }
  }

  public render(): JSX.Element {
    return (
      <div
        className={`PropertyEditor ${this.props.readOnly ? "ReadOnly" : ""}`}
      >
        {this.props.property.type === PropertyType.Date
          ? this.renderDateEditor()
          : this.props.property.type === PropertyType.Number
            ? this.renderNumberEditor()
            : this.props.property.type === PropertyType.Flag
              ? this.renderFlagEditor()
              : this.props.property.type === PropertyType.SingleSelect
                ? this.renderSingleSelectEditor()
                : this.props.property.type === PropertyType.SingleSelectDB
                  ? this.renderSingleSelectDBEditor()
                  : this.props.property.type === PropertyType.RelatedSingleSelectDB
                    ? this.renderRelatedSingleSelectDBEditor()
                    : this.props.property.type === PropertyType.DependentSingleSelectDB
                      ? this.renderDependentSingleSelectDBEditor()
                      : this.props.property.type === PropertyType.SingleSelectById
                        ? this.renderSingleSelectByIdEditor()
                        : this.props.property.type === PropertyType.List
                          ? this.renderListEditor()
                          : this.props.property.type === PropertyType.CompactList
                            ? this.renderCompactListEditor()
                            : this.props.property.type === PropertyType.IdList
                              ? this.renderIdListEditor()
                              : this.props.property.type === PropertyType.File
                                ? this.renderFileEditor()
                                : this.props.property.type === PropertyType.ConnectionList
                                  ? this.renderConnectionListEditor()
                                  : this.props.property.type === PropertyType.Count
                                    ? this.renderCount()
                                    : this.props.property.type === PropertyType.SlotProfile
                                      ? this.renderSlotProfileEditor()
                                      : this.props.property.type === PropertyType.ModuleProfile
                                        ? this.renderModuleProfileEditor()
                                        : this.renderTextEditor()}
      </div>
    );
  }

  private renderTextEditor = () => {
    return !this.props.readOnly ? (
      <TextValueEditor
        name={this.props.property.displayName}
        value={this.getComponentValue(this.props.property.name)}
        password={this.props.property.type === PropertyType.Password}
        onChange={this.updateValue}
        required={this.props.property.required}
        autoFocus={this.props.first}
        disabled={this.props.disabled}
      />
    ) : (
        <ReadOnlyProperty
          name={this.props.property.displayName}
          value={this.getComponentValue(
            this.props.property.name,
            this.props.property.subProperty
          )}
          indicateDiff={this.props.indicateDiff}
          columnIdx={this.props.columnIdx}
        />
      );
  };

  private renderSlotProfileEditor = () => {
    var profileTypeOptions = [
      "Payload",
      "Switch",
      "Time",
      "Peripheral",
      "Storage",
    ].map((option) => ({ value: option, caption: option }));
    var profileFormOptions = ["3U", "6U"].map((option) => ({
      value: option,
      caption: option,
    }));

    return !this.props.readOnly ? (
      <div className="ModuleProfileEditor">
        <SingleSelectValueEditor
          name={"Type"}
          value={this.getComponentValue("type")}
          onChange={this.updateSlotProfileTypeValue}
          options={profileTypeOptions}
          required={true}
        />
        <SingleSelectValueEditor
          name={"Form"}
          value={this.getComponentValue("form")}
          onChange={this.updateSlotProfileFormValue}
          options={profileFormOptions}
          required={true}
        />
        <SingleSelectValueEditor
          name={this.props.property.displayName}
          value={this.getComponentValue(this.props.property.name)}
          onChange={this.updateSlotProfileValue}
          options={this.slotProfileOptions}
          required={this.props.property.required}
        />
        {/* {(this.props.component as ModuleProps).topology.length > 0 && <div className="topologyEditor">
                    {(this.props.component as ModuleProps).topology.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                    .map((t) => {
                        return <div className="topoLabelDiv"><Topology source={t} tableEditable={true}  /><span>{t.name}</span></div>
                    })}
                </div>}                  */}
      </div>
    ) : (
        <div className="ReadOnlyModuleProfileEditor">
          <ReadOnlyProperty
            name={"Type"}
            value={this.getComponentValue("type")}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          <ReadOnlyProperty
            name={"Form"}
            value={this.getComponentValue("form")}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          <ReadOnlyProperty
            name={this.props.property.displayName}
            value={this.getComponentValue(
              this.props.property.name,
              this.props.property.subProperty
            )}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          {/* {(this.props.component as ModuleProps).topology.length > 0 && <div className="topologyEditor">
                    {(this.props.component as ModuleProps).topology.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                    .map((t) => {
                        return <div className="topoLabelDiv"><Topology source={t} tableEditable={false}  /><span>{t.name}</span></div>
                    })}
                </div>}   */}
        </div>
      );
  };

  private renderModuleProfileEditor = () => {
    var profileTypeOptions = [
      "Payload",
      "Switch",
      "Time",
      "Peripheral",
      "Storage",
    ].map((option) => ({ value: option, caption: option }));
    var profileFormOptions = ["3U", "6U"].map((option) => ({
      value: option,
      caption: option,
    }));

    return !this.props.readOnly ? (
      <div className="ModuleProfileEditor">
        <SingleSelectValueEditor
          name={"Type"}
          value={this.getComponentValue("type")}
          onChange={this.updateProfileTypeValue}
          options={profileTypeOptions}
          required={true}
        />
        <SingleSelectValueEditor
          name={"Form"}
          value={this.getComponentValue("form")}
          onChange={this.updateProfileFormValue}
          options={profileFormOptions}
          required={true}
        />
        <MultiSelectValueEditor
          name={this.props.property.displayName}
          value={this.getComponentValueArray(this.props.property.name)}
          onChange={this.updateProfileValue}
          options={this.profileOptions}
          required={this.props.property.required}
        />
        {(this.props.component as ModuleProps).topology.length > 0 && (
          <div className="topologyEditor">
            {(this.props.component as ModuleProps).topology
              .slice()
              .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
              .map((t, index) => {
                return (
                  <div key={index} className="topoLabelDiv">
                    <Topology source={t} tableEditable={true} onChange={this.props.onChange} />
                  </div>
                );
              })}
          </div>
        )}
      </div>
    ) : (
        <div className="ReadOnlyModuleProfileEditor">
          <ReadOnlyProperty
            name={"Type"}
            value={this.getComponentValue("type")}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          <ReadOnlyProperty
            name={"Form"}
            value={this.getComponentValue("form")}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          <ReadOnlyProperty
            name={this.props.property.displayName}
            value={this.getComponentValue(
              this.props.property.name,
              this.props.property.subProperty
            )}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          {(this.props.component as ModuleProps).topology.length > 0 && (
            <div className="topologyEditor">
              {(this.props.component as ModuleProps).topology
                .slice()
                .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                .map((t, index) => {
                  return (
                    <div key={index} className="topoLabelDiv">
                      <Topology source={t} tableEditable={false} onChange={this.props.onChange} />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      );
  };

  private updateProfileTypeValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    (this.props.component as any)["type"] = value;
    (this.props.component as ModuleProps).moduleProfile = [];
    (this.props.component as ModuleProps).topology = [];
    this.updateProfileOptions();
  };
  private updateProfileFormValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    (this.props.component as any)["form"] = value;
    (this.props.component as ModuleProps).moduleProfile = [];
    (this.props.component as ModuleProps).topology = [];
    this.updateProfileOptions();
  };

  private initializeProfileValue = (value: any) => {
    if(value && value instanceof Array && value.length > 0){
      (this.props.component as any)[this.props.property.name] = value;
      (this.props.component as ModuleProps).topology = [];
      getTopology(value.toString()).then((retVal) => {
        (this.props.component as ModuleProps).topology = retVal;
      });
    }else{
      (this.props.component as any)[this.props.property.name] = [];
      (this.props.component as ModuleProps).topology = [];
    }
  }

  private updateProfileValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    this.initializeProfileValue(value);
  };

  private updateSlotProfileTypeValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    (this.props.component as any)["type"] = value;
    (this.props.component as ModuleSlot).profile = "";
    this.updateSlotProfileOptions();
  };
  private updateSlotProfileFormValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    (this.props.component as any)["form"] = value;
    (this.props.component as ModuleSlot).profile = "";
    this.updateSlotProfileOptions();
  };

  private updateSlotProfileValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    (this.props.component as any)[this.props.property.name] = value;
  };

  private handleFileUpload = (files: FileList | null) => {
    this.props.onChange && this.props.onChange();
    if (files) {
      let file = files[0];
      uploadFile(file).then((response) => {
        this.updateValue(response);
      });
    }
  };

  private renderFileEditor = () => {
    return this.props.readOnly ? (
      <span
        className="FileDownload"
        onClick={() =>
          saveAs(
            getDocumentUrl(
              this.getComponentValue(
                this.props.property.name,
                this.props.property.subProperty
              )
            )
          )
        }
      >
        <FontAwesomeIcon icon="download" />
      </span>
    ) : this.getComponentValue(
      this.props.property.name,
      this.props.property.subProperty
    ).length > 0 ? (
          <IconButton
            onClick={() =>
              saveAs(
                getDocumentUrl(
                  this.getComponentValue(
                    this.props.property.name,
                    this.props.property.subProperty
                  )
                )
              )
            }
          >
            <FontAwesomeIcon icon="download" />
          </IconButton>
        ) : (
          <>
            <IconButton
              onClick={() => {
                if (this.myRef.current) this.myRef.current.click();
              }}
            >
              <FontAwesomeIcon icon="upload" />
            </IconButton>
            <input
              type="file"
              style={{ visibility: "hidden" }}
              ref={this.myRef}
              onChange={(e) => {
                this.handleFileUpload(e.target.files);
              }}
            />
          </>
        );
  };

  private renderCount = () => {
    return (
      <ReadOnlyProperty
        name={this.props.property.displayName}
        value={this.getComponentCount(this.props.property.name)}
        indicateDiff={this.props.indicateDiff}
        columnIdx={this.props.columnIdx}
      />
    );
  };

  private renderDateEditor = () => {
    const value = this.getComponentValue(this.props.property.name);
    const parsedDate = moment(value);

    return !this.props.readOnly ? (
      <DateValueEditor
        name={this.props.property.displayName}
        value={parsedDate.isValid() ? parsedDate.toDate() : null}
        onChange={(value) => this.updateValue(value)}
        required={this.props.property.required}
      />
    ) : (
        <ReadOnlyProperty
          name={this.props.property.displayName}
          value={
            parsedDate.isValid() ? parsedDate.toDate().toLocaleDateString() : ""
          }
          indicateDiff={this.props.indicateDiff}
          columnIdx={this.props.columnIdx}
        />
      );
  };

  private setConvertedNumberValue = (value: string | null, units: string) => {
    var results: ValueWithUnits = convertValueAndUnitsForStorage(
      Number(value),
      units
    );

    this.updateValue(results.value);
  };

  private renderNumberEditor = () => {
    var value = this.getComponentValue(
      this.props.property.name,
      this.props.property.subProperty
    );
    var units = this.props.property.unit;

    var results: ValueWithUnits = convertValueAndUnits(value, units);

    return !this.props.readOnly ? (
      <NumberValueEditor
        name={this.props.property.displayName}
        value={results.value}
        unit={results.units}
        onChange={(value) => this.setConvertedNumberValue(value, results.units)}
        required={this.props.property.required}
        preventDefaultScroll={false}
      />
    ) : (
        <ReadOnlyProperty
          name={this.props.property.displayName}
          value={results.value}
          unit={results.units}
          indicateDiff={this.props.indicateDiff}
          columnIdx={this.props.columnIdx}
        />
      );
  };

  private renderFlagEditor = () => {
    return !this.props.readOnly ? (
      <FlagValueEditor
        name={this.props.property.displayName}
        value={this.getComponentValue(this.props.property.name)}
        onChange={(value) => this.updateValue(this.parseFlagValue(value))}
        required={this.props.property.required}
        disabled={this.props.disabled}
      />
    ) : (
        <ReadOnlyProperty
          name={this.props.property.displayName}
          value={
            this.getComponentValue(this.props.property.name) === "true"
              ? "Yes"
              : this.getComponentValue(this.props.property.name) === "false"
                ? "No"
                : ""
          }
          indicateDiff={this.props.indicateDiff}
          columnIdx={this.props.columnIdx}
        />
      );
  };

  private renderSingleSelectEditor = () => {
    return !this.props.readOnly ? (
      <SingleSelectValueEditor
        name={this.props.property.displayName}
        value={this.getComponentValue(this.props.property.name)}
        onChange={this.updateValue}
        options={this.props.property.options.map((option) => {
          return { caption: option, value: option };
        })}
        required={this.props.property.required}
        disabled={this.props.disabled}
      />
    ) : (
        <ReadOnlyProperty
          name={this.props.property.displayName}
          value={this.getComponentValue(
            this.props.property.name,
            this.props.property.subProperty
          )}
          indicateDiff={this.props.indicateDiff}
          columnIdx={this.props.columnIdx}
        />
      );
  };

  private renderSingleSelectDBEditor = () => {
    return !this.props.readOnly ? (
      <SingleSelectDBValueEditor
        name={this.props.property.displayName}
        value={this.getComponentValue(this.props.property.name)}
        onChange={this.updateValue}
        databaseEntryName={this.props.property.databaseEntryName}
        required={this.props.property.required}
      />
    ) : (
        <ReadOnlyProperty
          name={this.props.property.displayName}
          value={this.getComponentValue(
            this.props.property.name,
            this.props.property.subProperty
          )}
          indicateDiff={this.props.indicateDiff}
          columnIdx={this.props.columnIdx}
        />
      );
  };

  private renderRelatedSingleSelectDBEditor = () => {
    return !this.props.readOnly ? (
      <div className="RelatedSingleSelects">
        <SingleSelectDBValueEditor
          name={this.props.property.displayName}
          value={this.getComponentValue(this.props.property.name)}
          onChange={this.updateValueAndRelatedOptions}
          databaseEntryName={this.props.property.databaseEntryName}
          required={this.props.property.required}
        />
        {this.props.property.dependentProperty ? (
          <SingleSelectValueEditor
            name={this.props.property.dependentProperty.displayName}
            onChange={this.updateDependentValue}
            value={this.getComponentValue(
              this.props.property.dependentProperty.name
            )}
            options={this.relatedOptions}
            required={this.props.property.dependentProperty.required}
          />
        ) : null}
      </div>
    ) : (
        <div className="ReadOnlyRelatedSingleSelects">
          <ReadOnlyProperty
            name={this.props.property.displayName}
            value={this.getComponentValue(
              this.props.property.name,
              this.props.property.subProperty
            )}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          {this.props.property.dependentProperty ? (
            <ReadOnlyProperty
              name={this.props.property.dependentProperty.displayName}
              value={this.getComponentValue(
                this.props.property.dependentProperty.name,
                this.props.property.dependentProperty.subProperty
              )}
              indicateDiff={this.props.indicateDiff}
              columnIdx={this.props.columnIdx}
            />
          ) : null}
        </div>
      );
  };

  /**
   * The first SingleSelect value must have corresponding options
   * in the db in order for the second SingleSelect component to render.
   */
  private renderDependentSingleSelectDBEditor = () => {
    return !this.props.readOnly ? (
      <div className="DependentSingleSelects">
        <SingleSelectDBValueEditor
          name={this.props.property.displayName}
          value={this.getComponentValue(this.props.property.name)}
          onChange={this.updateDependentSingleSelect}
          databaseEntryName={this.props.property.databaseEntryName}
          required={this.props.property.required}
        />
        {this.props.property.dependentProperty &&
          this.relatedOptions.length !== 0 ? (
            <SingleSelectValueEditor
              name={this.props.property.dependentProperty.displayName}
              onChange={this.updateDependentValue}
              value={this.getComponentValue(
                this.props.property.dependentProperty.name
              )}
              options={this.relatedOptions}
              required={this.props.property.dependentProperty.required}
            />
          ) : null}
      </div>
    ) : (
        <div className="ReadOnlyDependentSingleSelects">
          <ReadOnlyProperty
            name={this.props.property.displayName}
            value={this.getComponentValue(
              this.props.property.name,
              this.props.property.subProperty
            )}
            indicateDiff={this.props.indicateDiff}
            columnIdx={this.props.columnIdx}
          />
          {this.props.property.dependentProperty &&
            this.getComponentValue(
              this.props.property.dependentProperty.name,
              this.props.property.dependentProperty.subProperty
            ) ? (
              <ReadOnlyProperty
                name={this.props.property.dependentProperty.displayName}
                value={this.getComponentValue(
                  this.props.property.dependentProperty.name,
                  this.props.property.dependentProperty.subProperty
                )}
                indicateDiff={this.props.indicateDiff}
                columnIdx={this.props.columnIdx}
              />
            ) : null}
        </div>
      );
  };

  private renderSingleSelectByIdEditor = () => {
    return !this.props.readOnly ? (
      <SingleSelectByIdValueEditor
        name={this.props.property.displayName}
        value={this.getComponentValue(this.props.property.name)}
        listItemIndex={-1}
        onChange={this.updateValue}
        databaseEntryName={this.props.property.databaseEntryName}
        required={this.props.property.required}
        disabled={this.props.disabled}
      />
    ) : (
        <ReadOnlyByIdValueEditor
          name={this.props.property.displayName}
          value={this.getComponentValue(this.props.property.name)}
          databaseEntryName={this.props.property.databaseEntryName}
        />
      );
  };

  private renderListEditor = () => {
    const childList = (this.props.component as any)[
      this.props.property.name
    ] as ComponentPropertyProps[];

    return (
      <div className="ListEditor">
        {
          // TODO: Fix this ew
          childList.map((listItem, index) => {
            return (
              <div key={index} className="ListItem">
                <div className="ItemProps">
                  {(listItem.getPropertySchema() as PropGroup<
                    ComponentPropertyProps
                  >[]).map((propGroup) =>
                    propGroup.properties.map((property, index) => (
                      <ComponentPropertyEditor
                        key={index}
                        onChange={this.props.onChange}
                        property={property}
                        component={listItem}
                        readOnly={this.props.readOnly}
                        columnIdx={this.props.columnIdx}
                        indicateDiff={
                          this.props.diffSet && this.props.columnIdx === 0
                            ? this.props.diffSet.leftFieldHasDiff(
                              this.props.property.displayName,
                              index,
                              property.name
                            )
                            : this.props.diffSet && this.props.columnIdx === 1
                              ? this.props.diffSet.rightFieldHasDiff(
                                this.props.property.displayName,
                                index,
                                property.name
                              )
                              : false
                        }
                      />
                    ))
                  )}
                  {index !== childList.length - 1 && <div>&nbsp;</div>}
                </div>
                {!this.props.readOnly && (
                  <HhitsButton
                    icon
                    className="Remove"
                    onClick={() => {
                      this.props.onChange && this.props.onChange();
                      this.props.component.removeFromList(
                        this.props.property.name,
                        index
                      );
                    }
                    }
                  >
                    <FontAwesomeIcon icon="times" />
                  </HhitsButton>
                )}
              </div>
            );
          })
        }
        {!this.props.readOnly && (
          <HhitsButton
            className="AddNewItem"
            onClick={() => {
              this.props.onChange && this.props.onChange();
              this.props.component.addToList(this.props.property.name);
            }
            }
          >
            <FontAwesomeIcon icon="plus" />
            Add
          </HhitsButton>
        )}
      </div>
    );
  };

  private renderCompactListEditor = () => {
    const childList = (this.props.component as any)[
      this.props.property.name
    ] as ComponentPropertyProps[];

    return (
      <div className="CompactListEditor">
        {
          // TODO: Fix this ew
          childList.map((listItem, index) => {
            return (
              <div key={index} className="ListItem">
                <div className="ItemProps">
                  {(listItem.getPropertySchema() as PropGroup<
                    ComponentPropertyProps
                  >[]).map((propGroup) =>
                    propGroup.properties.map((property, index) => (
                      <ComponentPropertyEditor
                        key={index}
                        property={property}
                        component={listItem}
                        readOnly={this.props.readOnly}
                        columnIdx={this.props.columnIdx}
                        disabled={this.props.disabled}
                        onChange={this.props.onChange}
                        indicateDiff={
                          this.props.diffSet && this.props.columnIdx === 0
                            ? this.props.diffSet.leftFieldHasDiff(
                              this.props.property.displayName,
                              index,
                              property.name
                            )
                            : this.props.diffSet && this.props.columnIdx === 1
                              ? this.props.diffSet.rightFieldHasDiff(
                                this.props.property.displayName,
                                index,
                                property.name
                              )
                              : false
                        }
                      />
                    ))
                  )}
                  {index !== childList.length - 1 && <div>&nbsp;</div>}
                </div>
                {!this.props.readOnly && !this.props.disabled && (
                  <HhitsButton
                    icon
                    className="Remove"
                    onClick={() => {
                      this.props.onChange && this.props.onChange();
                      this.props.component.removeFromList(
                        this.props.property.name,
                        index
                      );
                    }
                    }
                  >
                    <FontAwesomeIcon icon="times" />
                  </HhitsButton>
                )}
              </div>
            );
          })
        }
        {!this.props.readOnly && !this.props.disabled && (
          <HhitsButton
            className="AddNewItem"
            onClick={() => {
              this.props.onChange && this.props.onChange();
              this.props.component.addToList(this.props.property.name);
            }
            }
          >
            <FontAwesomeIcon icon="plus" />
            {"Add " + this.props.property.displayName}
          </HhitsButton>
        )}
      </div>
    );
  };

  private renderIdListEditor = () => {
    var idList = (this.props.component as any)[
      this.props.property.name
    ] as string[];

    return (
      <div className="IdListEditor">
        {
          // TODO: Fix this ew
          idList.map((listItem, idx) => {
            return (
              <div key={idx} className="ListItem">
                <div className="ItemProps">
                  <div
                    className={`PropertyEditor ${this.props.readOnly ? "ReadOnly" : ""
                      }`}
                  >
                    {
                      <SingleSelectByIdValueEditor
                        name={""}
                        value={listItem}
                        listItemIndex={idx}
                        onChange={this.updateIdList}
                        databaseEntryName={
                          this.props.property.databaseEntryName
                        }
                        required={this.props.property.required}
                        disabled={this.props.disabled}
                      />
                    }
                  </div>
                </div>
                {!this.props.readOnly && !this.props.disabled && (
                  <HhitsButton
                    icon
                    className="Remove"
                    onClick={() => {
                      this.props.onChange && this.props.onChange();
                      this.props.component.removeFromList(
                        this.props.property.name,
                        idx
                      );
                    }
                    }
                  >
                    <FontAwesomeIcon icon="times" />
                  </HhitsButton>
                )}
              </div>
            );
          })
        }
        {!this.props.readOnly && !this.props.disabled && (
          <HhitsButton
            className="AddNewItem"
            onClick={() => {
              this.props.onChange && this.props.onChange();
              this.props.component.addToList(this.props.property.name);
            }
            }
          >
            <FontAwesomeIcon icon="plus" />
            Add
          </HhitsButton>
        )}
      </div>
    );
  };


  private renderConnectionListEditor = () => {
    var connections = getComponentValueRaw(
      this.props.property.name,
      this.props.component
    ) as BackplaneConnection[];
    return (
      <ConnectionTable
        title="Connections"
        readonly={this.props.readOnly}
        rows={connections}
        onChange={this.updateCompatibilityTable}
      />
    );
  };

  private updateCompatibilityTable = (rows: BackplaneConnection[]) => {
    this.props.onChange && this.props.onChange();
    (this.props.component as any)[this.props.property.name] = rows;
  };

  private parseFlagValue(value: string) {
    if (value !== "") {
      return value === "true";
    } else {
      return null;
    }
  }

  private updateValueAndRelatedOptions = (value: any) => {
    this.props.onChange && this.props.onChange();
    this.updateValue(value);
    if (this.props.property.dependentProperty) {
      this.updateRelatedOptions();
    }
  };

  private updateDependentSingleSelect = (value: any) => {
    this.props.onChange && this.props.onChange();
    this.updateValueAndRelatedOptions(value);
    if (
      this.props.property.dependentProperty &&
      this.relatedOptions.length !== 0
    ) {
      this.updateDependentValue("");
    }
  };

  private updateDependentValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    if (this.props.property.dependentProperty) {
      (this.props.component as any)[
        this.props.property.dependentProperty.name
      ] = value;
    }
  };

  private updateValue = (value: any) => {
    this.props.onChange && this.props.onChange();
    (this.props.component as any)[this.props.property.name] = value;
  };

  private updateIdList = (value: any, index: number) => {
    this.props.onChange && this.props.onChange();
    ((this.props.component as any)[this.props.property.name] as any)[
      index
    ] = value;
  };

  private getComponentValue = (
    propertyName: string,
    secondaryProperty?: string
  ) => {
    return secondaryProperty
      ? getComponentValue(propertyName, this.props.component) +
      " " +
      getComponentValue(secondaryProperty, this.props.component)
      : getComponentValue(propertyName, this.props.component);
  };

  private getComponentValueArray = (propertyName: string) => {
    return getComponentValueRaw(propertyName, this.props.component);
  };

  private getComponentCount = (propertyName: string) => {
    return getComponentCount(propertyName, this.props.component);
  };

  @computed get relatedOptions() {
    return this.relatedOptionsData.map((option) => ({
      value: option,
      caption: option,
    }));
  }

  private updateRelatedOptions() {
    if (
      this.props.property.dependentProperty &&
      this.props.property.dependentProperty.databaseEntryName
    ) {
      getDropdownOptions(
        this.props.property.dependentProperty.databaseEntryName +
        "-" +
        this.getComponentValue(this.props.property.name)
      ).then((options) => {
        this.relatedOptionsData = options;
      });
    }
  }

  @computed get profileOptions() {
    return this.profileOptionsData.map((option) => ({
      value: option,
      caption: option,
    }));
  }

  private updateProfileOptions() {
    if (this.props.component instanceof ModuleProps) {
      var propName = this.props.property.name.toString();
      if (propName === "moduleProfile") {
        var module = this.props.component as ModuleProps;
        getProfileOptions(module.form, module.type).then((options) => {
          this.profileOptionsData = options;
        });
      }
    }
  }

  @computed get slotProfileOptions() {
    return this.slotProfileOptionsData.map((option) => ({
      value: option,
      caption: option,
    }));
  }

  private updateSlotProfileOptions() {
    if (this.props.component instanceof ModuleSlot) {
      var propName = this.props.property.name.toString();
      if (propName === "profile") {
        var slot = this.props.component as ModuleSlot;
        getSlotProfileOptions(slot.form, slot.type).then((options) => {
          this.slotProfileOptionsData = options;
        });
      }
    }
  }
}

interface PropertyDisplay {
  name: string;
  value: string;
  unit?: string;
  indicateDiff?: boolean;
  columnIdx?: number;
}

function ReadOnlyProperty(property: PropertyDisplay) {
  return (
    <div className="ReadOnlyProperty">
      <div className="Name">{property.name}</div>
      <div className={`Value${property.indicateDiff ? " hasDiff" : ""}`}>
        {isLink(property.value) ? renderLink(property.value) : property.value}
        {property.unit && property.value !== "" && ` ${property.unit}`}
      </div>
    </div>
  );
}

function renderLink(value: string) {
  const caption = getLinkCaption(value);
  const url = getLinkURL(value);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {caption}
    </a>
  );
}
