import { observable } from "mobx";
import { ErrorMessage, ErrorLevel } from "./Errors";

export enum ComponentType {
  Chassis = "Chassis",
  Backplane = "Backplane",
  Module = "Module",
  Mezzanine = "Mezzanine",
  PowerSupply = "PowerSupply",
  Build = "Build",
}

export function ComponentTypeDisplayString(type: ComponentType) {
  return type.toString().replace(/([A-Z])/g, " $1");
}

export class Component {
  public index: number = -1;
  @observable public type: ComponentType = ComponentType.Chassis;
  @observable public subType: string = "";
  @observable public children: Component[] = [];
  @observable public allocatedWeight: number = 0;
  public location?: string;
  public propsId?: string;
  @observable public chassisManagerPrimary?: number = -1;
  @observable public chassisManagerSecondary?: number[] = [];

  constructor(source?: Component) {
    if (source) {
      Object.assign(this, source);
      this.children = source.children.map((child) => new Component(child));
    }
  }

  public getErrorLevel(errors: ErrorMessage[]): ErrorLevel {
    // TODO: Factor in child errors
    const errorsMatched = errors.filter((error) => error.index === this.index);
    let errorLevel = ErrorLevel.OK;

    if (errorsMatched.length > 0) {
      errorLevel = ErrorMessage.SortByErrorLevel(errorsMatched)[0].errorLevel;
    }

    return errorLevel;
  }

  public getErrorMessages(errors: ErrorMessage[]): ErrorMessage[] {
    return ErrorMessage.SortByErrorLevel(errors).filter(
      (error) => error.index === this.index
    );
  }

  public findComponent(index: number): Component | undefined {
    let componentReturn;
    if (this.index === index) {
      return this;
    } else {
      this.children.forEach((child) => {
        const found = child.findComponent(index);
        if (found) {
          componentReturn = found;
        }
      });
    }
    return componentReturn;
  }

  public equals(that: Component): boolean {
    // indexes are excluded from equality since they don't mean anything outside a check
    return (
      this.type === that.type &&
      this.subType === that.subType &&
      this.location === that.location &&
      this.propsId === that.propsId &&
      this.allocatedWeight === that.allocatedWeight &&
      this.children.length === that.children.length &&
      this.children.every((child, index) => child.equals(that.children[index]))
    );
  }
}
