import { createContext } from "react";

import { ComponentType } from "../Schema/Component";
import { IComponentProps } from "../Schema/ComponentProperties";
import { ChassisProps } from "../Schema/Components/ChassisProps";
import { BackplaneProps } from "../Schema/Components/BackplaneProps";
import { hydrateMap } from "../Hydration";
import { ModuleProps } from "../Schema/Components/Module";
import { MezzanineProps } from "../Schema/Components/Mezzanine";
import { PowerSupplyProps } from "../Schema/Components/PowerSupply";

export class PropsCache {
  chassises: Record<string, ChassisProps> = {};
  backplanes: Record<string, BackplaneProps> = {};
  modules: Record<string, ModuleProps> = {};
  mezzanines: Record<string, MezzanineProps> = {};
  powerSupplies: Record<string, PowerSupplyProps> = {};

  /**
   * Copy constructor for hydrating deserialized instances
   */
  constructor(source?: PropsCache) {
    if (source) {
      this.chassises = hydrateMap<ChassisProps>(source.chassises, ChassisProps);
      this.backplanes = hydrateMap<BackplaneProps>(
        source.backplanes,
        BackplaneProps
      );
      this.modules = hydrateMap<ModuleProps>(source.modules, ModuleProps);
      this.mezzanines = hydrateMap<MezzanineProps>(
        source.mezzanines,
        MezzanineProps
      );
      this.powerSupplies = hydrateMap<PowerSupplyProps>(
        source.powerSupplies,
        PowerSupplyProps
      );
    }
  }

  public lookup(type: ComponentType.Chassis, id: string): ChassisProps;
  public lookup(type: ComponentType.Backplane, id: string): BackplaneProps;
  public lookup(type: ComponentType.Module, id: string): ModuleProps;
  public lookup(type: ComponentType.Mezzanine, id: string): MezzanineProps;
  public lookup(type: ComponentType.PowerSupply, id: string): PowerSupplyProps;
  public lookup(type: ComponentType, id: string): IComponentProps;
  public lookup(type: ComponentType, id: string): IComponentProps {
    switch (type) {
      case ComponentType.Chassis:
        return this.chassises[id];
      case ComponentType.Backplane:
        return this.backplanes[id];
      case ComponentType.Module:
        return this.modules[id];
      case ComponentType.Mezzanine:
        return this.mezzanines[id];
      case ComponentType.PowerSupply:
        return this.powerSupplies[id];
      default:
        throw new Error("Unknown component properties were referenced");
    }
  }

  public enroll(props: IComponentProps) {
    const id = props.getId();
    if (!id)
      throw new Error("Tried to enroll component properties without an ID");

    if (props instanceof ChassisProps) {
      this.chassises[id] = props;
    } else if (props instanceof BackplaneProps) {
      this.backplanes[id] = props;
    } else if (props instanceof ModuleProps) {
      this.modules[id] = props;
    } else if (props instanceof MezzanineProps) {
      this.mezzanines[id] = props;
    } else if (props instanceof PowerSupplyProps) {
      this.powerSupplies[id] = props;
    } else {
      throw new Error("Unknown component property type was enrolled");
    }
  }
}

export const PropsCacheContext = createContext(new PropsCache());
