import { observer } from "mobx-react";
import * as React from "react";

import { Modal } from "../../Components/Modal/Modal";
import { TextValueEditor } from "../../Components/Editors/TextValue";
import { HhitsButton } from "../../Components/Button";
import { observable } from "mobx";
import { saveUserNewPassword } from "../../API/UserRepo";
import { UiStore } from "../../Data/Store/UiStore";

export interface IPasswordResetModal {
  username: string;
  onClose(): void;
  uiStore: UiStore;
}

@observer
export class PasswordResetModal extends React.Component<IPasswordResetModal> {
  @observable oldPwd: string = "";
  @observable newPwd1: string = "";
  @observable newPwd2: string = "";

  public render() {
    return (
      <Modal
        title="Change Password"
        className="PasswordResetModal"
        onClose={this.props.onClose}
      >
        <div className="BuildMetaData">
          <div className="Editors">
            <TextValueEditor
              autoFocus
              name="Username"
              value={this.props.username}
              disabled={true}
            />
            <TextValueEditor
              autoFocus
              name="Old Password"
              value={this.oldPwd}
              required={true}
              password={true}
              onChange={(value: string) => (this.oldPwd = value)}
            />
            <TextValueEditor
              name="New Password"
              value={this.newPwd1}
              required={true}
              password={true}
              onChange={(value: string) => (this.newPwd1 = value)}
            />
            <TextValueEditor
              name="New Password Again"
              value={this.newPwd2}
              required={true}
              password={true}
              onChange={(value: string) => (this.newPwd2 = value)}
            />
          </div>
        </div>
        <div className="bottomRow">
          <span className="requiredWarning">
            * indicates a required field
            </span>
          {
            <div className="Actions">
              <HhitsButton link onClick={this.props.onClose}>
                Cancel
                </HhitsButton>
              <HhitsButton
                primary
                disabled={
                  this.newPwd1.length === 0 || this.newPwd2.length === 0
                }
                onClick={this.onSave}
              >
                Save
                </HhitsButton>
            </div>
          }
        </div>
      </Modal>
    );
  }

  private onSave = () => {
    var allGood = true;

    if (allGood) {
      saveUserNewPassword(this.props.username, this.oldPwd, this.newPwd1).then(
        (results) => {
          if (results) {
            //console.log("Success saving new password - " + results)
            this.props.uiStore.setPwdResetSuccessMsgVisible(true);
          } else {
            //console.log("Error saving new password - " + results)
            this.props.uiStore.setPwdResetFailMsgVisible(true);
          }
        }
      );
    }

    this.props.onClose();
  };
}
