import { observable } from "mobx";

import { SearchOperator } from "../../../Data/Search";
import { ISearchAttribute, SearchOption } from "./SearchOption";

export class SearchNumeric extends SearchOption {
  @observable public value: number | null;
  @observable public operator: SearchOperator;
  public options: SearchOperator[];
  public defaultText?: string;

  constructor(
    value: number | null,
    operator: SearchOperator,
    options: SearchOperator[],
    attribute: ISearchAttribute,
    enabled: boolean = true,
    defaultText?: string,
    active?: boolean
  ) {
    super(attribute, enabled);
    this.value = value;
    this.operator = operator;
    this.options = options;
    this.defaultText = defaultText;
    this.active = active ? active : false;
  }

  clone = () => {
    return new SearchNumeric(
      this.value,
      this.operator,
      this.options,
      this.attribute,
      this.enabled,
      this.defaultText,
      this.active
    );
  };

  public setValue(value: number | null) {
    this.value = value;
  }
}
