import { observable } from "mobx";

import { ISearchAttribute, SearchOption } from "./SearchOption";

export class SearchBool extends SearchOption {
  @observable public checked: boolean = false;

  constructor(
    checked: boolean,
    attribute: ISearchAttribute,
    enabled: boolean = true,
    active?: boolean
  ) {
    super(attribute, enabled);
    this.checked = checked;
    this.active = active ? active : false;
  }

  clone = () => {
    return new SearchBool(
      this.checked,
      this.attribute,
      this.enabled,
      this.active
    );
  };

  public setValue(value: boolean) {
    this.checked = value;
  }
}
