export class ComputedPower {
  public index: number = 0;
  public powerTotalDrawInWatts: number = 0;
  public powerTotalSupplyInWatts: number = 0;
  public weightTotalInGrams: number = 0;
  public allocatedWeight: number = 0;
  public power12vTotalDrawInAmps: number = 0;
  public power5vTotalDrawInAmps: number = 0;
  public power3_3vTotalDrawInAmps: number = 0;
  public power3_3vAuxTotalDrawInAmps: number = 0;
  public power12vAuxPlusTotalDrawInAmps: number = 0;
  public power12vAuxNegTotalDrawInAmps: number = 0;
  public power12vTotalSupplyInAmps: number = 0;
  public power5vTotalSupplyInAmps: number = 0;
  public power3_3vTotalSupplyInAmps: number = 0;
  public power3_3vAuxTotalSupplyInAmps: number = 0;
  public power12vAuxPlusTotalSupplyInAmps: number = 0;
  public power12vAuxNegTotalSupplyInAmps: number = 0;
}
