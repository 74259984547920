export enum ErrorLevel {
  Error = "Error",
  Warning = "Warning",
  OK = "OK",
}

export class ErrorMessage {
  ruleId: string = "";
  message: string = "No message given.";
  errorLevel: ErrorLevel = ErrorLevel.Warning;
  index: number = -1;
  field: string = "";
  sourceComponentName?: string = "";
  groupName?: string = "";

  constructor(source?: ErrorMessage) {
    if (source) {
      Object.assign(this, source);
    }
  }

  public static SortByErrorLevel(errors: ErrorMessage[]) {
    return errors.slice().sort((a, b) => {
      const aVal = ErrorMessage.GetErrorSortValue(a.errorLevel);
      const bVal = ErrorMessage.GetErrorSortValue(b.errorLevel);
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    });
  }
  public static SortErrorLevels(errorLevels: ErrorLevel[]) {
    return errorLevels.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
  }

  private static GetErrorSortValue(errorLevel: ErrorLevel) {
    switch (errorLevel) {
      case ErrorLevel.Error:
        return 0;
      case ErrorLevel.Warning:
        return 1;
      case ErrorLevel.OK:
        return 2;
    }
  }
}
