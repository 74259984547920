import { observable } from "mobx";

import {
  PropGroup,
  PropertyDescriptor,
  Processor,
  IComponentProps,
  Software,
  ComponentPort,
  ComponentSlot,
  IComputedProps,
  getComponentRuleProperties,
  Memory,
  DifferenceSet,
} from "../ComponentProperties";
import {
  PropertyType,
  PermissionLevels,
} from "../../Repository/PropertySchema";
import { ComponentType } from "../Component";
import { FileData } from "../FileData";
import { RulePropertySchema } from "../Rule";
import { GrantedGroupPermission, GrantedUserPermission } from "../UserGroup";
import { USER_ID_SESSION_ATTRIBUTE_NAME } from "../../../Pages/Login/AuthenticationService";
import { BaseComponent } from "./BaseComponent";
import { ConnectorProps } from "../Subcomponents/Connector.types";
import { AdditionalPowerProps } from "../Subcomponents/Power.types";
import { ComputedPower } from "../Subcomponents/ComputedPower";

export class MezzanineProps extends BaseComponent
  implements IComponentProps, ConnectorProps, AdditionalPowerProps {
  @observable public type: string = "";
  @observable public form: string = "";
  @observable public size: string = "";
  @observable public protocol: string = "";
  @observable public protocolVersion: string = "";
  @observable public mapping: string = "";
  @observable public processors: Processor[] = [];
  @observable public memory: Memory[] = [];
  @observable public ports: ComponentPort[] = [];
  @observable public overvoltageProtection?: boolean;
  @observable public reversePolarityProtection?: boolean;
  @observable public shortCircuitProtection?: boolean;
  @observable public twoLevelMaintenance?: boolean;
  @observable public loadSharing?: boolean;
  @observable public clock: string = "";

  constructor(source?: MezzanineProps) {
    super();
    if (source) {
      Object.assign(this, source);
      this.ports = source.ports.map((port) => new ComponentPort(port));
      this.processors = source.processors.map(
        (processor) => new Processor(processor)
      );
      this.memory = source.memory.map((memory) => new Memory(memory));
      this.software = source.software.map((software) => new Software(software));
      this.groupAccess = source.groupAccess.map(
        (access) => new GrantedGroupPermission(access)
      );
      this.userAccess = source.userAccess.map(
        (access) => new GrantedUserPermission(access)
      );
      this.documentation = source.documentation.map(
        (documentation) => new FileData(documentation)
      );
    }
  }

  public clone(keepId?: boolean): MezzanineProps {
    var clone = new MezzanineProps(this);
    if (!keepId) {
      clone.id = undefined;
      clone.model = this.model + " (Clone)";
    }
    var currentUserId = sessionStorage.getItem(USER_ID_SESSION_ATTRIBUTE_NAME);
    if (currentUserId) {
      clone.ownerId = currentUserId;
    }
    return clone;
  }

  public containsEmptyRequiredFields(): boolean {
    if (this.model.length <= 0) return true;
    return false;
  }

  public getId(): string | undefined {
    return this.id;
  }
  public getComponentType(): ComponentType {
    return ComponentType.Mezzanine;
  }

  public getTitle(): string {
    return this.model;
  }

  public getSubtext(): string {
    return this.type;
  }

  public getSlots(): ComponentSlot[] {
    return [];
  }
  public getTopPropertySchema() {
    return MEZZANINE_TOP_PROPERTY_SCHEMA;
  }
  public getPermissionPropertySchema(): PropGroup<MezzanineProps>[] {
    return MEZZANINE_PERMISSION_PROPERTY_SCHEMA;
  }
  public getPropertySchema(): PropGroup<MezzanineProps>[] {
    return this.buildMezzanineSchema();
  }
  public getRepositoryProperties(): PropertyDescriptor<MezzanineProps>[] {
    return [
      new PropertyDescriptor("vendor", "Vendor"),
      new PropertyDescriptor("type", "Type"),
      new PropertyDescriptor("form", "Form"),
      new PropertyDescriptor(
        "powerTotalInWatts",
        "Power",
        PropertyType.Number,
        "Watts"
      ),
    ];
  }
  public addToList<ModuleProps>(propName: keyof ModuleProps) {
    if (propName === "processors") {
      this.processors.push(
        new Processor({ index: this.processors.length + 1 })
      );
    } else if (propName === "ports") {
      this.ports.push(new ComponentPort({ index: this.ports.length + 1 }));
    } else if (propName === "memory") {
      this.memory.push(new Memory({}));
    } else if (propName === "software") {
      this.software.push(new Software({}));
    } else if (propName === "groupAccess") {
      this.groupAccess.push(new GrantedGroupPermission({}));
    } else if (propName === "userAccess") {
      this.userAccess.push(new GrantedUserPermission({}));
    } else if (propName === "documentation") {
      this.documentation.push(new FileData());
    }
  }
  public removeFromList<ModuleProps>(
    propName: keyof ModuleProps,
    index: number
  ) {
    if (propName === "processors") {
      this.processors.splice(index, 1);
    } else if (propName === "ports") {
      this.ports.splice(index, 1);
    } else if (propName === "memory") {
      this.memory.splice(index, 1);
    } else if (propName === "software") {
      this.software.splice(index, 1);
    } else if (propName === "groupAccess") {
      this.groupAccess.splice(index, 1);
    } else if (propName === "userAccess") {
      this.userAccess.splice(index, 1);
    } else if (propName === "documentation") {
      this.documentation.splice(index, 1);
    }
  }
  public asImplementation(): MezzanineProps {
    return this;
  }

  public static getRulePropertySchema(): RulePropertySchema[] {
    const properties = getComponentRuleProperties(
      MEZZANINE_PROPERTY_LIST as PropGroup<IComponentProps>[]
    );

    properties.push.apply(
      properties,
      ComponentPort.getRulePropertySchema("ports", "Port")
    );
    properties.push.apply(
      properties,
      Processor.getRulePropertySchema("processors", "Processor")
    );
    properties.push.apply(
      properties,
      Memory.getRulePropertySchema("memory", "Memory")
    );
    properties.push.apply(
      properties,
      Software.getRulePropertySchema("software", "Software")
    );

    return properties.sort((a, b) => a.display.localeCompare(b.display));
  }

  public compareTo(other: MezzanineProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();

    diffs.addDiffs(this.getEnvironmentalDifferences(other));
    diffs.addDiffs(this.getVendorDifferences(other));
    diffs.addDiffs(this.getPhysicalDifferences(other));
    diffs.addDiffs(this.getHostDifferences(other));
    diffs.addDiffs(this.getSystemDifferences(other));
    diffs.addDiffs(this.getPowerDifferences(other, false, false));

    if (this.type !== other.type) diffs.addDiff("Connector", "type");
    if (this.form !== other.form) diffs.addDiff("Connector", "form");
    if (this.protocol !== other.protocol)
      diffs.addDiff("Connector", "protocol");
    if (this.protocolVersion !== other.protocolVersion)
      diffs.addDiff("Connector", "protocolVersion");
    if (this.size !== other.size) diffs.addDiff("Connector", "size");
    if (this.mapping !== other.mapping) diffs.addDiff("Connector", "mapping");

    diffs.compareProps("Processors", this.processors, other.processors);
    diffs.compareProps("Memory", this.memory, other.memory);

    diffs.compareProps("Ports", this.ports, other.ports);

    diffs.compareProps("SW / FW / BSP Support", this.software, other.software);

    if (this.clock !== other.clock) diffs.addDiff("Other", "clock");
    if (this.overvoltageProtection !== other.overvoltageProtection)
      diffs.addDiff("Other", "overvoltageProtection");
    if (this.reversePolarityProtection !== other.reversePolarityProtection)
      diffs.addDiff("Other", "reversePolarityProtection");
    if (this.shortCircuitProtection !== other.shortCircuitProtection)
      diffs.addDiff("Other", "shortCircuitProtection");
    if (this.twoLevelMaintenance !== other.twoLevelMaintenance)
      diffs.addDiff("Other", "twoLevelMaintenance");
    if (this.loadSharing !== other.loadSharing)
      diffs.addDiff("Other", "loadSharing");

    return diffs;
  }

  public getOwnerId(): string {
    return this.ownerId;
  }

  public getWorldAccess(): PermissionLevels {
    return this.worldAccess;
  }

  public getGroupAccess(): GrantedGroupPermission[] {
    return this.groupAccess;
  }

  public getUserAccess(): GrantedUserPermission[] {
    return this.userAccess;
  }

  buildMezzanineSchema = () => {
    let mezzanineSchema: PropGroup<MezzanineProps>[] = [
      this.getVendorPropGroup(),
      new PropGroup("Connector", [
        new PropertyDescriptor(
          "form",
          "Form",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["XMC", "PMC", "FMC"]
        ),
        new PropertyDescriptor(
          "protocol",
          "Protocol",
          PropertyType.RelatedSingleSelectDB,
          undefined,
          undefined,
          undefined,
          undefined,
          "protocol",
          new PropertyDescriptor(
            "protocolVersion",
            "Version",
            PropertyType.SingleSelectDB,
            undefined,
            undefined,
            undefined,
            undefined,
            "protocol-version"
          )
        ),
        new PropertyDescriptor(
          "type",
          "Connector Type",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["VITA 42", "VITA 61"]
        ),
        new PropertyDescriptor(
          "size",
          "Size",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["Single Width", "Double Width"]
        ),
        new PropertyDescriptor(
          "mapping",
          "Mapping",
          PropertyType.SingleSelectDB,
          undefined,
          undefined,
          undefined,
          undefined,
          "mezzanine-mapping"
        ),
      ]),
      new PropGroup("Processors", [
        new PropertyDescriptor("processors", "Processors", PropertyType.List),
      ]),
      new PropGroup("Memory", [
        new PropertyDescriptor("memory", "Memory", PropertyType.List),
      ]),
      this.getPowerPropGroup(false, false),
      new PropGroup("Ports", [
        new PropertyDescriptor("ports", "Ports", PropertyType.List),
      ]),
      this.getEnvironmentalPropGroup(),
      this.getPhysicalPropGroup(),
      this.getSystemPropGroup(),
      new PropGroup("SW / FW / BSP Support", [
        new PropertyDescriptor(
          "software",
          "SW / FW / BSP Support",
          PropertyType.List
        ),
      ]),
      new PropGroup("Other", [
        new PropertyDescriptor("clock", "Clock"),
        new PropertyDescriptor(
          "overvoltageProtection",
          "Overvoltage Protection",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "reversePolarityProtection",
          "Reverse Polarity Protection",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "shortCircuitProtection",
          "Short Circuit Protection",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "twoLevelMaintenance",
          "Two Level Maintenance",
          PropertyType.Flag
        ),
        new PropertyDescriptor(
          "loadSharing",
          "Load Sharing",
          PropertyType.Flag
        ),
      ]),
      this.getHostPropGroup(),
      new PropGroup("Documentation", [
        new PropertyDescriptor(
          "documentation",
          "Documentation",
          PropertyType.CompactList
        ),
      ]),
      this.getPermissionPropGroup(),
    ];
    return mezzanineSchema;
  };
}

export class MezzanineComputeds extends ComputedPower
  implements IComputedProps {
  constructor(source?: MezzanineComputeds) {
    super();
    if (source) {
      Object.assign(this, source);
    }
  }
}

const MEZZANINE_TOP_PROPERTY_SCHEMA: PropGroup<MezzanineProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("type", "Type"),
    new PropertyDescriptor("form", "Form"),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Total Power",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor("coolingType", "Cooling Type"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
  ]),
];

const MEZZANINE_PERMISSION_PROPERTY_SCHEMA: PropGroup<MezzanineProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor(
      "ownerId",
      "Owner",
      PropertyType.SingleSelectById,
      undefined,
      undefined,
      undefined,
      undefined,
      "users"
    ),
    new PropertyDescriptor("worldAccess", "World"),
    new PropertyDescriptor("groupAccess", "Groups", PropertyType.CompactList),
    new PropertyDescriptor("userAccess", "Users", PropertyType.CompactList),
    new PropertyDescriptor("userAccess", "Users", PropertyType.CompactList),
  ]),
];

const MEZZANINE_PROPERTY_LIST: PropGroup<MezzanineProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("partNumber", "Part Number"),
    new PropertyDescriptor("seriesNumber", "Series Number"),
    new PropertyDescriptor("revisionNumber", "Revision Number"),
    new PropertyDescriptor(
      "manufacturedDate",
      "Manufactured Date",
      PropertyType.Date
    ),
    new PropertyDescriptor(
      "type",
      "Connector Type",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["VITA 42", "VITA 61"]
    ),
    new PropertyDescriptor(
      "form",
      "Form",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Single Width", "Double Width"]
    ),
    new PropertyDescriptor(
      "size",
      "Size",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Single Width", "Double Width"]
    ),
    new PropertyDescriptor(
      "protocol",
      "Protocol",
      PropertyType.RelatedSingleSelectDB,
      undefined,
      undefined,
      undefined,
      undefined,
      "protocol",
      new PropertyDescriptor(
        "protocolVersion",
        "Version",
        PropertyType.SingleSelectDB,
        undefined,
        undefined,
        undefined,
        undefined,
        "protocol-version"
      )
    ),
    new PropertyDescriptor(
      "mapping",
      "Mapping",
      PropertyType.SingleSelectDB,
      undefined,
      undefined,
      undefined,
      undefined,
      "mezzanine-mapping"
    ),
    new PropertyDescriptor("processors", "Processors", PropertyType.List),
    new PropertyDescriptor("memory", "Memory", PropertyType.List),
    new PropertyDescriptor(
      "power12vInAmps",
      "12V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power5vInAmps",
      "5V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vInAmps",
      "3.3V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vAuxInAmps",
      "3.3V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxPlusInAmps",
      "+12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxNegInAmps",
      "-12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Total Power",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor("ports", "Ports", PropertyType.List),
    new PropertyDescriptor(
      "coolingType",
      "Cooling Type",
      PropertyType.SingleSelect
    ),
    new PropertyDescriptor(
      "operatingTemperatureMinInCelsius",
      "Min Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "operatingTemperatureMaxInCelsius",
      "Max Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMinInCelsius",
      "Min Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMaxInCelsius",
      "Max Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "temperatureCycling",
      "Temperature Cycling",
      PropertyType.Flag
    ),
    new PropertyDescriptor("vibration", "Vibration"),
    new PropertyDescriptor("shock", "Shock"),
    new PropertyDescriptor("humidity", "Humidity"),
    new PropertyDescriptor(
      "operatingAltitudeMinInFeet",
      "Min Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "operatingAltitudeMaxInFeet",
      "Max Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "rapidDecompression",
      "Rapid Decompression",
      PropertyType.Flag
    ),
    new PropertyDescriptor("fungusResistance", "Fungus Resistance"),
    new PropertyDescriptor(
      "electrostaticDischargeResistance",
      "Electrostatic Discharge Resistance"
    ),
    new PropertyDescriptor("corrosionResistance", "Corrosion Resistance"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor(
      "lengthInInches",
      "Length",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "widthInInches",
      "Width",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "weightInGrams",
      "Weight",
      PropertyType.Number,
      "Grams"
    ),
    new PropertyDescriptor(
      "ipmcImplemented",
      "IPMC Implemented",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier1Controller",
      "Tier 1 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier2Controller",
      "Tier 2 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "chassisManager",
      "Chassis Manager",
      PropertyType.Flag
    ),
    new PropertyDescriptor("clock", "Clock"),
    new PropertyDescriptor("software", "Software Support", PropertyType.List),
    new PropertyDescriptor(
      "overvoltageProtection",
      "Overvoltage Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "reversePolarityProtection",
      "Reverse Polarity Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "shortCircuitProtection",
      "Short Circuit Protection",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "twoLevelMaintenance",
      "Two Level Maintenance",
      PropertyType.Flag
    ),
    new PropertyDescriptor("loadSharing", "Load Sharing", PropertyType.Flag),
    new PropertyDescriptor("hostConformancy", "Conformant", PropertyType.Flag),
    new PropertyDescriptor(
      "hostConformancyLevel",
      "Level of Conformance",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Level A", "Level B"]
    ),
    new PropertyDescriptor("tier3", "Tier 3"),
  ]),
];
