import axios from "axios";

export async function Get<T>(url: string): Promise<T> {
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
    .then((response) => {
      return response && response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/";
      }
    });
}

export async function Delete<T>(url: string): Promise<T> {
  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
    .then((response) => {
      return response && response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/";
      }
    });
}

export async function Post<T>(url: string, data: {}): Promise<T> {
  return axios
    .post(url, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
    .then((response) => {
      return response && response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/";
      }
    });
}

export async function PostFile<T>(url: string, file: File): Promise<T> {
  const formData = new FormData();
  formData.append("file", file);

  return axios
    .post(url, formData, {
      headers: {
        "Content-Type": "mulitpart/form-data",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
    .then((response) => {
      return response && response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/";
      }
    });
}
