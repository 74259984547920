import { observable } from "mobx";

import { ISearchAttribute, SearchOption } from "./SearchOption";

export class SearchMultiSelect extends SearchOption {
  @observable public value: string[] = [];
  public options: string[] = [];
  public defaultText?: string;
  public autoPopulateOptions?: boolean;

  constructor(
    value: string[],
    options: string[],
    attribute: ISearchAttribute,
    enabled: boolean = true,
    defaultText?: string,
    autoPopulateOptions?: boolean,
    active?: boolean
  ) {
    super(attribute, enabled);
    this.value = value;
    this.options = options;
    this.defaultText = defaultText;
    this.autoPopulateOptions = autoPopulateOptions;
    this.active = active ? active : false;
  }

  clone = () => {
    return new SearchMultiSelect(
      this.value,
      this.options,
      this.attribute,
      this.enabled,
      this.defaultText,
      this.autoPopulateOptions,
      this.active
    );
  };

  public setValue(value: string[]) {
    this.value = value;
  }
}
