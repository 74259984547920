import { action, computed, observable } from "mobx";
import { createContext, ReactNode, useEffect, useState } from "react";
import {
  USER_CAUTIONCOLOR_SESSION_ATTRIBUTE_NAME,
  USER_INFOCOLOR_SESSION_ATTRIBUTE_NAME,
  USER_MEASUREMENTSYSTEM_SESSION_ATTRIBUTE_NAME,
  USER_WARNINGCOLOR_SESSION_ATTRIBUTE_NAME,
} from '../../Pages/Login/AuthenticationService';
import { ErrorLevel } from "../Schema/Errors";

export enum ContextPage {
  Properties = "Properties",
  Repository = "Repository",
  Rules = "Rules",
}

export const DEFAULT_UNIT = "Grams";
export const DEFAULT_INFORMATION_COLOR = "#000000";
export const DEFAULT_WARNING_COLOR = "#e23b0b";
export const DEFAULT_CAUTION_COLOR = "#efb313";

interface HeaderItem {
  key: number;
  weight: number;
  content: ReactNode;
}

export class UiStore {
  private initializeMeasurementSystem = () => {
    let system = sessionStorage.getItem(USER_MEASUREMENTSYSTEM_SESSION_ATTRIBUTE_NAME);
    return system === null ? "" : system;
  }

  private initializeColor = (attribute: string, defaultColor: string) => {
    let color = sessionStorage.getItem(attribute);
    return color === null ? defaultColor : color;
  }

  private initializeErrorLevelColors = () => {
    let errorLevelColors = observable.map<ErrorLevel, string>();
    
    errorLevelColors.set(ErrorLevel.OK, this.initializeColor(USER_INFOCOLOR_SESSION_ATTRIBUTE_NAME, DEFAULT_INFORMATION_COLOR));
    errorLevelColors.set(ErrorLevel.Error, this.initializeColor(USER_WARNINGCOLOR_SESSION_ATTRIBUTE_NAME, DEFAULT_WARNING_COLOR));
    errorLevelColors.set(ErrorLevel.Warning, this.initializeColor(USER_CAUTIONCOLOR_SESSION_ATTRIBUTE_NAME, DEFAULT_CAUTION_COLOR));
    return errorLevelColors;
  }


  @observable public subContextItems: HeaderItem[] = [];

  @observable public aboutModalVisible = false;
  @observable public userEditorVisible = false;
  @observable public passwordResetModalVisible = false;
  @observable public passwordResetSuccessVisible = false;
  @observable public passwordResetFailVisible = false;

  @observable public measurementSystem = this.initializeMeasurementSystem();
  @observable private errorLevelColors = this.initializeErrorLevelColors();
  @observable public contextVisible: boolean = true;
  @observable public contextPage: ContextPage = ContextPage.Repository;



  public setPwdResetSuccessMsgVisible = (vis: boolean) => {
    this.passwordResetSuccessVisible = vis;
  };

  public setPwdResetFailMsgVisible = (vis: boolean) => {
    this.passwordResetFailVisible = vis;
  };

  public toggleAboutModal = () => {
    this.aboutModalVisible = !this.aboutModalVisible;
  };

  public togglePasswordResetModal = () => {
    this.passwordResetModalVisible = !this.passwordResetModalVisible;
  };

  public toggleUserEditorModal = () => {
    this.userEditorVisible = !this.userEditorVisible;
  };

  public setContextPage = (contextPage: ContextPage) => {
    this.contextVisible = true;
    this.contextPage = contextPage;
  };

  public setMeasurementSystem = (unit: string) => {
    this.measurementSystem = unit;
    sessionStorage.setItem(
      USER_MEASUREMENTSYSTEM_SESSION_ATTRIBUTE_NAME,
      unit
    );
  }

  @computed
  public get ErrorLevelColors() {
    return this.errorLevelColors;
  };

  @action
  public setErrorColors = (informationColor: string, cautionColor: string, warningColor: string) => {
    sessionStorage.setItem(USER_INFOCOLOR_SESSION_ATTRIBUTE_NAME, informationColor);
    sessionStorage.setItem(USER_WARNINGCOLOR_SESSION_ATTRIBUTE_NAME, warningColor);
    sessionStorage.setItem(USER_CAUTIONCOLOR_SESSION_ATTRIBUTE_NAME, cautionColor);

    this.errorLevelColors.set(ErrorLevel.OK, informationColor);
    this.errorLevelColors.set(ErrorLevel.Error, warningColor);
    this.errorLevelColors.set(ErrorLevel.Warning, cautionColor);
  };

  public determineErrorColor = (userColor: string | undefined, defaultColor: string) => {
    if (userColor && userColor !== "") {
      return userColor 
    } 
    return defaultColor;
  };

  public reinitializeErrorColors = () => {
    this.errorLevelColors = this.initializeErrorLevelColors();
  }

  private nextKey = 1;
  public useHeaderRealEstate(weight: number, content: ReactNode) {
    const [key] = useState(() => this.nextKey++);
    useEffect(() => {
      const item = {
        key,
        weight,
        content,
      };
      this.subContextItems.push(item);
      return () => {
        const i = this.subContextItems.findIndex((item) => item.key === key);
        if (i >= 0) {
          this.subContextItems.splice(i, 1);
        }
      };
    });
  }
}

export const UiStoreStoreContext = createContext(new UiStore());
