import * as React from "react";

import { Snackbar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HhitsButton } from "./Button";

export interface MessageProps {
  message: string;
  open: boolean;

  onClose(): void;
}

export class HhitsMessage extends React.Component<MessageProps> {
  public render() {
    return (
      <Snackbar
        className="HhitsMessage"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={this.props.open}
        autoHideDuration={5000}
        onClose={this.handleClose}
        message={<span>{this.props.message}</span>}
        action={[
          <HhitsButton icon onClick={this.props.onClose}>
            <FontAwesomeIcon icon="times" />
          </HhitsButton>,
        ]}
      />
    );
  }

  private handleClose = (event: React.SyntheticEvent<any>, reason: string) => {
    if (reason !== "clickaway") {
      this.props.onClose();
    }
  };
}
