import * as React from "react";
import ReactDataSheet from "react-datasheet";

import { computed } from "mobx";
import { HhitsButton } from "../../Components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackplaneConnection } from "../../Data/Schema/ComponentProperties";

const MAX_SLOTS = 48;

interface ConnectionTableProps {
  rows: BackplaneConnection[];
  //slotCount: number;
  title: string;
  readonly?: boolean;

  onChange(newRows: BackplaneConnection[]): void;
}

interface TableSelectProps {
  value: any;
  options: any[];
  onChange(value: string): void;
}

interface DataEditorProps {
  value: any;
  onChange(value: string): void;
}

interface TableCell {
  value: string;
}
export class TableSelect extends React.Component<TableSelectProps> {
  public render() {
    return (
      <select value={this.props.value} onChange={this.handleChange}>
        {this.props.options.map((option) => {
          if (option === 99) return <option value={option}>{"RTM"}</option>;
          else return <option value={option}>{option}</option>;
        })}
      </select>
    );
  }

  private handleChange = (event: any) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };
}

export class DataEditor extends React.Component<DataEditorProps> {
  public render() {
    return (
      <input
        autoFocus={true}
        value={this.props.value}
        onChange={this.handleChange}
      ></input>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };
}

export class ConnectionTable extends React.Component<ConnectionTableProps> {
  @computed get data(): {}[][] {
    return [
      [
        { readOnly: true },
        { value: "A", readOnly: true },
        { value: "A Label", readOnly: true },
        { value: "A Plane", readOnly: true },
        { value: "B", readOnly: true },
        { value: "B Label", readOnly: true },
        { value: "B Plane", readOnly: true },
        { value: "Row", readOnly: true },
        { value: "Outer Label", readOnly: true },
      ],
      ...this.props.rows.map((connection, index) => {
        return [
          { value: index + 1, readOnly: true },
          {
            value: connection.a,
            className: "slotcell",
            readOnly: this.props.readonly,
          },
          {
            value: connection.a_label,
            className: "labelcell",
            readOnly: this.props.readonly,
          },
          {
            value: connection.a_plane,
            className: "planecell",
            readOnly: this.props.readonly,
          },
          {
            value: connection.b === 99 ? "RTM" : connection.b,
            className: "slotcell",
            readOnly: this.props.readonly,
          },
          {
            value: connection.b_label,
            className: "labelcell",
            readOnly: this.props.readonly,
          },
          {
            value: connection.b_plane,
            className: "planecell",
            readOnly: this.props.readonly,
          },
          {
            value: connection.row,
            className: "whichrowcell",
            readOnly: this.props.readonly,
          },
          {
            value: connection.right_label,
            className: "labelcell",
            readOnly: this.props.readonly,
          },
        ];
      }),
    ];
  }

  public render() {
    var planeOptions: string[] = ["", "control", "data", "expansion"];

    var slotOptions: number[] = [];
    var slotOptionsWithRTM: number[] = [];
    for (let i = 1; i <= MAX_SLOTS; i++) {
      slotOptions.push(i);
      slotOptionsWithRTM.push(i);
    }
    slotOptionsWithRTM.push(99);

    return (
      <>
        <div className="ConnectionTable">
          <div className="ConnectionTableTitle">{this.props.title}</div>
          <ReactDataSheet
            className="Datasheet"
            data={this.data}
            dataEditor={(props) =>
              props.col === 1 ? (
                <TableSelect
                  value={props.value}
                  options={slotOptions}
                  onChange={(value) => props.onChange(value)}
                />
              ) : props.col === 4 ? (
                <TableSelect
                  value={props.value}
                  options={slotOptionsWithRTM}
                  onChange={(value) => props.onChange(value)}
                />
              ) : props.col === 3 || props.col === 6 ? (
                <TableSelect
                  value={props.value}
                  options={planeOptions}
                  onChange={(value) => props.onChange(value)}
                />
              ) : (
                <DataEditor
                  value={props.value}
                  onChange={(value) => props.onChange(value)}
                />
              )
            }
            valueRenderer={(cell) => (cell as TableCell).value}
            rowRenderer={(row) => {
              return (
                <tr className="table-row">
                  {row.children}
                  {row.row !== 0 && this.props.readonly === false && (
                    <td
                      className="row-delete"
                      onClick={() => this.deleteRow(row.row)}
                    >
                      <FontAwesomeIcon icon="times" />
                    </td>
                  )}
                </tr>
              );
            }}
            onCellsChanged={(changes, additions) => {
              const grid = this.data;
              changes.forEach(({ cell, row, col, value }) => {
                grid[row][col] = { ...grid[row][col], value };
              });
              if (additions) {
                additions.forEach(({ row, col, value }) => {
                  if (grid.length > row) {
                    grid[row][col] = { ...grid[row][col], value };
                  } else {
                    grid.splice(row, 0, [
                      { value: row + 1, readOnly: true },
                      { value: col === 1 ? value : "" },
                      { value: col === 2 ? value : "" },
                    ]);
                  }
                });
              }

              this.updatePairValues(grid as TableCell[][]);
            }}
          />
        </div>
        <br />
        {this.props.readonly === false && MAX_SLOTS > 1 && (
          <HhitsButton className="AddNewItem" onClick={this.addRow}>
            <FontAwesomeIcon icon="plus" />
            Add Row
          </HhitsButton>
        )}
        {this.props.readonly === false && MAX_SLOTS < 2 && (
          <span>
            At least 2 slots must exist before connections can be defined.
          </span>
        )}
      </>
    );
  }

  private deleteRow = (index: number) => {
    const grid = this.data;
    grid.splice(index, 1);

    this.updatePairValues(grid as TableCell[][]);
  };

  private addRow = () => {
    const grid = this.data;
    grid.push([
      { value: grid.length + 1, readOnly: true },
      { value: -1 },
      { value: "" },
      { value: "" },
      { value: -1 },
      { value: "" },
      { value: "" },
      { value: -1 },
      { value: "" },
    ]);

    this.updatePairValues(grid as TableCell[][]);
  };

  private updatePairValues = (grid: TableCell[][]) => {
    const rows = grid
      .filter((row, x) => x !== 0)
      .map((row) => {
        const connection = new BackplaneConnection();
        connection.a = parseInt(row[1].value);
        connection.a_label = row[2].value;
        connection.a_plane = row[3].value;
        connection.b = row[4].value === "RTM" ? 99 : parseInt(row[4].value);
        connection.b_label = row[5].value;
        connection.b_plane = row[6].value;
        connection.row = parseInt(row[7].value);
        connection.right_label = row[8].value;
        return connection;
      });

    this.props.onChange(rows);
  };
}
