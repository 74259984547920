import { observable } from "mobx";
import { ComponentType } from "./Component";
import { SearchOperator } from "../Search";
import { ErrorLevel } from "./Errors";
import moment from "moment";
import { PropertyType } from "../Repository/PropertySchema";

export class RuleGroup {
  public id?: string;
  @observable public name: string = "";
  @observable public description: string = "";
  @observable public owner: string = "";

  constructor(source?: RuleGroup) {
    if (source) {
      Object.assign(this, source);
    }
  }
}

export interface RuleGroupInfo {
  id: string;
  disabledRuleIds: string[];
}

export class Rule {
  public id?: string;
  @observable public name: string = "";
  @observable public description: string = "";
  @observable public disabled: boolean = false;
  @observable public componentType: ComponentType = ComponentType.Build;
  @observable public source: RuleOperand = new RuleOperand();
  @observable public target: RuleOperand = new RuleOperand();
  @observable public operator: SearchOperator = SearchOperator.Equals;
  @observable public errorMessage: string = "";
  @observable public dateCreated: Date = new Date();
  @observable public dateModified: Date = new Date();
  @observable public errorLevel: ErrorLevel = ErrorLevel.Error;
  @observable public group: string = "";
  @observable public numOccurences: number = 0;
  @observable public findAll: boolean = false;

  constructor(source?: Rule) {
    if (source) {
      Object.assign(this, source);
      this.source = new RuleOperand(source.source);
      this.target = new RuleOperand(source.target);
      this.dateCreated = moment(source.dateCreated).toDate();
      this.dateModified = moment(source.dateModified).toDate();
    }
  }

  public equals(rule: Rule) {
    return (
      this.id === rule.id &&
      this.name === rule.name &&
      this.description === rule.description &&
      this.disabled === rule.disabled &&
      this.componentType === rule.componentType &&
      this.dateCreated === rule.dateCreated &&
      this.dateModified === rule.dateModified &&
      this.group === rule.group &&
      this.errorMessage === rule.errorMessage &&
      this.componentType === rule.componentType &&
      this.disabled === rule.disabled &&
      this.operator === rule.operator &&
      this.numOccurences === rule.numOccurences &&
      this.findAll === rule.findAll &&
      this.source.operandValue === rule.source.operandValue &&
      this.target.operandValue === rule.target.operandValue
    );
  }
}

export interface RulePropertySchema {
  name: string;
  display: string;
  computed: boolean;
  listCount: boolean;
  type: PropertyType;
}

export enum OperandType {
  Base = "Base",
  Lookup = "Lookup",
  Constant = "Constant",
  Compound = "Compound",
}

export enum OperandSource {
  Self = "Self",
  Parent = "Parent",
  Slot = "Slot",
  Constant = "Constant",
}

export class RuleOperand {
  public operandType: OperandType = OperandType.Base;
  constructor(source?: RuleOperand) {
    if (source) {
      switch (source.operandType) {
        case OperandType.Compound: {
          return new CompoundOperand(source as CompoundOperand);
        }
        case OperandType.Constant: {
          return new ConstantOperand(source as ConstantOperand);
        }
        case OperandType.Lookup: {
          return new LookupOperand(source as LookupOperand);
        }
        default: {
          Object.assign(this, source);
        }
      }
    }
  }
  public operandValue(): string {
    return "BASE OPERAND";
  }
}

export class LookupOperand implements RuleOperand {
  public operandType: OperandType = OperandType.Lookup;
  @observable public type: OperandSource = OperandSource.Self;
  @observable public field: string = "";
  @observable public fieldDisplayName: string = "";
  @observable public computed: boolean = false;
  @observable public listCount: boolean = false;
  @observable public allowMissing: boolean = false;

  constructor(source?: LookupOperand) {
    if (source) {
      Object.assign(this, source);
    }
  }
  public operandValue() {
    return this.fieldDisplayName;
  }
}

export class ConstantOperand implements RuleOperand {
  public operandType: OperandType = OperandType.Constant;
  @observable public value: OperandValue = new OperandValue();

  constructor(source?: ConstantOperand) {
    if (source) {
      Object.assign(this, source);
      this.value = new OperandValue(source.value);
    }
  }

  public operandValue() {
    return this.value.getValue();
  }
}

export class CompoundOperand implements RuleOperand {
  public operandType: OperandType = OperandType.Compound;
  @observable public left: RuleOperand = new RuleOperand();
  @observable public right: RuleOperand = new RuleOperand();

  constructor(source?: CompoundOperand) {
    if (source) {
      Object.assign(this, source);
      this.left = new RuleOperand(source.left);
      this.right = new RuleOperand(source.right);
    }
  }

  public operandValue() {
    return `${this.left.operandValue}, ${this.right.operandValue}`;
  }
}

export enum OperandValueType {
  Base = "Base",
  NoValue = "NoValue",
  Boolean = "Boolean",
  String = "String",
  Number = "Number",
  Pair = "Pair",
  Set = "Set",
  Date = "Date",
}

export class OperandValue {
  public valueType: OperandValueType = OperandValueType.Base;
  constructor(source?: OperandValue) {
    if (source) {
      switch (source.valueType) {
        case OperandValueType.Boolean: {
          return new BooleanValue(source as BooleanValue);
        }
        case OperandValueType.NoValue: {
          return new NoValue(source as NoValue);
        }
        case OperandValueType.Number: {
          return new NumberValue(source as NumberValue);
        }
        case OperandValueType.Pair: {
          return new PairValue(source as PairValue);
        }
        case OperandValueType.Set: {
          return new SetValue(source as SetValue);
        }
        case OperandValueType.String: {
          return new StringValue(source as StringValue);
        }
        case OperandValueType.Date: {
          return new DateValue(source as DateValue);
        }
        default: {
          Object.assign(this, source);
        }
      }
    }
  }

  public getValue(): string {
    return "BASE OPERAND VALUE";
  }
}
export class NoValue implements OperandValue {
  public valueType: OperandValueType = OperandValueType.NoValue;
  constructor(source?: NoValue) {
    if (source) {
      Object.assign(this, source);
    }
  }

  public getValue() {
    return "Null";
  }
}
export class BooleanValue implements OperandValue {
  public valueType: OperandValueType = OperandValueType.Boolean;
  @observable public value: boolean = false;

  constructor(source?: BooleanValue) {
    if (source) {
      Object.assign(this, source);
    }
  }
  public getValue() {
    return this.value ? "True" : "False";
  }
}
export class StringValue implements OperandValue {
  public valueType: OperandValueType = OperandValueType.String;
  @observable public value: string = "";

  constructor(source?: StringValue) {
    if (source) {
      Object.assign(this, source);
    }
  }

  public getValue() {
    return this.value;
  }
}
export class DateValue implements OperandValue {
  public valueType: OperandValueType = OperandValueType.Date;
  @observable public value: Date = new Date();

  constructor(source?: DateValue) {
    if (source) {
      Object.assign(this, source);
    }
  }

  public getValue() {
    return moment(this.value).format("M/DD/YYYY");
  }
}
export class NumberValue implements OperandValue {
  public valueType: OperandValueType = OperandValueType.Number;
  @observable public value: number = 0;

  constructor(source?: NumberValue) {
    if (source) {
      Object.assign(this, source);
    }
  }

  public getValue() {
    return this.value.toString();
  }
}
export class PairValue implements OperandValue {
  public valueType: OperandValueType = OperandValueType.Pair;
  @observable public left: OperandValue = new OperandValue();
  @observable public right: OperandValue = new OperandValue();

  constructor(source?: PairValue) {
    if (source) {
      Object.assign(this, source);
      this.left = new OperandValue(source.left);
      this.right = new OperandValue(source.right);
    }
  }

  public init(left: number, right: number) {
    if (left && right) {
      var l = new NumberValue();
      l.value = left;
      var r = new NumberValue();
      r.value = right;
      this.left = new OperandValue(l);
      this.right = new OperandValue(r);
    }
  }

  public getValue() {
    return `${this.left}, ${this.right}`;
  }
}
export class SetValue implements OperandValue {
  public valueType: OperandValueType = OperandValueType.Set;
  @observable public options: OperandValue[] = [];

  constructor(source?: SetValue) {
    if (source) {
      Object.assign(this, source);
      this.options = source.options.map((option) => new OperandValue(option));
    }
  }

  public getValue() {
    return "List";
  }
}
