import * as React from "react";

import { observer } from "mobx-react";

import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
interface ValueOption {
  value: string;
  caption: string;
}
export interface ISingleSelectValue {
  name?: string;
  value: string;
  options: ValueOption[];
  disabled?: boolean;
  required?: boolean;
  notNullable?: boolean;
  onChange?(value: string): void;
}

@observer
export class SingleSelectValueEditor extends React.Component<
  ISingleSelectValue
> {
  public render() {
    return (
      <FormControl required={this.props.required} fullWidth={true}>
        {this.props.name && (
          <InputLabel htmlFor={this.props.name}>{this.props.name}</InputLabel>
        )}
        <Select
          id={this.props.name}
          onChange={this.onChange}
          fullWidth={true}
          disabled={this.props.disabled}
          value={this.props.value}
          input={
            <Input
              fullWidth={true}
              name={this.props.name}
              id={this.props.name}
            />
          }
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {!this.props.notNullable && <MenuItem value="" />}
          {this.props.options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.caption}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  onChange = (event: any) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };
}
