export function parseNumber(value: string | undefined) {
  if (value && value !== undefined) {
    return Number(value.replace(/[^\d.-]/g, ""));
  } else {
    return 0;
  }
}

export function roundNumber(value: number, digits: number = 0) {
  return +value.toFixed(digits);
}

export function hundredthsDouble(value: any): string {
  return roundNumber(Number(value), 2).toString();
}