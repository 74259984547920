import { observable } from "mobx";

import {
  IComponentProps,
  PropGroup,
  ModuleSlot,
  PropertyDescriptor,
  ComponentPort,
  ComponentSlot,
  IComputedProps,
  getComponentRuleProperties,
  Software,
  DifferenceSet,
  BpPlaneNoPipeSize,
  BpPlane,
} from "../ComponentProperties";
import {
  PropertyType,
  PermissionLevels,
} from "../../Repository/PropertySchema";
import { ComponentType } from "../Component";
import { FileData } from "../FileData";
import { RulePropertySchema } from "../Rule";
import { GrantedGroupPermission, GrantedUserPermission } from "../UserGroup";
import { USER_ID_SESSION_ATTRIBUTE_NAME } from "../../../Pages/Login/AuthenticationService";
import { BaseComponent } from "./BaseComponent";
import { ComputedPower } from "../Subcomponents/ComputedPower";

export class BackplaneProps extends BaseComponent implements IComponentProps {
  @observable public form: string = "";
  @observable public slots: ModuleSlot[] = [];
  @observable public profile: string = "";
  @observable public topologyType: string = "";
  @observable public dataPlanes: BpPlane[] = [];
  @observable public controlPlanes: BpPlane[] = [];
  @observable public expansionPlanes: BpPlane[] = [];
  @observable public clockConnections: BpPlaneNoPipeSize[] = [];
  @observable public ipmbConnections: BpPlaneNoPipeSize[] = [];
  @observable public utilityPlanes: BpPlaneNoPipeSize[] = [];
  @observable public clock: string = "";
  @observable public ports: ComponentPort[] = [];

  constructor(source?: BackplaneProps) {
    super();
    if (source) {
      Object.assign(this, source);
      this.ports = source.ports.map((port) => new ComponentPort(port));
      this.slots = source.slots.map((slot) => new ModuleSlot(slot));
      this.software = source.software.map((software) => new Software(software));
      this.groupAccess = source.groupAccess.map(
        (access) => new GrantedGroupPermission(access)
      );
      this.userAccess = source.userAccess.map(
        (access) => new GrantedUserPermission(access)
      );
      this.documentation = source.documentation.map(
        (documentation) => new FileData(documentation)
      );
      this.dataPlanes = source.dataPlanes.map((plane) => new BpPlane(plane));
      this.controlPlanes = source.controlPlanes.map(
        (plane) => new BpPlane(plane)
      );
      this.expansionPlanes = source.expansionPlanes.map(
        (plane) => new BpPlane(plane)
      );
      this.clockConnections = source.clockConnections.map(
        (plane) => new BpPlaneNoPipeSize(plane)
      );
      this.ipmbConnections = source.ipmbConnections.map(
        (plane) => new BpPlaneNoPipeSize(plane)
      );
      this.utilityPlanes = source.utilityPlanes.map(
        (plane) => new BpPlaneNoPipeSize(plane)
      );
    }
  }

  public clone(keepId?: boolean): BackplaneProps {
    var clone = new BackplaneProps(this);
    if (!keepId) {
      clone.id = undefined;
      clone.model = this.model + " (Clone)";
    }
    var currentUserId = sessionStorage.getItem(USER_ID_SESSION_ATTRIBUTE_NAME);
    if (currentUserId) {
      clone.ownerId = currentUserId;
    }
    return clone;
  }

  public containsEmptyRequiredFields(): boolean {
    if (this.model.length <= 0) return true;
    return false;
  }

  public getId(): string | undefined {
    return this.id;
  }
  public getComponentType(): ComponentType {
    return ComponentType.Backplane;
  }
  public getTitle(): string {
    return this.model;
  }
  public getSubtext(): string {
    return this.profile;
  }
  public getSlots(): ComponentSlot[] {
    return this.slots.slice().sort((a, b) => (a.index >= b.index ? 1 : -1));
  }
  public getTopPropertySchema() {
    return BACKPLANE_TOP_PROPERTY_SCHEMA;
  }
  public getPermissionPropertySchema(): PropGroup<BackplaneProps>[] {
    return BACKPLANE_PERMISSION_PROPERTY_SCHEMA;
  }
  public getPropertySchema() {
    return this.buildBackplaneSchema();
  }
  public getRepositoryProperties(): PropertyDescriptor<BackplaneProps>[] {
    return [
      new PropertyDescriptor("profile", ""),
      new PropertyDescriptor("vendor", "Vendor"),
      new PropertyDescriptor("form", "Form"),
      new PropertyDescriptor("slots", "Slots", PropertyType.Count),
    ];
  }
  public addToList<BackplaneProps>(propName: keyof BackplaneProps) {
    if (propName === "slots") {
      this.slots.push(new ModuleSlot({ index: this.slots.length + 1 }));
    } else if (propName === "ports") {
      this.ports.push(new ComponentPort({ index: this.ports.length + 1 }));
    } else if (propName === "software") {
      this.software.push(new Software({}));
    } else if (propName === "groupAccess") {
      this.groupAccess.push(new GrantedGroupPermission({}));
    } else if (propName === "userAccess") {
      this.userAccess.push(new GrantedUserPermission({}));
    } else if (propName === "dataPlanes") {
      this.dataPlanes.push(new BpPlane({}));
    } else if (propName === "controlPlanes") {
      this.controlPlanes.push(new BpPlane({}));
    } else if (propName === "expansionPlanes") {
      this.expansionPlanes.push(new BpPlane({}));
    } else if (propName === "documentation") {
      this.documentation.push(new FileData());
    } else if (propName === "clockConnections") {
      this.clockConnections.push(new BpPlaneNoPipeSize({}));
    } else if (propName === "ipmbConnections") {
      this.ipmbConnections.push(new BpPlaneNoPipeSize({}));
    } else if (propName === "utilityPlanes") {
      this.utilityPlanes.push(new BpPlaneNoPipeSize({}));
    }
  }
  public removeFromList<BackplaneProps>(
    propName: keyof BackplaneProps,
    index: number
  ) {
    if (propName === "slots") {
      this.slots.splice(index, 1);
    } else if (propName === "ports") {
      this.ports.splice(index, 1);
    } else if (propName === "software") {
      this.software.splice(index, 1);
    } else if (propName === "groupAccess") {
      this.groupAccess.splice(index, 1);
    } else if (propName === "userAccess") {
      this.userAccess.splice(index, 1);
    } else if (propName === "dataPlanes") {
      this.dataPlanes.splice(index, 1);
    } else if (propName === "controlPlanes") {
      this.controlPlanes.splice(index, 1);
    } else if (propName === "expansionPlanes") {
      this.expansionPlanes.splice(index, 1);
    } else if (propName === "documentation") {
      this.documentation.splice(index, 1);
    } else if (propName === "clockConnections") {
      this.clockConnections.splice(index, 1);
    } else if (propName === "ipmbConnections") {
      this.ipmbConnections.splice(index, 1);
    } else if (propName === "utilityPlanes") {
      this.utilityPlanes.splice(index, 1);
    }
  }
  public asImplementation(): BackplaneProps {
    return this;
  }

  public static getRulePropertySchema(): RulePropertySchema[] {
    const properties = getComponentRuleProperties(
      BACKPLANE_PROPERTY_LIST as PropGroup<IComponentProps>[]
    );
    properties.push.apply(properties, [
      {
        name: "dataPlaneCommsMismatch",
        display: "Data Plane Comms Mismatch",
        computed: true,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: "controlPlaneCommsMismatch",
        display: "Control Plane Comms Mismatch",
        computed: true,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: "expansionPlaneCommsMismatch",
        display: "Expansion Plane Comms Mismatch",
        computed: true,
        listCount: false,
        type: PropertyType.Text,
      },
      {
        name: "commonDataPlaneVersion",
        display: "Common Data Plane Version",
        computed: true,
        listCount: false,
        type: PropertyType.Flag,
      },
      {
        name: "commonControlPlaneVersion",
        display: "Common Control Plane Version",
        computed: true,
        listCount: false,
        type: PropertyType.Flag,
      },
      {
        name: "commonExpansionPlaneVersion",
        display: "Common Expansion Plane Version",
        computed: true,
        listCount: false,
        type: PropertyType.Flag,
      },
    ]);
    properties.push.apply(
      properties,
      ComponentPort.getRulePropertySchema("ports", "Port")
    );
    properties.push.apply(
      properties,
      Software.getRulePropertySchema("software", "Software")
    );
    properties.push.apply(
      properties,
      ModuleSlot.getRulePropertySchema("slots", "Module Slot")
    );
    properties.push.apply(
      properties,
      BpPlane.getRulePropertySchema("dataPlanes", "Data Plane")
    );
    properties.push.apply(
      properties,
      BpPlane.getRulePropertySchema("controlPlanes", "Control Plane")
    );
    properties.push.apply(
      properties,
      BpPlane.getRulePropertySchema("expansionPlanes", "Expansion Plane")
    );
    properties.push.apply(
      properties,
      BpPlaneNoPipeSize.getRulePropertySchema("clockConnections", "Clocks")
    );
    properties.push.apply(
      properties,
      BpPlaneNoPipeSize.getRulePropertySchema("ipmbConnections", "IPMB")
    );
    properties.push.apply(
      properties,
      BpPlaneNoPipeSize.getRulePropertySchema("utilityPlanes", "Utility Plane")
    );

    return properties.sort((a, b) => a.display.localeCompare(b.display));
  }

  public compareTo(other: BackplaneProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();
    diffs.addDiffs(this.getEnvironmentalDifferences(other));
    diffs.addDiffs(this.getVendorDifferences(other));
    diffs.addDiffs(this.getPhysicalDifferences(other));
    diffs.addDiffs(this.getHostDifferences(other));
    diffs.addDiffs(this.getSystemDifferences(other));

    if (this.form !== other.form) diffs.addDiff("Profile", "form");
    if (this.profile !== other.profile) diffs.addDiff("Profile", "profile");
    if (this.topologyType !== other.topologyType)
      diffs.addDiff("Topology Type", "topologyType");

    diffs.compareProps("Data Planes", this.dataPlanes, other.dataPlanes);
    diffs.compareProps(
      "Control Planes",
      this.controlPlanes,
      other.controlPlanes
    );
    diffs.compareProps(
      "Expansion Planes",
      this.expansionPlanes,
      other.expansionPlanes
    );
    diffs.compareProps("Clocks", this.clockConnections, other.clockConnections);
    diffs.compareProps("IPMB", this.ipmbConnections, other.ipmbConnections);
    diffs.compareProps(
      "Utility Planes",
      this.utilityPlanes,
      other.utilityPlanes
    );

    diffs.compareProps("Slots", this.slots, other.slots);
    diffs.compareProps("Ports", this.ports, other.ports);

    diffs.compareProps("SW / FW / BSP Support", this.software, other.software);

    if (this.clock !== other.clock) diffs.addDiff("Other", "clock");

    return diffs;
  }

  public getOwnerId(): string {
    return this.ownerId;
  }

  public getWorldAccess(): PermissionLevels {
    return this.worldAccess;
  }

  public getGroupAccess(): GrantedGroupPermission[] {
    return this.groupAccess;
  }

  public getUserAccess(): GrantedUserPermission[] {
    return this.userAccess;
  }

  buildBackplaneSchema = () => {
    let backplaneSchema: PropGroup<BackplaneProps>[] = [
      this.getVendorPropGroup(),
      new PropGroup("Profile", [
        new PropertyDescriptor(
          "form",
          "Form",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["3U", "6U", "Hybrid"]
        ),
        new PropertyDescriptor("profile", "Profile"),
        new PropertyDescriptor(
          "topologyType",
          "Topology Type",
          PropertyType.SingleSelect,
          undefined,
          undefined,
          ["Centralized", "Distributed", "Hybrid"]
        ),
      ]),
      new PropGroup("Slots", [
        new PropertyDescriptor("slots", "Slots", PropertyType.List),
      ]),
      new PropGroup("Data Plane", [
        new PropertyDescriptor("dataPlanes", "Data Planes", PropertyType.List),
      ]),
      new PropGroup("Control Plane", [
        new PropertyDescriptor(
          "controlPlanes",
          "Control Planes",
          PropertyType.List
        ),
      ]),
      new PropGroup("Expansion Plane", [
        new PropertyDescriptor(
          "expansionPlanes",
          "Expansion Planes",
          PropertyType.List
        ),
      ]),
      new PropGroup("Clocks", [
        new PropertyDescriptor("clockConnections", "Clocks", PropertyType.List),
      ]),
      new PropGroup("IPMB", [
        new PropertyDescriptor("ipmbConnections", "IPMB", PropertyType.List),
      ]),
      new PropGroup("Utility Plane", [
        new PropertyDescriptor(
          "utilityPlanes",
          "Utility Plane",
          PropertyType.List
        ),
      ]),
      new PropGroup("Ports", [
        new PropertyDescriptor("ports", "Ports", PropertyType.List),
      ]),
      this.getEnvironmentalPropGroup(),
      this.getPhysicalPropGroup(),
      this.getSystemPropGroup(),
      new PropGroup("SW / FW / BSP Support", [
        new PropertyDescriptor(
          "software",
          "SW / FW / BSP Support",
          PropertyType.List
        ),
      ]),
      new PropGroup("Other", [new PropertyDescriptor("clock", "Clock")]),
      this.getHostPropGroup(),
      new PropGroup("Documentation", [
        new PropertyDescriptor(
          "documentation",
          "Documentation",
          PropertyType.CompactList
        ),
      ]),
      this.getPermissionPropGroup(),
    ];

    return backplaneSchema;
  };
}

export class BackplaneComputeds extends ComputedPower
  implements IComputedProps {
  public dataPlaneProtocolMismatch?: string;
  public commonControlPlaneType?: boolean;
  public commonExpansionPlaneType?: boolean;
  public commonDataPlaneVersion?: boolean;
  public commonControlPlaneVersion?: boolean;
  public commonExpansionPlaneVersion?: boolean;

  constructor(source?: BackplaneComputeds) {
    super();
    if (source) {
      Object.assign(this, source);
    }
  }
}

const BACKPLANE_TOP_PROPERTY_SCHEMA: PropGroup<BackplaneProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("form", "Form"),
    new PropertyDescriptor("profile", "Profile"),
    new PropertyDescriptor("topologyType", "Topology Type"),
    new PropertyDescriptor("slots", "Slots", PropertyType.Count),
    new PropertyDescriptor("dataPlanes", "Data Planes", PropertyType.List),
    new PropertyDescriptor(
      "controlPlanes",
      "Control Planes",
      PropertyType.List
    ),
    new PropertyDescriptor(
      "expansionPlanes",
      "Expansion Planes",
      PropertyType.List
    ),
    // new PropertyDescriptor("dataPlaneGbaudRateInGBd", "Data GBAUD Rate", PropertyType.Number, "GBd"),
    // new PropertyDescriptor("controlPlaneGbaudRateInGBd", "Control GBAUD Rate", PropertyType.Number, "GBd"),
    // new PropertyDescriptor("expansionPlaneGbaudRateInGBd", "Expansion GBAUD Rate", PropertyType.Number, "GBd"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
  ]),
];

const BACKPLANE_PERMISSION_PROPERTY_SCHEMA: PropGroup<BackplaneProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor(
      "ownerId",
      "Owner",
      PropertyType.SingleSelectById,
      undefined,
      undefined,
      undefined,
      undefined,
      "users"
    ),
    new PropertyDescriptor("worldAccess", "World"),
    new PropertyDescriptor("groupAccess", "Groups", PropertyType.CompactList),
    new PropertyDescriptor("userAccess", "Users", PropertyType.CompactList),
  ]),
];

const BACKPLANE_PROPERTY_LIST: PropGroup<BackplaneProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("partNumber", "Part Number"),
    new PropertyDescriptor("seriesNumber", "Series Number"),
    new PropertyDescriptor("revisionNumber", "Revision Number"),
    new PropertyDescriptor(
      "manufacturedDate",
      "Manufactured Date",
      PropertyType.Date
    ),
    new PropertyDescriptor(
      "form",
      "Form",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["3U", "6U", "Hybrid"]
    ),
    new PropertyDescriptor("profile", "Profile"),
    new PropertyDescriptor(
      "topologyType",
      "Topology Type",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Centralized", "Distributed", "Hybrid"]
    ),
    new PropertyDescriptor("dataPlanes", "Data Planes", PropertyType.List),
    new PropertyDescriptor(
      "controlPlanes",
      "Control Planes",
      PropertyType.List
    ),
    new PropertyDescriptor(
      "expansionPlanes",
      "Expansion Planes",
      PropertyType.List
    ),
    new PropertyDescriptor(
      "coolingType",
      "Cooling Type",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Air", "Conduction", "AFT", "LFT", "Air Flow-By"]
    ),
    new PropertyDescriptor(
      "operatingTemperatureMinInCelsius",
      "Min Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "operatingTemperatureMaxInCelsius",
      "Max Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMinInCelsius",
      "Min Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMaxInCelsius",
      "Max Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "temperatureCycling",
      "Temperature Cycling",
      PropertyType.Flag
    ),
    new PropertyDescriptor("vibration", "Vibration"),
    new PropertyDescriptor("shock", "Shock"),
    new PropertyDescriptor("humidity", "Humidity"),
    new PropertyDescriptor(
      "operatingAltitudeMinInFeet",
      "Min Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "operatingAltitudeMaxInFeet",
      "Max Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "rapidDecompression",
      "Rapid Decompression",
      PropertyType.Flag
    ),
    new PropertyDescriptor("fungusResistance", "Fungus Resistance"),
    new PropertyDescriptor(
      "electrostaticDischargeResistance",
      "Electrostatic Discharge Resistance"
    ),
    new PropertyDescriptor("corrosionResistance", "Corrosion Resistance"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor(
      "conformalCoating",
      "Conformal Coating",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Acrylic", "Parylene", "Polyurethane", "PTFE", "Silicone"]
    ),
    new PropertyDescriptor(
      "lengthInInches",
      "Length",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "widthInInches",
      "Width",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "weightInGrams",
      "Weight",
      PropertyType.Number,
      "Grams"
    ),
    new PropertyDescriptor(
      "ipmcImplemented",
      "IPMC Implemented",
      PropertyType.Flag
    ),
    new PropertyDescriptor("tier1Controller", "Tier 1 Controller"),
    new PropertyDescriptor("tier2Controller", "Tier 2 Controller"),
    new PropertyDescriptor(
      "chassisManager",
      "Chassis Manager",
      PropertyType.Flag
    ),
    new PropertyDescriptor("clock", "Clock"),
    new PropertyDescriptor("software", "Software Support", PropertyType.List),
    new PropertyDescriptor("hostConformancy", "Conformant", PropertyType.Flag),
    new PropertyDescriptor("hostConformancyLevel", "Level of Conformance"),
    new PropertyDescriptor("tier3", "Tier 3"),
    new PropertyDescriptor("slots", "Module Slots", PropertyType.List),
  ]),
];
