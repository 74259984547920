import { Get, PostFile } from "./Http";
import { FileData } from "../Data/Schema/FileData";

export function getComponentImageData(
  componentId: string
): Promise<FileData[]> {
  return Get<FileData[]>(
    `/api/controllers/repository/componentImageData?componentId=${componentId}`
  );
}

export function getImageUrl(componentId: string): string {
  return `/api/controllers/repository/componentImagePng?imageId=${componentId}`;
}

export function getDocumentUrl(fileId: string): string {
  return `/api/controllers/repository/componentDocument?fileId=${fileId}`;
}

export async function uploadFile(file: File): Promise<string> {
  const fileId = await PostFile<string>(
    `/api/controllers/repository/files/upload`,
    file
  );
  return fileId;
}
