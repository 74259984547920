import * as React from "react";

import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import GridLayout from "react-grid-layout";
import { HhitsButton } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import { Select, MenuItem, Tooltip } from "@material-ui/core";
import { getDropdownOptions } from "../../API/Repository";

export interface ITopology {
  name: string;
  label: string;
  width: number;
  height: number;
  rowHeight: number;
  x: number;
  y: number;
  layout: string;
  type: string;
  columns: string[];
  signals: ITopologySignal[];
  pipes: ITopologyPipe[];
  children: ITopology[];
  alias: string;
}

interface ITopologyPipe {
  type: string;
  label: string;
}

interface ITopologySignal {
  name: string;
  goesToExternalIO: boolean;
}

const SLOT_NUM_COLUMNS = 12;
const PATTERN_LAYOUT_KEY = "pattern";

export interface TopologyCompare {
  source: ITopology;
  target?: ITopology;
  faded?: boolean;
  showDownloadBtn?: boolean;
  tableEditable?: boolean;
  onChange?(): void;
}

@observer
export class Topology extends React.Component<TopologyCompare> {
  @observable private activeTopology: ITopology = this.props.source;
  @observable private activeTopology2?: ITopology = this.props.target;
  @observable private signalNames: string[] = [];
  @observable private aperturePatterns: string[] = [];
  @observable private selectedCell: HTMLDivElement | undefined = undefined;

  @computed get apertureMenuItems(): JSX.Element[] {
    return this.aperturePatterns.map((pattern, index) => {
      return (
        <MenuItem
          className={"aperturePatternOption"}
          key={index}
          value={pattern}
        >
          {pattern}
        </MenuItem>
      );
    });
  }

  @computed get signalNameMenuItems(): JSX.Element[] {
    return this.signalNames.map((signalName, index) => {
      return (
        <MenuItem className={"signalNameOption"} key={index} value={signalName}>
          {signalName}
        </MenuItem>
      );
    });
  }

  public componentDidMount() {
    getDropdownOptions("signal-names").then((value) => {
      this.signalNames = value.map((s) => s.toString()).sort();
    });

    getDropdownOptions("aperture-pattern").then((value) => {
      this.aperturePatterns = value.map((s) => s.toString()).sort();
    });
  }

  public render() {
    return (
      <div>
        <div id="topology-div">
          {this.activeTopology.layout === "slotprofile"
            ? this.renderTopologyRoot(this.activeTopology, this.activeTopology2)
            : this.renderTopologyTable(this.activeTopology)}
        </div>

        {this.props.showDownloadBtn ? (
          <span
            className="ExportImage"
            onClick={this.exportImage}
            aria-hidden="true"
          >
            <HhitsButton primary>
              <FontAwesomeIcon icon="download" />
              Download Image
            </HhitsButton>
          </span>
        ) : null}
      </div>
    );
  }

  private exportImage = () => {
    var element = document.getElementById("topology-div");
    if (element) {
      htmlToImage.toBlob(element).then(function (blob) {
        if (blob) saveAs(blob, "topology.png");
      });
    }
  };

  isPlaceHolderTopology = (topology: ITopology) => {
    if (
      topology.children.length === 1 &&
      topology.children[0].label === "Profile\nData\nNot\nFound"
    ) {
      return true;
    }
    return false;
  };

  private renderTopologyRoot(
    topologyRoot: ITopology,
    topologyRoot2?: ITopology
  ) {
    // TODO: Names for compared grids should not be hardcoded
    return (
      <div className={`Topology ${this.props.faded ? "Faded" : ""}`}>
        {this.renderRoot(topologyRoot, topologyRoot2 && "Slot")}

        {topologyRoot2 && this.renderRoot(topologyRoot2, "Module")}
      </div>
    );
  }

  private renderRoot(topologyRoot: ITopology, title?: string) {
    const columnWidth = topologyRoot.width / SLOT_NUM_COLUMNS;

    return (
      <div className="TopologyRoot">
        <div className="GridTitle">{title}</div>
        <GridLayout
          className="layout"
          layout={this.getSlotLayout(topologyRoot.children)}
          cols={SLOT_NUM_COLUMNS}
          rowHeight={
            this.isPlaceHolderTopology(topologyRoot)
              ? 1.5
              : topologyRoot.rowHeight
          }
          margin={[0, 0]}
          width={topologyRoot.width}
          style={{ width: topologyRoot.width }}
        >
          {topologyRoot.children.map((section) =>
            this.renderTopologySection(
              section,
              columnWidth,
              topologyRoot.rowHeight
            )
          )}
        </GridLayout>
      </div>
    );
  }

  private renderTopologyTable(topologyData: ITopology) {
    return (
      <div className="Topology TopoTable">
        <div className="TableTitleBar">
          <div
            onClick={this.setActiveTopology.bind(this, this.props.source)}
            className="linkBack"
            key={"linkback"}
          >
            {"< Back"}
          </div>
          <div className="TableTitle">
            {topologyData.name.toLocaleUpperCase()}
          </div>
        </div>
        <GridLayout
          className="layout"
          layout={this.getTableLayout(topologyData)}
          cols={topologyData.columns.length}
          rowHeight={20}
          margin={[2, 2]}
          width={920}
          style={{ width: 920 }}
        >
          {topologyData.columns.map((item, i) =>
            this.renderTableHeaderCell(item, i, topologyData.type)
          )}
          {topologyData.signals.map((item, i) =>
            this.renderTableCell(item, i, topologyData.type)
          )}
        </GridLayout>
        {topologyData.type !== PATTERN_LAYOUT_KEY && (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>
            <FontAwesomeIcon icon="plug" className="selectedIcon" />
            <strong>- External IO</strong>
          </div>
        )}
      </div>
    );
  }

  private renderTopologySection(
    section: ITopology,
    columnWidth: number,
    rowHeight: number
  ) {
    return (
      <div
        className={
          "slot slot-" + section.type + (section.signals ? " link-pointer" : "")
        }
        onClick={this.clickTopology.bind(this, section)}
        key={section.name}
      >
        <label
          className={"slot-label" + (section.signals ? " link-pointer" : "")}
        >
          <span>
            {section.label.split("\n").map((item, i) => (
              <p key={i}>{item}</p>
            ))}
            {section.type === "pattern" ? (
              <p>{section.signals[0].name.substring(0, 1)}</p>
            ) : (
              ""
            )}
          </span>
        </label>

        <GridLayout
          className="layout"
          layout={this.getSlotLayout(section.children)}
          cols={SLOT_NUM_COLUMNS}
          rowHeight={rowHeight}
          margin={[0, 0]}
          width={section.width * columnWidth - 0}
        >
          {section.children
            ? section.children.map((subsection) =>
                this.renderSubsection(subsection, columnWidth, rowHeight)
              )
            : null}
        </GridLayout>
      </div>
    );
  }

  private renderSubsection(
    subsection: ITopology,
    columnWidth: number,
    rowHeight: number
  ) {
    return (
      <div
        className={"slot-inner slot-" + subsection.type}
        key={subsection.name}
      >
        <label className={"slot-label"}>
          <span>
            {subsection.label.split("\n").map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </span>
        </label>

        <GridLayout
          className="layout"
          layout={this.getPipesLayout(subsection)}
          cols={SLOT_NUM_COLUMNS}
          rowHeight={rowHeight}
          margin={[0, 0]}
          width={subsection.width * columnWidth - 0}
        >
          {subsection.pipes
            ? subsection.pipes.map((pipe, pipeIndex) =>
                this.renderPipe(subsection, pipe, pipeIndex)
              )
            : null}
        </GridLayout>
      </div>
    );
  }

  private renderPipe(
    subsection: ITopology,
    pipe: ITopologyPipe,
    pipeIndex: number
  ) {
    return (
      <div
        className={
          "slot-pipe slot-" +
          pipe.type +
          (subsection.signals ? " link-pointer" : "")
        }
        onClick={this.clickTopology.bind(this, subsection)}
        key={subsection.name + "-" + pipe.type + "-" + pipeIndex}
      >
        <label className={"slot-label"}>
          <span>
            {pipe.label.split("\n").map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </span>
        </label>
      </div>
    );
  }

  private renderTableHeaderCell = (
    value: string,
    index: number,
    sectionType: string
  ) => {
    return sectionType === PATTERN_LAYOUT_KEY ? (
      <div key={"sigcol-" + index}>{""}</div>
    ) : index === 0 ? (
      <div className="signals signals-header" key={"sigcol-" + index} />
    ) : (
      <div className="signals signals-header" key={"sigcol-" + index}>
        {value}
      </div>
    );
  };

  private isFirstColumn(idx: number) {
    return idx % this.activeTopology.columns.length === 0;
  }

  private renderTableCell = (
    signal: ITopologySignal,
    index: number,
    sectionType: string
  ) => {
    const toggleExternalIO = () => {
      this.props.onChange && this.props.onChange();
      this.activeTopology.signals[index].goesToExternalIO = !this.activeTopology
        .signals[index].goesToExternalIO;
    };

    return this.props.tableEditable === false ? (
      sectionType === PATTERN_LAYOUT_KEY ? (
        //read only aperture pattern
        <div className={"patternDropdown"} key={"sigval-" + index}>
          Aperture Pattern: {signal.name}
        </div>
      ) : (
        //read only table
        <div
          className={
            "signals signals-value sig-" + this.fixSignalClassName(signal.name)
          }
          key={"sigval-" + index}
        >
          <div className="read-only-grid-container BasicContainer">
            <div className="grid-item">
              {this.activeTopology.signals[index].goesToExternalIO ? (
                <FontAwesomeIcon icon="plug" className="selectedIcon" />
              ) : null}
            </div>
            <div className="grid-item BasicItem">
              <p className={"BasicItemText"}>{signal.name}</p>
            </div>
          </div>
        </div>
      )
    ) : sectionType === PATTERN_LAYOUT_KEY ? (
      //editable aperture pattern select
      <div className={""} key={"sigval-" + index}>
        Aperture Pattern:{" "}
        <Select
          id={"sigval-" + index}
          onChange={(event) => {
            this.props.onChange && this.props.onChange();
            this.activeTopology.signals[index].name = event.target
              .value as string;
          }}
          fullWidth={true}
          value={signal.name}
          disableUnderline
          className={"SelectItem"}
        >
          {this.apertureMenuItems}
        </Select>
      </div>
    ) : (
      //editable table
      <div
        className={
          "signals signals-value sig-" + this.fixSignalClassName(signal.name)
        }
        key={"sigval-" + index}
      >
        {this.isFirstColumn(index) ? (
          signal.name
        ) : (
          <div className="grid-container SelectContainer">
            {this.activeTopology.signals[index].goesToExternalIO ? (
              /* The below tooltip implementation might seem odd, but it was the only way
              I could make the events work properly on chrome. - Will G */
              <Tooltip title="Click to remove external I/O" arrow>
                <div className="grid-item" onClick={toggleExternalIO}>
                  <FontAwesomeIcon icon="plug" className="selectedIcon" />
                </div>
              </Tooltip>
            ) : (
              <div className="grid-item" onClick={toggleExternalIO}>
                <Tooltip title="Click to mark external I/O" arrow>
                  <img
                    src={require("../../Images/plug-outline.png")}
                    alt=""
                    className="outline-plug"
                  />
                </Tooltip>
              </div>
            )}
            <Select
              id={"sigval-" + index}
              className="grid-item SelectItem"
              onChange={(event) => {
                if (this.selectedCell) {
                  this.selectedCell.className =
                    "MuiInputBase-root MuiInput-root grid-Item SelectItem HOVERED";
                }
                this.props.onChange && this.props.onChange();
                this.activeTopology.signals[index].name = event.target
                  .value as string;
              }}
              value={signal.name}
              disableUnderline
              onMouseOver={(event) => this.setSelectHovered(event)}
              onMouseEnter={(event) => this.setSelectHovered(event)}
              onMouseLeave={(event) => this.setSelectUnhovered(event)}
              onMouseOut={(event) => this.setSelectUnhovered(event)}
            >
              {this.signalNameMenuItems}
            </Select>
          </div>
        )}
      </div>
    );
  };

  // This is a workaround for firefox not listening to onhover css overwrites. Remove when unneeded
  setSelectHovered(event: React.MouseEvent<HTMLDivElement>) {
    this.selectedCell = event.currentTarget;
    event.currentTarget.className =
      "MuiInputBase-root MuiInput-root grid-Item SelectItem HOVERED";
  }

  setSelectUnhovered(event: React.MouseEvent<HTMLDivElement>) {
    this.selectedCell = event.currentTarget;
    event.currentTarget.className =
      "MuiInputBase-root MuiInput-root grid-item SelectItem";
  }

  private getSlotLayout(sections: ITopology[]) {
    const layout: GridLayout.Layout[] = [];
    if (sections) {
      sections.map((section, index) => {
        layout[index] = {
          i: section.name,
          x: section.x,
          y: section.y,
          w: section.width,
          h: section.height,
          static: true,
        };
        return 0;
      });
    }

    return layout;
  }
  private getPipesLayout(section: ITopology) {
    const layout: GridLayout.Layout[] = [];
    if (section.pipes) {
      const height = section.height / section.pipes.length;

      section.pipes.map((pipe, index) => {
        layout[index] = {
          i: section.name + "-" + pipe.type + "-" + index,
          x: 0,
          y: index * height,
          w: SLOT_NUM_COLUMNS,
          h: height,
          static: true,
        };
        return 0;
      });
    }

    return layout;
  }

  private getTableLayout(topology: ITopology) {
    const layout: GridLayout.Layout[] = [];
    const numCols = topology.columns.length;

    topology.columns.map((val, index) => {
      layout[index] = {
        i: "sigcol-" + index,
        x: index,
        y: 0,
        w: 1,
        h: 1,
        static: true,
      };
      return 0;
    });

    topology.signals.map((val, index) => {
      layout[index + numCols] = {
        i: "sigval-" + index,
        x: index % numCols,
        y: Math.floor(index / numCols) + 1,
        w: 1,
        h: 1,
        static: true,
      };
      return 0;
    });

    return layout;
  }

  private clickTopology(topology: ITopology) {
    if (
      topology.columns &&
      topology.columns.length > 0 &&
      topology.signals &&
      topology.signals.length > 0
    ) {
      this.setActiveTopology(topology);
    }
  }

  private setActiveTopology = (topology: ITopology) => {
    this.activeTopology = topology;
  };

  private fixSignalClassName(className: string) {
    className = className.replace("*", "");
    className = className.replace("+", "");
    className = className.replace("-", "").replace("-", "");
    className = className.replace(".", "");

    return className;
  }
}
