import { EnvironmentalProps } from "../Subcomponents/Environment.types";
import { VendorProps } from "../Subcomponents/Vendor.types";
import { observable } from "mobx";
import {
  DifferenceSet,
  PropGroup,
  PropertyDescriptor,
  Software,
} from "../ComponentProperties";
import {
  PropertyType,
  PermissionLevels,
  PERMISSION_LEVEL_STRINGS,
} from "../../Repository/PropertySchema";
import { PhysicalProps } from "../Subcomponents/Physical.types";
import { SystemManagementProps } from "../Subcomponents/SystemManagement.types";
import { GrantedGroupPermission, GrantedUserPermission } from "../UserGroup";
import { HostProps } from "../Subcomponents/Host.types";
import { PowerProps } from "../Subcomponents/Power.types";

import { FileData } from "../FileData";
import { ITopology } from "../../../Components/Topology/Topology";
import { PermissionsProps } from "../Subcomponents/Permissions.types";
import { compareStringFields } from "../../../Utilities/String";

export interface BaseComponentProps
  extends EnvironmentalProps,
    VendorProps,
    PhysicalProps,
    SystemManagementProps,
    HostProps,
    PermissionsProps,
    PowerProps {}

export class BaseComponent implements BaseComponentProps {
  // Common information
  @observable id?: string;
  @observable public software: Software[] = [];
  @observable public imageData: FileData[] = [];
  @observable public topology: ITopology[] = [];
  @observable public editable: boolean = false;
  @observable public deletable: boolean = false;
  @observable public documentation: FileData[] = [];

  // Environment
  @observable public coolingType: string = "";
  @observable public operatingTemperatureMinInCelsius?: number;
  @observable public operatingTemperatureMaxInCelsius?: number;
  @observable public storageTemperatureMinInCelsius?: number;
  @observable public storageTemperatureMaxInCelsius?: number;
  @observable public temperatureCycling?: boolean;
  @observable public vibration: string = "";
  @observable public shock: string = "";
  @observable public humidity: string = "";
  @observable public operatingAltitudeMinInFeet?: number;
  @observable public operatingAltitudeMaxInFeet?: number;
  @observable public rapidDecompression?: boolean;
  @observable public fungusResistance: string = "";
  @observable public electrostaticDischargeResistance: string = "";
  @observable public corrosionResistance: string = "";
  @observable public ruggedization: string = "";
  @observable public conformalCoating: string = "";
  @observable public mtbf?: number; //hours

  getEnvironmentalDifferences(other: EnvironmentalProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();

    if (this.coolingType !== other.coolingType)
      diffs.addDiff("Environmental", "coolingType");
    if (
      this.operatingTemperatureMinInCelsius !==
      other.operatingTemperatureMinInCelsius
    )
      diffs.addDiff("Environmental", "operatingTemperatureMinInCelsius");
    if (
      this.operatingTemperatureMaxInCelsius !==
      other.operatingTemperatureMaxInCelsius
    )
      diffs.addDiff("Environmental", "operatingTemperatureMaxInCelsius");
    if (
      this.storageTemperatureMinInCelsius !==
      other.storageTemperatureMinInCelsius
    )
      diffs.addDiff("Environmental", "storageTemperatureMinInCelsius");
    if (
      this.storageTemperatureMaxInCelsius !==
      other.storageTemperatureMaxInCelsius
    )
      diffs.addDiff("Environmental", "storageTemperatureMaxInCelsius");
    if (this.temperatureCycling !== other.temperatureCycling)
      diffs.addDiff("Environmental", "temperatureCycling");
    if (this.vibration !== other.vibration)
      diffs.addDiff("Environmental", "vibration");
    if (this.shock !== other.shock) diffs.addDiff("Environmental", "shock");
    if (this.humidity !== other.humidity)
      diffs.addDiff("Environmental", "humidity");
    if (this.operatingAltitudeMinInFeet !== other.operatingAltitudeMinInFeet)
      diffs.addDiff("Environmental", "operatingAltitudeMinInFeet");
    if (this.operatingAltitudeMaxInFeet !== other.operatingAltitudeMaxInFeet)
      diffs.addDiff("Environmental", "operatingAltitudeMaxInFeet");
    if (this.rapidDecompression !== other.rapidDecompression)
      diffs.addDiff("Environmental", "rapidDecompression");
    if (this.fungusResistance !== other.fungusResistance)
      diffs.addDiff("Environmental", "fungusResistance");
    if (
      this.electrostaticDischargeResistance !==
      other.electrostaticDischargeResistance
    )
      diffs.addDiff("Environmental", "electrostaticDischargeResistance");
    if (this.corrosionResistance !== other.corrosionResistance)
      diffs.addDiff("Environmental", "corrosionResistance");
    if (this.ruggedization !== other.ruggedization)
      diffs.addDiff("Environmental", "ruggedization");
    if (this.conformalCoating !== other.conformalCoating)
      diffs.addDiff("Environmental", "conformalCoating");

    return diffs;
  }

  getEnvironmentalPropGroup(): PropGroup<EnvironmentalProps> {
    return new PropGroup("Environmental", [
      new PropertyDescriptor(
        "coolingType",
        "Cooling Type",
        PropertyType.SingleSelect,
        undefined,
        undefined,
        ["Air", "Conduction", "AFT", "LFT", "Air Flow-By"]
      ),
      new PropertyDescriptor(
        "operatingTemperatureMinInCelsius",
        "Min Operating Temp",
        PropertyType.Number,
        "Celsius"
      ),
      new PropertyDescriptor(
        "operatingTemperatureMaxInCelsius",
        "Max Operating Temp",
        PropertyType.Number,
        "Celsius"
      ),
      new PropertyDescriptor(
        "storageTemperatureMinInCelsius",
        "Min Storage Temp",
        PropertyType.Number,
        "Celsius"
      ),
      new PropertyDescriptor(
        "storageTemperatureMaxInCelsius",
        "Max Storage Temp",
        PropertyType.Number,
        "Celsius"
      ),
      new PropertyDescriptor(
        "temperatureCycling",
        "Temperature Cycling",
        PropertyType.Flag
      ),
      new PropertyDescriptor("vibration", "Vibration"),
      new PropertyDescriptor("shock", "Shock"),
      new PropertyDescriptor("humidity", "Humidity"),
      new PropertyDescriptor(
        "operatingAltitudeMinInFeet",
        "Min Operating Altitude",
        PropertyType.Number,
        "Feet"
      ),
      new PropertyDescriptor(
        "operatingAltitudeMaxInFeet",
        "Max Operating Altitude",
        PropertyType.Number,
        "Feet"
      ),
      new PropertyDescriptor(
        "rapidDecompression",
        "Rapid Decompression",
        PropertyType.Flag
      ),
      new PropertyDescriptor("fungusResistance", "Fungus Resistance"),
      new PropertyDescriptor(
        "electrostaticDischargeResistance",
        "Electrostatic Discharge Resistance"
      ),
      new PropertyDescriptor("corrosionResistance", "Corrosion Resistance"),
      new PropertyDescriptor("ruggedization", "Ruggedization"),
      new PropertyDescriptor("mtbf", "MTBF", PropertyType.Number, "Hours"),
      new PropertyDescriptor(
        "conformalCoating",
        "Conformal Coating",
        PropertyType.SingleSelect,
        undefined,
        undefined,
        ["Acrylic", "Parylene", "Polyurethane", "PTFE", "Silicone"]
      ),
    ]);
  }

  // Vendor
  @observable public vendor: string = "";
  @observable public model: string = "";
  @observable public partNumber: string = "";
  @observable public seriesNumber: string = "";
  @observable public revisionNumber: string = "";
  @observable public manufacturedDate?: Date;
  @observable public costInUSD?: number;

  getVendorDifferences(other: VendorProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();

    if (this.model !== other.model) diffs.addDiff("Vendor", "model");
    if (this.vendor !== other.vendor) diffs.addDiff("Vendor", "vendor");
    if (this.partNumber !== other.partNumber)
      diffs.addDiff("Vendor", "partNumber");
    if (this.seriesNumber !== other.seriesNumber)
      diffs.addDiff("Vendor", "seriesNumber");
    if (this.revisionNumber !== other.revisionNumber)
      diffs.addDiff("Vendor", "revisionNumber");
    if (this.manufacturedDate !== other.manufacturedDate)
      diffs.addDiff("Vendor", "manufacturedDate");

    return diffs;
  }

  getVendorPropGroup(): PropGroup<VendorProps> {
    return new PropGroup("Vendor", [
      new PropertyDescriptor("model", "Name", undefined, undefined, true),
      new PropertyDescriptor("vendor", "Vendor"),
      new PropertyDescriptor("partNumber", "Part Number"),
      new PropertyDescriptor("seriesNumber", "Series Number"),
      new PropertyDescriptor("revisionNumber", "Revision Number"),
      new PropertyDescriptor(
        "manufacturedDate",
        "Manufactured Date",
        PropertyType.Date
      ),
      new PropertyDescriptor("costInUSD", "Cost", PropertyType.Number, "USD"),
    ]);
  }

  // Physical
  @observable public lengthInInches?: number;
  @observable public widthInInches?: number;
  @observable public pitchInInches?: number;
  @observable public weightInGrams?: number;

  getPhysicalDifferences(other: PhysicalProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();
    if (this.lengthInInches !== other.lengthInInches)
      diffs.addDiff("Physical", "lengthInInches");
    if (this.widthInInches !== other.widthInInches)
      diffs.addDiff("Physical", "widthInInches");
    if (this.pitchInInches !== other.pitchInInches)
      diffs.addDiff("Physical", "pitchInInches");
    if (this.weightInGrams !== other.weightInGrams)
      diffs.addDiff("Physical", "weightInGrams");
    return diffs;
  }

  getPhysicalPropGroup(): PropGroup<PhysicalProps> {
    return new PropGroup("Physical", [
      new PropertyDescriptor(
        "lengthInInches",
        "Length",
        PropertyType.Number,
        "Inches"
      ),
      new PropertyDescriptor(
        "widthInInches",
        "Width",
        PropertyType.Number,
        "Inches"
      ),
      new PropertyDescriptor(
        "pitchInInches",
        "Pitch",
        PropertyType.Number,
        "Inches"
      ),
      new PropertyDescriptor(
        "weightInGrams",
        "Weight",
        PropertyType.Number,
        "Grams"
      ),
    ]);
  }

  // System Management
  @observable public ipmcImplemented?: boolean;
  @observable public tier1Controller: string = "";
  @observable public tier2Controller: string = "";
  @observable public chassisManager: boolean = false;

  getSystemDifferences(other: SystemManagementProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();
    if (this.ipmcImplemented !== other.ipmcImplemented)
      diffs.addDiff("System Management", "ipmcImplemented");
    if (this.tier1Controller !== other.tier1Controller)
      diffs.addDiff("System Management", "tier1Controller");
    if (this.tier2Controller !== other.tier2Controller)
      diffs.addDiff("System Management", "tier2Controller");
    if (this.chassisManager !== other.chassisManager)
      diffs.addDiff("System Management", "chassisManager");
    return diffs;
  }
  getSystemPropGroup(): PropGroup<SystemManagementProps> {
    return new PropGroup("System Management", [
      new PropertyDescriptor(
        "ipmcImplemented",
        "IPMC Implemented",
        PropertyType.Flag
      ),
      new PropertyDescriptor(
        "tier1Controller",
        "Tier 1 Controller",
        PropertyType.Flag
      ),
      new PropertyDescriptor(
        "tier2Controller",
        "Tier 2 Controller",
        PropertyType.Flag
      ),
      new PropertyDescriptor(
        "chassisManager",
        "Chassis Manager",
        PropertyType.Flag
      ),
    ]);
  }

  // Host
  @observable public hostConformancy?: boolean;
  @observable public hostConformancyLevel: string = "";
  @observable public tier3: string = "";

  getHostDifferences(other: HostProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();
    if (this.hostConformancy !== other.hostConformancy)
      diffs.addDiff("HOST", "hostConformancy");
    if (this.hostConformancyLevel !== other.hostConformancyLevel)
      diffs.addDiff("HOST", "hostConformancyLevel");
    if (!compareStringFields(this.tier3, other.tier3))
      diffs.addDiff("HOST", "tier3");
    return diffs;
  }

  getHostPropGroup(): PropGroup<HostProps> {
    return new PropGroup("HOST", [
      new PropertyDescriptor(
        "hostConformancy",
        "Conformant",
        PropertyType.Flag
      ),
      new PropertyDescriptor(
        "hostConformancyLevel",
        "Level of Conformance",
        PropertyType.SingleSelect,
        undefined,
        undefined,
        ["Level A", "Level B"]
      ),
      new PropertyDescriptor("tier3", "Tier 3"),
    ]);
  }

  // Permissions
  @observable public ownerId: string = "";
  @observable public worldAccess: PermissionLevels = PermissionLevels.ReadWrite;
  @observable public groupAccess: GrantedGroupPermission[] = [];
  @observable public userAccess: GrantedUserPermission[] = [];

  getPermissionPropGroup(): PropGroup<PermissionsProps> {
    return new PropGroup("Permissions", [
      new PropertyDescriptor(
        "ownerId",
        "Owner",
        PropertyType.SingleSelectById,
        undefined,
        undefined,
        undefined,
        undefined,
        "users"
      ),
      new PropertyDescriptor(
        "worldAccess",
        "World",
        PropertyType.SingleSelect,
        undefined,
        undefined,
        PERMISSION_LEVEL_STRINGS
      ),
      new PropertyDescriptor("groupAccess", "Group", PropertyType.CompactList),
      new PropertyDescriptor("userAccess", "User", PropertyType.CompactList),
    ]);
  }

  // Power
  @observable public power12vInAmps?: number;
  @observable public power5vInAmps?: number;
  @observable public power3_3vInAmps?: number;
  @observable public power3_3vAuxInAmps?: number;
  @observable public power12vAuxPlusInAmps?: number;
  @observable public power12vAuxNegInAmps?: number;
  @observable public powerTotalInWatts?: number;
  @observable public powerCurrent: string = "";
  @observable public powerType: string = "";
  @observable public efficiencyPercent?: number;
  @observable public powerInputType: string = "";
  @observable public powerOutputType: string = "";

  getPowerDifferences(
    other: PowerProps,
    integrated: boolean,
    io: boolean
  ): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();

    if (this.power12vInAmps !== other.power12vInAmps)
      diffs.addDiff("Integrated Power", "power12vInAmps");
    if (this.power5vInAmps !== other.power5vInAmps)
      diffs.addDiff("Integrated Power", "power5vInAmps");
    if (this.power3_3vInAmps !== other.power3_3vInAmps)
      diffs.addDiff("Integrated Power", "power3_3vInAmps");
    if (this.power3_3vAuxInAmps !== other.power3_3vAuxInAmps)
      diffs.addDiff("Integrated Power", "power3_3vAuxInAmps");
    if (this.power12vAuxPlusInAmps !== other.power12vAuxPlusInAmps)
      diffs.addDiff("Integrated Power", "power12vAuxPlusInAmps");
    if (this.power12vAuxNegInAmps !== other.power12vAuxNegInAmps)
      diffs.addDiff("Integrated Power", "power12vAuxNegInAmps");
    if (this.powerTotalInWatts !== other.powerTotalInWatts)
      diffs.addDiff("Integrated Power", "powerTotalInWatts");

    if (integrated) {
      if (this.powerCurrent !== other.powerCurrent)
        diffs.addDiff("Integrated Power", "powerCurrent");
      if (this.powerType !== other.powerType)
        diffs.addDiff("Integrated Power", "powerType");
    }

    if (io) {
      diffs.addDiff("Power", "efficiencyPercent");
      if (this.powerType !== other.powerType)
        diffs.addDiff("Power", "powerType");
      if (this.powerInputType !== other.powerInputType)
        diffs.addDiff("Power", "powerInputType");
      if (this.powerOutputType !== other.powerOutputType)
        diffs.addDiff("Power", "powerOutputType");
    }

    return diffs;
  }

  getPowerPropGroup(integrated: boolean, io: boolean): PropGroup<PowerProps> {
    let powerGroup: PropGroup<PowerProps> = new PropGroup("Integrated Power", [
      new PropertyDescriptor(
        "power12vInAmps",
        "12V",
        PropertyType.Number,
        "Amps"
      ),
      new PropertyDescriptor(
        "power5vInAmps",
        "5V",
        PropertyType.Number,
        "Amps"
      ),
      new PropertyDescriptor(
        "power3_3vInAmps",
        "3.3V",
        PropertyType.Number,
        "Amps"
      ),
      new PropertyDescriptor(
        "power3_3vAuxInAmps",
        "3.3V Aux",
        PropertyType.Number,
        "Amps"
      ),
      new PropertyDescriptor(
        "power12vAuxPlusInAmps",
        "+12V Aux",
        PropertyType.Number,
        "Amps"
      ),
      new PropertyDescriptor(
        "power12vAuxNegInAmps",
        "-12V Aux",
        PropertyType.Number,
        "Amps"
      ),
      new PropertyDescriptor(
        "powerTotalInWatts",
        "Total",
        PropertyType.Number,
        "Watts"
      ),
    ]);

    if (integrated) {
      powerGroup.properties = powerGroup.properties.concat([
        new PropertyDescriptor("powerCurrent", "Current"),
        new PropertyDescriptor("powerType", "Power Type"),
      ]);

      powerGroup.name = "Integrated Power";
    }

    // Should probably catch the powerType dupe, but these cant exist concurrently at the moment.
    if (io) {
      powerGroup.properties = powerGroup.properties.concat([
        new PropertyDescriptor(
          "efficiencyPercent",
          "Efficiency",
          PropertyType.Number,
          "%"
        ),
        new PropertyDescriptor("powerType", "Power Type"),
        new PropertyDescriptor("powerInputType", "Power Input Type"),
        new PropertyDescriptor("powerOutputType", "Power Output Type"),
      ]);
    }

    return powerGroup;
  }
}
