export enum PropertyType {
  Text = "Text",
  ReadOnlyText = "ReadOnlyText",
  Number = "Number",
  Date = "Date",
  Flag = "Flag",
  SingleSelect = "SingleSelect",
  MultiSelect = "MultiSelect",
  List = "List",
  Count = "Count",
  SingleSelectDB = "SingleSelectDB",
  SingleSelectById = "SingleSelectById",
  Password = "Password",
  IdList = "IdList",
  CompactList = "CompactList",
  File = "File",
  ConnectionList = "ConnectionList",
  ModuleProfile = "ModuleProfile",
  SlotProfile = "SlotProfile",
  RelatedSingleSelectDB = "RelatedSingleSelectDB",
  DependentSingleSelectDB = "DependentSingleSelectDB",
}

export enum PermissionLevels {
  None = "None",
  Read = "Read",
  ReadWrite = "ReadWrite",
}

export const PERMISSION_LEVEL_STRINGS = [
  PermissionLevels.None.toString(),
  PermissionLevels.Read.toString(),
  PermissionLevels.ReadWrite.toString(),
];

export enum MeasurementSystem {
  Metric = "Metric",
  Imperial = "Imperial"
}