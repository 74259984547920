import * as React from "react";

import { observer } from "mobx-react";

import { TextField } from "@material-ui/core";

export interface ITextValue {
  name?: string;
  value: string;
  multiline?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  password?: boolean;
  onChange?(value: string): void;
}

@observer
export class TextValueEditor extends React.Component<ITextValue> {
  public render() {
    return (
      <TextField
        autoFocus={this.props.autoFocus}
        required={this.props.required}
        id={this.props.name}
        label={this.props.name ? this.props.name : undefined}
        value={this.props.value}
        type={this.props.password ? "password" : "text"}
        onChange={this.onChange}
        multiline={this.props.multiline}
        fullWidth={true}
        disabled={this.props.disabled}
      />
    );
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };
}
