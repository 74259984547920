import { observable } from "mobx";
import { PropGroup, PropertyDescriptor } from "./ComponentProperties";
import { PropertyType } from "../Repository/PropertySchema";

export class FileData {
  @observable public fileId?: string;
  @observable public name: string = "";
  @observable public description: string = "";
  @observable public reference: string = "";

  constructor(source?: FileData) {
    if (source) {
      Object.assign(this, source);
    }
  }

  getPropertySchema(): PropGroup<FileData>[] {
    return [
      new PropGroup("ungrouped", [
        new PropertyDescriptor("name", "Name", PropertyType.Text),
        new PropertyDescriptor("fileId", "File", PropertyType.File),
      ]),
    ];
  }
}
