import { observer } from "mobx-react";
import * as React from "react";
import { Button, IconButton, Tooltip } from "@material-ui/core";

export interface IButton {
  className?: string;
  tooltip?: string;
  icon?: boolean;
  smallIcon?: boolean;
  primary?: boolean;
  link?: boolean;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
}

@observer
export class HhitsButton extends React.Component<IButton> {
  public render() {
    return this.props.tooltip && !this.props.disabled ? (
      <Tooltip
        className="Tooltip"
        title={this.props.tooltip}
        aria-label={this.props.tooltip}
        placement="top"
      >
        {this.renderButton()}
      </Tooltip>
    ) : (
      this.renderButton()
    );
  }

  private renderButton = () => {
    return this.props.icon ? (
      <IconButton
        disabled={this.props.disabled}
        color={this.props.primary ? "primary" : "default"}
        className={`HhitsButton ${this.props.className} ${
          this.props.icon ? "Icon" : ""
        }`}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </IconButton>
    ) : this.props.smallIcon ? (
      <IconButton
        disabled={this.props.disabled}
        color={this.props.primary ? "primary" : "default"}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </IconButton>
    ) : (
      <Button
        disabled={this.props.disabled}
        variant={this.props.link ? "text" : "contained"}
        color={this.props.primary ? "primary" : "default"}
        className={`HhitsButton ${this.props.className} ${
          this.props.icon ? "Icon" : ""
        }`}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  };
}
