import * as React from "react";

import { observer } from "mobx-react";

import { InputAdornment, TextField } from "@material-ui/core";

export interface INumberValue {
  name?: string;
  value: string; //TODO: Change this to number type
  unit?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?(value: string | null): void;
  preventDefaultScroll?: boolean;
}

@observer
export class NumberValueEditor extends React.Component<INumberValue> {
  public render() {
    const value = Number.parseFloat(this.props.value);
    return (
      <TextField
        required={this.props.required}
        id={this.props.name}
        label={this.props.name ? this.props.name : undefined}
        value={value || value === 0 ? value : ""}
        onChange={this.onChange}
        fullWidth={true}
        disabled={this.props.disabled}
        type={"number"}
        onWheel={(event) => {
          if (
            this.props.preventDefaultScroll === undefined ||
            this.props.preventDefaultScroll
          ) {
            event.preventDefault();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{this.props.unit}</InputAdornment>
          ),
        }}
      />
    );
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      if (!isNaN(Number.parseFloat(event.target.value))) {
        this.props.onChange(event.target.value);
      }
      if (!event.target.value) {
        this.props.onChange(null);
      }
    }
  };
}
