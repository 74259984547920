import * as React from "react";
import { Modal as MaterialModal } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IModalProps {
  className?: string;
  onClose?: () => void;
  outsideClose?: boolean;
  title?: string;
  width?: string;
  hideHeader?: boolean;
  open?: boolean;
}

export class Modal extends React.Component<IModalProps> {
  public render() {
    return (
      <MaterialModal
        className={"ModalRoot " + this.props.className}
        disableBackdropClick={!this.props.outsideClose}
        open={this.props.open === undefined ? true : this.props.open}
        onClose={this.props.onClose}
      >
        <div className="MaterialModal">
          {!this.props.hideHeader && (
            <header>
              <div className="Title">{this.props.title}</div>
              {this.props.onClose && (
                <span
                  className="Close"
                  onClick={this.props.onClose}
                  aria-hidden="true"
                >
                  <FontAwesomeIcon icon="times" />
                </span>
              )}
            </header>
          )}
          {this.props.children}
        </div>
      </MaterialModal>
    );
  }
}
