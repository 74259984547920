import { observable } from "mobx";
import { fromPromise } from "mobx-utils";
import { createContext } from "react";

import { IComponentProps } from "./Schema/ComponentProperties";
import { HhitsBuild } from "./Schema/HhitsBuild";
import { getBuilds } from "../API/BuildRepo";
import { Rule, RuleGroup } from "./Schema/Rule";
import { getRules, getRuleGroups } from "../API/RuleRepo";

export class SiteState {
  @observable public showOnlyCompatibleComponents = true;

  @observable public fetchingBuilds = false;
  @observable public hhitsBuilds: HhitsBuild[] = [];
  @observable public forceRepoRefresh = false;

  @observable public fetchingRules = false;
  @observable public fetchingRuleGroups = false;
  @observable public rules: Rule[] = [];
  @observable public ruleGroups: RuleGroup[] = [];
  @observable public selectedRuleGroup: RuleGroup | null = null;

  @observable public draggedComponent: IComponentProps | null = null;

  public refreshRepository = () => {
    this.forceRepoRefresh = !this.forceRepoRefresh;
  };

  public getBuilds = () => {
    return fromPromise(this.runGetBuilds()).case({
      fulfilled: (builds) => builds,
      pending: () => {
        return [];
      },
      rejected: (error) => {
        console.log(error);
        return [];
      },
    });
  };
  private async runGetBuilds(): Promise<HhitsBuild[]> {
    this.fetchingBuilds = true;
    return getBuilds().then((values) => {
      this.hhitsBuilds = values;
      this.fetchingBuilds = false;
      return values;
    });
  }

  public getRules = () => {
    return fromPromise(this.runGetRules());
  };

  public getRuleGroups = () => {
    return fromPromise(this.runGetRuleGroups());
  };

  public getRuleById = (ruleId: string) => {
    return this.rules.find((rule) => rule.id === ruleId);
  };

  private async runGetRules(): Promise<Rule[]> {
    this.fetchingRules = true;
    return getRules().then((values) => {
      this.rules = values;
      this.fetchingRules = false;
      return this.rules;
    });
  }

  private async runGetRuleGroups(): Promise<RuleGroup[]> {
    this.fetchingRuleGroups = true;
    return getRuleGroups().then((values) => {
      this.ruleGroups = values;
      this.fetchingRuleGroups = false;
      this.selectedRuleGroup = this.ruleGroups[0];
      return this.ruleGroups;
    });
  }

  public setDraggedComponent = (component: IComponentProps | null) => {
    this.draggedComponent = component;
  };
}

export const SiteContext = createContext<SiteState>(new SiteState());
