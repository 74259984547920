import { ErrorMessage } from "../Data/Schema/Errors";
import { HhitsBuild } from "../Data/Schema/HhitsBuild";
import { PropsCache } from "../Data/Store/PropsCache";
import { Post, Get, Delete } from "./Http";
import { IComputedProps } from "../Data/Schema/ComponentProperties";
import { BackplaneProps } from "../Data/Schema/Components/BackplaneProps";

export interface BuildLoad {
  build: HhitsBuild;
  componentProperties: PropsCache;
  computedProperties: Record<number, IComputedProps>;
  buildErrors: ErrorMessage[];
}
export class Pair {
  first: String = "";
  second: String = "";

  constructor(a: String, b: String) {
    this.first = a;
    this.second = b;
  }
}

export interface BackplaneLayout {
  backplanes: BackplaneProps[];
  compatibilityList: Pair[];
}
export interface BuildSave {
  id?: string;
  build: HhitsBuild;
}

export async function saveBuild(build: BuildSave): Promise<HhitsBuild> {
  const rawBuild = await Post<HhitsBuild>(
    `/api/controllers/builds/save`,
    build
  );
  return new HhitsBuild(rawBuild);
}

export async function saveBuildDetails(build: BuildSave): Promise<HhitsBuild> {
  const rawBuild = await Post<HhitsBuild>(
    `/api/controllers/builds/saveDetails`,
    build
  );
  return new HhitsBuild(rawBuild);
}

export async function saveBuildRuleGroups(
  build: BuildSave
): Promise<HhitsBuild> {
  const rawBuild = await Post<HhitsBuild>(
    `/api/controllers/builds/saveRuleGroups`,
    build
  );
  return new HhitsBuild(rawBuild);
}

export async function getBuilds(): Promise<HhitsBuild[]> {
  const rawData = await Get<HhitsBuild[]>(`/api/controllers/builds`);
  // deserialized data is not actually an instance of the indicated class, fix that up before returning it
  return rawData.map((build) => new HhitsBuild(build));
}

export async function getBuild(buildId: string): Promise<BuildLoad> {
  const rawData = await Get<BuildLoad>(
    `/api/controllers/builds/?buildId=${buildId}`
  );
  // deserialized data are not actually instances of the indicated class, fix that up before returning them
  return {
    build: new HhitsBuild(rawData.build),
    componentProperties: new PropsCache(rawData.componentProperties),
    computedProperties: rawData.computedProperties,
    buildErrors: rawData.buildErrors.map((error) => new ErrorMessage(error)),
  };
}

export async function checkBuild(build: HhitsBuild): Promise<BuildLoad> {
  const rawData = await Post<BuildLoad>(`/api/controllers/builds/check`, build);
  // deserialized data are not actually instances of the indicated class, fix that up before returning them

  return {
    build: new HhitsBuild(rawData.build),
    componentProperties: new PropsCache(rawData.componentProperties),
    computedProperties: rawData.computedProperties,
    buildErrors: rawData.buildErrors.map((error) => new ErrorMessage(error)),
  };
}

export async function getBuildCache(build: HhitsBuild): Promise<PropsCache> {
  const rawData = await Post<PropsCache>(`/api/controllers/buildCache/`, build);
  return new PropsCache(rawData);
}

export async function deleteBuild(buildId: string) {
  return Delete(`/api/controllers/builds/delete/?buildId=${buildId}`);
}

export async function findBackplane(
  build: HhitsBuild
): Promise<BackplaneLayout> {
  const rawData = await Post<BackplaneLayout>(
    `/api/controllers/repository/findbackplane`,
    build
  );
  // deserialized data are not actually instances of the indicated class, fix that up before returning them
  return {
    backplanes: rawData.backplanes.map(
      (backplane) => new BackplaneProps(backplane)
    ),
    compatibilityList: rawData.compatibilityList.map(
      (pair) => new Pair(pair.first, pair.second)
    ),
  };
}

export async function getCompatibilityList(
): Promise<Pair[]> {
  const rawData = await Get<Pair[]>(
    `/api/controllers/repository/getCompatibilityList`
  );
  
  return rawData;
}
