import * as React from "react";
import { observer } from "mobx-react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import HhitsLogo from "./Images/hhits_logo.png";
import HhitsTextLogo from "./Images/hhits_logo_with_text.png";
import SimvLogo from "./Images/SimV_small.png";
import "./Polyfill/Number";
import { Modal } from "./Components/Modal/Modal";
import { RootStore } from "./Data/Store/RootStore";
import { GIT_DESCRIBE } from "./Meta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthenticationService, {
  IS_ADMIN_SESSION_ATTRIBUTE_NAME,
  USER_NAME_SESSION_ATTRIBUTE_NAME,
} from "./Pages/Login/AuthenticationService";
import { observable } from "mobx";
import { Menu, MenuItem, IconButton, Divider } from "@material-ui/core";
import { UserEditorModal } from "./Pages/Settings/UserEditorModal";
import { PasswordResetModal } from "./Pages/Settings/PasswordResetModal";
import { HhitsMessage } from "./Components/Message";

interface HeaderProps extends RouteComponentProps<{}> {
  rootStore: RootStore;
}

@observer
class HeaderRaw extends React.Component<HeaderProps> {
  @observable userMenuAnchor: HTMLElement | undefined = undefined;
  @observable helpMenuAnchor: HTMLElement | undefined = undefined;

  private get uiStore() {
    return this.props.rootStore.uiStore;
  }

  public render() {
    return (
      <div className="MainHeader">
        <div className="HeaderText">
          {<img className="SiteLogo" src={HhitsLogo} alt="HHITS" />}
          {this.props.location.pathname === "/forgot_password" && (
            <NavLink exact={true} to="/" className={`NavigationButton`}>
              <FontAwesomeIcon icon="angle-left" />
              Return to Login
            </NavLink>
          )}
        </div>
        <div className="Navigation">
          <NavLink exact={true} to="/builds" className={`NavigationButton`}>
            Builds
          </NavLink>
          <div
            className={`ContextItems ${
              this.uiStore.subContextItems.length > 0 ? "Show" : ""
            }`}
          >
            {this.uiStore.subContextItems.map((item, index) => (
              <HeaderItem>{item.content}</HeaderItem>
            ))}
          </div>
          <div className="NavSeparator" />
          <NavLink to="/repository" className={`NavigationButton`}>
            Repository
          </NavLink>
          <NavLink to="/rules" className={`NavigationButton`}>
            Rules
          </NavLink>
        </div>
        <span className="spacer" />

        <div className="Note">
          The data in this application is for testing purposes and may contain
          modified or unrealistic data.
        </div>
        <span className="spacer" />
        <span className="spacer" />

        {this.props.location.pathname !== "/" ? (
          <>
            <div className="Status">
              <IconButton
                aria-owns={this.helpMenuAnchor ? "help-menu" : undefined}
                aria-haspopup="true"
                onClick={this.showAbout}
              >
                <FontAwesomeIcon
                  className="MenuButton"
                  icon="question-circle"
                />
              </IconButton>
              <Menu
                id="help-menu"
                anchorEl={this.helpMenuAnchor}
                keepMounted
                open={this.helpMenuAnchor !== undefined}
                onClose={() => (this.helpMenuAnchor = undefined)}
              >
                <MenuItem disabled={true}>Help</MenuItem>
                <MenuItem onClick={this.showAbout}>About HHITS</MenuItem>
              </Menu>
            </div>

            {sessionStorage.getItem(IS_ADMIN_SESSION_ATTRIBUTE_NAME) ===
            "true" ? (
              <div className="Status">
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.gotoAdmin}
                >
                  <FontAwesomeIcon className="MenuButton" icon="cog" />
                </IconButton>
              </div>
            ) : null}

            <div className="Status">
              <IconButton
                aria-owns={this.userMenuAnchor ? "user-menu" : undefined}
                aria-haspopup="true"
                onClick={this.userMenuClick}
              >
                <FontAwesomeIcon className="MenuButton" icon="user" />
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={this.userMenuAnchor}
                keepMounted
                open={this.userMenuAnchor !== undefined}
                onClose={() => (this.userMenuAnchor = undefined)}
              >
                <MenuItem onClick={this.gotoProfile}>Profile</MenuItem>
                <MenuItem onClick={this.gotoPasswordReset}>
                  Change Password
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.logoutOfHHITS}>Log Out</MenuItem>
              </Menu>
            </div>
          </>
        ) : null}

        {this.uiStore.aboutModalVisible && (
          <Modal
            className={"AboutModal"}
            onClose={this.uiStore.toggleAboutModal}
          >
            <div className="AboutBody">
              <img className="HhitsLogo" src={HhitsTextLogo} alt="HHITS" />
              <div className="App-About">
                <div className="version">Version: {GIT_DESCRIBE}</div>
              </div>
            </div>
            <div className="copyright">
              Copyright © 2018-2019{" "}
              <img className="SimV" src={SimvLogo} alt="SimVentions"></img>
            </div>
          </Modal>
        )}

        {this.uiStore.userEditorVisible &&
          sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME) && (
            <UserEditorModal
              uiStore={this.uiStore}
              username={
                sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME) || ""
              }
              onClose={this.uiStore.toggleUserEditorModal}
              adminUser={false}
              //onSave={this.props.siteState.refreshRepository}
            />
          )}

        {this.uiStore.passwordResetModalVisible &&
          sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME) && (
            <PasswordResetModal
              username={
                sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME) || ""
              }
              onClose={this.uiStore.togglePasswordResetModal}
              uiStore={this.uiStore}
              //adminUser={false}
              //onSave={this.props.siteState.refreshRepository}
            />
          )}

        <HhitsMessage
          message="Password changed!"
          open={this.uiStore.passwordResetSuccessVisible}
          onClose={() => this.uiStore.setPwdResetSuccessMsgVisible(false)}
        />
        <HhitsMessage
          message="Password change failed!"
          open={this.uiStore.passwordResetFailVisible}
          onClose={() => this.uiStore.setPwdResetFailMsgVisible(false)}
        />
      </div>
    );
  }

  private userMenuClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.userMenuAnchor = event.currentTarget;
  };

  /** 
  private helpMenuClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.helpMenuAnchor = event.currentTarget;
  };
  */

  private gotoProfile = (event: any) => {
    this.userMenuAnchor = undefined;
    this.uiStore.toggleUserEditorModal();
  };

  private gotoPasswordReset = (event: any) => {
    this.userMenuAnchor = undefined;
    this.uiStore.togglePasswordResetModal();
  };

  private gotoAdmin = (event: any) => {
    this.userMenuAnchor = undefined;
    this.props.history.push(`/settings`);
  };

  private showAbout = (event: any) => {
    this.helpMenuAnchor = undefined;
    this.uiStore.toggleAboutModal();
  };

  private logoutOfHHITS = () => {
    AuthenticationService.executeLogout();
  };
}

function HeaderItem(props: { children: React.ReactNode }) {
  return <>{props.children}</>;
}

// withRouter is needed to force a rerender when the route changes; otherwise @observer will prevent the Route from noticing the path change
export const Header = withRouter(HeaderRaw);
