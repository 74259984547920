import * as React from "react";

import { observer } from "mobx-react";
import { observable } from "mobx";
import { getDropdownOptionsById } from "../../API/Repository";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

export interface IdOption {
  id: string;
  caption: string;
}
export interface ISingleSelectByIdValue {
  name?: string;
  value: string;
  listItemIndex?: number;
  databaseEntryName?: string;
  required?: boolean;
  disabled?: boolean;
  notNullable?: boolean;
  onChange?(value: string, index: number): void;
}

@observer
export class SingleSelectByIdValueEditor extends React.Component<
  ISingleSelectByIdValue
> {
  @observable private options: IdOption[] | undefined;

  public componentDidMount() {
    // TODO: Better way of doing this?
    if (this.props.databaseEntryName) {
      getDropdownOptionsById(this.props.databaseEntryName).then((value) => {
        this.options = value.sort();
      });
    }
  }

  public render() {
    return (
      <FormControl required={this.props.required} fullWidth={true}>
        {this.props.name && (
          <InputLabel htmlFor={this.props.name}>{this.props.name}</InputLabel>
        )}
        <Select
          id={this.props.name}
          disabled={this.props.disabled}
          onChange={this.onChange}
          fullWidth={true}
          value={this.props.value}
          input={
            <Input
              fullWidth={true}
              name={this.props.name}
              id={this.props.name}
            />
          }
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {!this.props.notNullable}
          {this.options &&
            this.options.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.caption}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }

  private onChange = (event: any) => {
    if (this.props.onChange) {
      this.props.onChange(
        event.target.value,
        this.props.listItemIndex ? this.props.listItemIndex : 0
      );
    }
  };
}
