import { observable } from "mobx";

import { ComponentType } from "../Component";
import {
  IComponentProps,
  PropGroup,
  BackplaneSlot,
  ComponentPort,
  PropertyDescriptor,
  ComponentSlot,
  IComputedProps,
  getComponentRuleProperties,
  DifferenceSet,
} from "../ComponentProperties";
import {
  PropertyType,
  PermissionLevels,
} from "../../Repository/PropertySchema";
import { FileData } from "../FileData";
import { RulePropertySchema } from "../Rule";
import { GrantedGroupPermission, GrantedUserPermission } from "../UserGroup";
import { USER_ID_SESSION_ATTRIBUTE_NAME } from "../../../Pages/Login/AuthenticationService";
import { BaseComponent } from "./BaseComponent";
import { ComputedPower } from "../Subcomponents/ComputedPower";

export class ChassisProps extends BaseComponent implements IComponentProps {
  @observable public power12vInAmps?: number;
  @observable public power5vInAmps?: number;
  @observable public power3_3vInAmps?: number;
  @observable public power3_3vAuxInAmps?: number;
  @observable public power12vAuxPlusInAmps?: number;
  @observable public power12vAuxNegInAmps?: number;
  @observable public powerTotalInWatts?: number;
  @observable public powerCurrent: string = "";
  @observable public powerType: string = "";
  @observable public allocatedWeight: number = 0;
  @observable public slots: BackplaneSlot[] = [];
  @observable public ports: ComponentPort[] = [];

  constructor(source?: ChassisProps) {
    super();
    if (source) {
      Object.assign(this, source);
      this.ports = source.ports.map((port) => new ComponentPort(port));
      this.slots = source.slots.map((slot) => new BackplaneSlot(slot));
      this.groupAccess = source.groupAccess.map(
        (access) => new GrantedGroupPermission(access)
      );
      this.userAccess = source.userAccess.map(
        (access) => new GrantedUserPermission(access)
      );
      this.documentation = source.documentation.map(
        (documentation) => new FileData(documentation)
      );
    }
  }

  public clone(keepId?: boolean): ChassisProps {
    var clone = new ChassisProps(this);
    if (!keepId) {
      clone.id = undefined;
      clone.model = this.model + " (Clone)";
    }
    var currentUserId = sessionStorage.getItem(USER_ID_SESSION_ATTRIBUTE_NAME);
    if (currentUserId) {
      clone.ownerId = currentUserId;
    }
    return clone;
  }

  public containsEmptyRequiredFields(): boolean {
    if (this.model.length <= 0) return true;
    return false;
  }

  public getId(): string | undefined {
    return this.id;
  }
  public getComponentType(): ComponentType {
    return ComponentType.Chassis;
  }
  public getTitle(): string {
    return this.model;
  }
  public getSubtext(): string {
    return "Chassis";
  }
  public getSlots(): ComponentSlot[] {
    return this.slots.slice().sort((a, b) => (a.index >= b.index ? 1 : -1));
  }
  public getPropertySchema(): PropGroup<ChassisProps>[] {
    return this.buildChassisSchema();
  }
  public getTopPropertySchema(): PropGroup<ChassisProps>[] {
    return CHASSIS_TOP_PROPERTY_SCHEMA;
  }
  public getPermissionPropertySchema(): PropGroup<ChassisProps>[] {
    return CHASSIS_PERMISSION_PROPERTY_SCHEMA;
  }
  public getRepositoryProperties(): PropertyDescriptor<ChassisProps>[] {
    return [
      new PropertyDescriptor("vendor", "Vendor"),
      new PropertyDescriptor("slots", "Backplane Slots", PropertyType.Count),
      new PropertyDescriptor(
        "powerTotalInWatts",
        "Power",
        PropertyType.Number,
        "Watts"
      ),
    ];
  }
  public addToList<ChassisProps>(propName: keyof ChassisProps) {
    if (propName === "slots") {
      this.slots.push(new BackplaneSlot({ index: this.slots.length + 1 }));
    } else if (propName === "ports") {
      this.ports.push(new ComponentPort({ index: this.ports.length + 1 }));
    } else if (propName === "groupAccess") {
      this.groupAccess.push(new GrantedGroupPermission({}));
    } else if (propName === "userAccess") {
      this.userAccess.push(new GrantedUserPermission({}));
    } else if (propName === "documentation") {
      this.documentation.push(new FileData());
    }
  }
  public removeFromList<ChassisProps>(
    propName: keyof ChassisProps,
    index: number
  ) {
    if (propName === "slots") {
      this.slots.splice(index, 1);
    } else if (propName === "ports") {
      this.ports.splice(index, 1);
    } else if (propName === "groupAccess") {
      this.groupAccess.splice(index, 1);
    } else if (propName === "userAccess") {
      this.userAccess.splice(index, 1);
    } else if (propName === "documentation") {
      this.documentation.splice(index, 1);
    }
  }
  public asImplementation(): ChassisProps {
    return this;
  }

  public compareTo(other: ChassisProps): DifferenceSet {
    var diffs: DifferenceSet = new DifferenceSet();
    diffs.addDiffs(this.getEnvironmentalDifferences(other));
    diffs.addDiffs(this.getVendorDifferences(other));
    diffs.addDiffs(this.getPhysicalDifferences(other));
    diffs.addDiffs(this.getHostDifferences(other));
    diffs.addDiffs(this.getSystemDifferences(other));

    diffs.compareProps("Backplane Slots", this.slots, other.slots);
    diffs.compareProps("Ports", this.ports, other.ports);

    diffs.addDiffs(this.getPowerDifferences(other, true, false));

    return diffs;
  }

  public getOwnerId(): string {
    return this.ownerId;
  }

  public getWorldAccess(): PermissionLevels {
    return this.worldAccess;
  }

  public getGroupAccess(): GrantedGroupPermission[] {
    return this.groupAccess;
  }

  public getUserAccess(): GrantedUserPermission[] {
    return this.userAccess;
  }

  public buildChassisSchema = () => {
    let chassisSchema: PropGroup<ChassisProps>[] = [
      this.getVendorPropGroup(),
      new PropGroup("Backplane Slots", [
        new PropertyDescriptor("slots", "Backplane Slots", PropertyType.List),
      ]),
      new PropGroup("Ports", [
        new PropertyDescriptor("ports", "Ports", PropertyType.List),
      ]),
      this.getPowerPropGroup(true, false),
      this.getEnvironmentalPropGroup(),
      this.getPhysicalPropGroup(),
      this.getSystemPropGroup(),
      this.getHostPropGroup(),
      new PropGroup("Documentation", [
        new PropertyDescriptor(
          "documentation",
          "Documentation",
          PropertyType.CompactList
        ),
      ]),
      this.getPermissionPropGroup(),
    ];

    return chassisSchema;
  };

  public static getRulePropertySchema(): RulePropertySchema[] {
    const properties = getComponentRuleProperties(
      CHASSIS_PROPERTY_LIST as PropGroup<IComponentProps>[]
    );
    properties.push.apply(properties, [
      {
        name: "powerTotalDrawInWatts",
        display: "Total Power Draw",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "powerTotalSupplyInWatts",
        display: "Total Power Supply",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "weightTotalInGrams",
        display: "Total Weight In Grams",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "allocatedWeight",
        display: "Total Allocated Weight In Grams",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power12vTotalDrawInAmps",
        display: "Total 12V Power Draw",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power5vTotalDrawInAmps",
        display: "Total 5V Power Draw",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power3_3vTotalDrawInAmps",
        display: "Total 3.3V Power Draw",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power3_3vAuxTotalDrawInAmps",
        display: "Total 3.3V Aux Power Draw",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power12vAuxPlusTotalDrawInAmps",
        display: "Total +12V Aux Power Draw",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power12vAuxNegTotalDrawInAmps",
        display: "Total -12V Aux Power Draw",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power12vTotalSupplyInAmps",
        display: "Total 12V Power Supply",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power5vTotalSupplyInAmps",
        display: "Total 5V Power Supply",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power3_3vTotalSupplyInAmps",
        display: "Total 3.3V Power Supply",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power3_3vAuxTotalSupplyInAmps",
        display: "Total 3.3V Aux Power Supply",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power12vAuxPlusTotalSupplyInAmps",
        display: "Total +12V Aux Power Supply",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
      {
        name: "power12vAuxNegTotalSupplyInAmps",
        display: "Total -12V Aux Power Supply",
        computed: true,
        listCount: false,
        type: PropertyType.Number,
      },
    ]);
    properties.push.apply(
      properties,
      ComponentPort.getRulePropertySchema("ports", "Port")
    );
    properties.push.apply(
      properties,
      BackplaneSlot.getRulePropertySchema("slots", "Backplane Slot")
    );
    return properties.sort((a, b) => a.display.localeCompare(b.display));
  }
}

export class ChassisComputed extends ComputedPower implements IComputedProps {
  constructor(source?: ChassisComputed) {
    super();
    if (source) {
      Object.assign(this, source);
    }
  }
}

const CHASSIS_TOP_PROPERTY_SCHEMA: PropGroup<ChassisProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("slots", "Backplane Slots", PropertyType.Count),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Integrated Power",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor("powerType", "Power Type"),
    new PropertyDescriptor("coolingType", "Cooling Type"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor(
      "lengthInInches",
      "Length",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "widthInInches",
      "Width",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "weightInGrams",
      "Weight",
      PropertyType.Number,
      "Grams"
    ),
  ]),
];

const CHASSIS_PERMISSION_PROPERTY_SCHEMA: PropGroup<ChassisProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor(
      "ownerId",
      "Owner",
      PropertyType.SingleSelectById,
      undefined,
      undefined,
      undefined,
      undefined,
      "users"
    ),
    new PropertyDescriptor("worldAccess", "World"),
    new PropertyDescriptor("groupAccess", "Groups", PropertyType.CompactList),
    new PropertyDescriptor("userAccess", "Users", PropertyType.CompactList),
  ]),
];

const CHASSIS_PROPERTY_LIST: PropGroup<ChassisProps>[] = [
  new PropGroup("", [
    new PropertyDescriptor("model", "Name", undefined, undefined, true),
    new PropertyDescriptor("vendor", "Vendor"),
    new PropertyDescriptor("partNumber", "Part Number"),
    new PropertyDescriptor("seriesNumber", "Series Number"),
    new PropertyDescriptor("revisionNumber", "Revision Number"),
    new PropertyDescriptor(
      "manufacturedDate",
      "Manufactured Date",
      PropertyType.Date
    ),
    new PropertyDescriptor(
      "power12vInAmps",
      "12V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power5vInAmps",
      "5V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vInAmps",
      "3.3V Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power3_3vAuxInAmps",
      "3.3V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxPlusInAmps",
      "+12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "power12vAuxNegInAmps",
      "-12V Aux Power",
      PropertyType.Number,
      "Amps"
    ),
    new PropertyDescriptor(
      "powerTotalInWatts",
      "Chassis Total Power",
      PropertyType.Number,
      "Watts"
    ),
    new PropertyDescriptor("powerCurrent", "Current"),
    new PropertyDescriptor("powerType", "Power Type"),
    new PropertyDescriptor(
      "coolingType",
      "Cooling Type",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Air", "Conduction", "AFT", "LFT", "Air Flow-By"]
    ),
    new PropertyDescriptor(
      "operatingTemperatureMinInCelsius",
      "Min Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "operatingTemperatureMaxInCelsius",
      "Max Operating Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMinInCelsius",
      "Min Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "storageTemperatureMaxInCelsius",
      "Max Storage Temp",
      PropertyType.Number,
      "Celsius"
    ),
    new PropertyDescriptor(
      "temperatureCycling",
      "Temperature Cycling",
      PropertyType.Flag
    ),
    new PropertyDescriptor("vibration", "Vibration"),
    new PropertyDescriptor("shock", "Shock"),
    new PropertyDescriptor("humidity", "Humidity"),
    new PropertyDescriptor(
      "operatingAltitudeMinInFeet",
      "Min Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "operatingAltitudeMaxInFeet",
      "Max Operating Altitude",
      PropertyType.Number,
      "Feet"
    ),
    new PropertyDescriptor(
      "rapidDecompression",
      "Rapid Decompression",
      PropertyType.Flag
    ),
    new PropertyDescriptor("fungusResistance", "Fungus Resistance"),
    new PropertyDescriptor(
      "electrostaticDischargeResistance",
      "Electrostatic Discharge Resistance"
    ),
    new PropertyDescriptor("corrosionResistance", "Corrosion Resistance"),
    new PropertyDescriptor("ruggedization", "Ruggedization"),
    new PropertyDescriptor(
      "lengthInInches",
      "Length",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "widthInInches",
      "Width",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "pitchInInches",
      "Pitch",
      PropertyType.Number,
      "Inches"
    ),
    new PropertyDescriptor(
      "weightInGrams",
      "Weight",
      PropertyType.Number,
      "Grams"
    ),
    new PropertyDescriptor(
      "ipmcImplemented",
      "IPMC Implemented",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier1Controller",
      "Tier 1 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "tier2Controller",
      "Tier 2 Controller",
      PropertyType.Flag
    ),
    new PropertyDescriptor(
      "chassisManager",
      "Chassis Manager",
      PropertyType.Flag
    ),
    new PropertyDescriptor("hostConformancy", "Conformant", PropertyType.Flag),
    new PropertyDescriptor(
      "hostConformancyLevel",
      "Level of Conformance",
      PropertyType.SingleSelect,
      undefined,
      undefined,
      ["Level A", "Level B"]
    ),
    new PropertyDescriptor("tier3", "Tier 3"),
  ]),
];
