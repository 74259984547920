import { observable } from "mobx";

import { SearchOperator } from "../../../Data/Search";
import { ISearchAttribute, SearchOption } from "./SearchOption";

export class SearchText extends SearchOption {
  @observable public value: string;
  @observable public operator: SearchOperator;
  public defaultText?: string;
  public disableAutoComplete?: boolean;

  constructor(
    value: string,
    operator: SearchOperator,
    attribute: ISearchAttribute,
    enabled: boolean = true,
    defaultText?: string,
    disableAutoComplete?: boolean,
    active?: boolean
  ) {
    super(attribute, enabled);
    this.value = value;
    this.operator = operator;
    this.defaultText = defaultText;
    this.disableAutoComplete = disableAutoComplete;
    this.active = active ? active : false;
  }

  clone = () => {
    let searchText = new SearchText(
      this.value,
      this.operator,
      this.attribute,
      this.enabled,
      this.defaultText,
      this.disableAutoComplete,
      this.active
    );

    searchText.numMatchesOperator = this.numMatchesOperator;
    searchText.numMatches = this.numMatches;
    return searchText;
  };

  public setValue(value: string) {
    this.value = value;
  }
}
